<template>
	<div class="gp-column-styles">
        <a
            href="javascript:void(0)"
            @click="expanded = !expanded">
            <feather-icon :name="expanded ? 'chevron-down' : 'chevron-right'"/>
            <l10n value="Conditional style"/>
        </a>
        <template v-if="expanded">
            <ul ref="rules">
                <li v-for="rule, i in rules" :key="i">
                    <a
                        href="javascript:void()"
                        @click="deleteRule(rule)"
                        class="float-right">
                        <feather-icon name="trash"/>
                    </a>
                    <label><l10n value="Rule {n}" :n="i+1"/></label>
                    <div class="gp-column-styles-condition">
                        <select class="form-control form-control-sm" v-model="rule.condition">
                            <option
                                v-for="condition, id in validConditions"
                                :value="id"
                                :key="id"
                                >
                                {{condition.name}}
                            </option>
                        </select>
                    </div>
                    <div v-if="conditions[rule.condition].args && conditions[rule.condition].args.length" class="gp-column-styles-args">
                        <div v-for="type, i in conditions[rule.condition].args">
                            <input
                                class="form-control form-control-sm"
                                :type="type"
                                :value="formatValue(rule.conditionArgs[i])"
                                @change="$set(rule.conditionArgs, i, parseValue($event.target.value))"/>
                        </div>
                    </div>
                    <div>
                        <select class="form-control form-control-sm" v-model="rule.style">
                            <option
                                v-for="style, id in validStyles"
                                :value="id"
                                :key="id"
                                >
                                {{style.name}}
                            </option>
                        </select>
                    </div>
                    <div v-if="styles[rule.style].args" class="gp-column-styles-args">
                        <div v-for="value, i in styles[rule.style].args">
                            <input
                                class="form-control form-control-sm"
                                type="number"
                                :value="formatValue(rule.styleArgs[i] !== undefined ? rule.styleArgs[i] : value)"
                                @change="$set(rule.styleArgs, i, parseValue($event.target.value))"/>
                        </div>
                    </div>
                    <div
                        v-if="rule.style == 'custom'"
                        class="gp-column-styles-custom">
                        <table>
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <div class="btn-group">
                                            <button
                                                @click="$set(rule, 'bold', !rule.bold)"
                                                :class="{
                                                    'btn btn-sm btn-secondary': true,
                                                    active: rule.bold}">
                                                B
                                            </button>
                                            <button
                                                @click="$set(rule, 'italic', !rule.italic)"
                                                :class="{
                                                    'btn btn-sm btn-secondary': true,
                                                    active: rule.italic}">
                                                I
                                            </button>
                                            <button
                                                @click="$set(rule, 'underline', !rule.underline)"
                                                :class="{
                                                    'btn btn-sm btn-secondary': true,
                                                    active: rule.underline}">
                                                U
                                            </button>
                                            <button
                                                @click="$set(rule, 'lineThrough', !rule.lineThrough)"
                                                :class="{
                                                    'btn btn-sm btn-secondary': true,
                                                    active: rule.lineThrough}">
                                                S
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <gp-check
                                            :checked="rule.color != null"
                                            @change="$set(rule, 'color', $event ? '#000000' : null)"
                                            >
                                            <l10n value="Text color:"/>
                                        </gp-check>
                                    </td>
                                    <td>
                                        <input
                                            type="color"
                                            v-model="rule.color"
                                            :disabled="rule.color == null"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <gp-check
                                            :checked="rule.backgroundColor != null"
                                            @change="$set(rule, 'backgroundColor', $event ? '#fffffff' : null)"
                                            >
                                            <l10n value="Color fill:"/>
                                        </gp-check>
                                    </td>
                                    <td>
                                        <input
                                            type="color"
                                            v-model="rule.backgroundColor"
                                            :disabled="rule.backgroundColor == null"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div><l10n value="Fill saturation"/></div>
                                        <input
                                            min="0"
                                            max="1"
                                            step="0.05"
                                            :value="rule.saturation !== undefined ? rule.saturation : 1"
                                            @change="$set(rule, 'saturation', parseFloat($event.target.value))"
                                            type="range"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--div>
                        <gp-check v-model="rule.applyToRow">
                            <l10n value="Apply to entire row"/>
                        </gp-check>
                    </div-->
                </li>
            </ul>
            <button
                @click="addRule"
                class="btn btn-sm btn-secondary">
                <l10n value="Add rule"/>
            </button>
        </template>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    model: {
        prop: "rules",
        event: "change",
    },
    props: {
        column:     { type: Object },
        rows:       { type: Array,  default: () => [] },
        conditions: { type: Object, default: () => ({}) },
        styles:     { type: Object, default: () => ({}) },
    },
    data() {
        let rules = _.cloneDeep(this.column.styleRules) || []
        for (let rule of rules) {
            if (rule.args) {
                rule.conditionArgs = rule.args
                delete rule.args
            }
            if (!rule.styleArgs)
                rule.styleArgs = []
        }
        return { expanded: false, rules }
    },
    methods: {
        addRule() {
            this.rules.push({
                condition: _.keys(this.conditions)[0],
                conditionArgs: [],
                style: _.keys(this.styles)[0],
                styleArgs: [],
            })
        },
        deleteRule(rule) {
            this.rules.splice(this.rules.indexOf(rule), 1)
        },
        parseValue(x) {
            console.log("parseValue", x)
            if (this.columnType === "text")
                return x
            if (this.columnFormat === "percent")
                return parseFloat(x) / 100
            else
                return parseFloat(x)
        },
        formatValue(x) {
            if (this.columnType === "text")
                return x
            if (this.columnFormat === "percent")
                return x * 100
            else
                return x
        },
    },
    watch: {
        rules: {
            deep: true,
            handler: _.debounce(function (rules) {
                console.log({rules})
                this.$emit("change", {styleRules:_.cloneDeep(rules)})
            }, 300, {trailing:true})
        }
    },
    computed: {
        columnFormat() {
            return this.column.metric?.format ||
                this.column.attribute?.format
        },
        columnType() {
            if (this.columnFormat === "timestamp")
                return "timestamp"

            switch (this.column.type) {
                case "string":
                case "tagged":
                    return "text"

                case "docid":
                case "int8":
                case "int16":
                case "int32":
                case "int64":
                case "float":
                case "double":
                    return "number"

                default:
                    return this.column.type
            }
        },
        validStyles() {
            return _(this.styles)
                .toPairs()
                .filter(([key, {func}]) => !func || this.columnType == "number")
                .fromPairs()
                .value()
        },
        validConditions() {
            return _(this.conditions)
                .toPairs()
                .filter(([key, {args}]) =>
                    key === "none" ||
                    !args && this.columnType === "text" ||
                    args && args[0] === this.columnType)
                .fromPairs()
                .value()
        },
    },
}
</script>
<style>
.gp-column-styles ul li {
    margin-top: 8px;
}
.gp-column-styles li > div + div {
    margin-top: 6px;
}
.gp-column-styles li > div > div + div {
    margin-top: 4px;
}
.gp-column-styles li select {
    display: inline-block;
}
.gp-column-styles > button {
    margin: 10px auto;
    display: block;
}
.gp-column-styles .btn-group .btn:nth-child(1) {
    font-weight: bold;
}
.gp-column-styles .btn-group .btn:nth-child(2) {
    font-style: italic;
}
.gp-column-styles .btn-group .btn:nth-child(3) {
    text-decoration: underline;
}
.gp-column-styles .btn-group .btn:nth-child(4) {
    text-decoration: line-through;
}
.gp-column-styles-custom table {
    margin: 0 auto;
}
.gp-column-styles-custom .btn-group {
    display: flex;
}
.gp-column-styles-custom .btn-group .btn {
    flex-grow: 1;
}
.gp-column-styles-custom td {
    padding: 2px 0;
}
.gp-column-styles-custom td + td {
    padding-left: 8px;
}
.gp-column-styles-condition input {
    margin-top: 6px;
}
.gp-column-styles-custom input[type="range"] {
    width: 100%;
}
.gp-column-styles-args {
    display: flex;
    margin-right: -4px;
}
.gp-column-styles-args > div {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0!important;
    margin-right: 4px!important;
    position: relative;
    height: 30px;
}
.gp-column-styles-args > div > input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>