<template>
  <div class="my-chosen" ref="root">
    <my-caption :caption="caption" />
    <select
      ref="select"
      :multiple="multiple"
      :placeholder="placeholder10n"
      :data-placeholder="placeholder10n"
    >
      <option value="" :selected="selection.length == 0" disabled="disabled"></option>
      <option
        v-for="option in options"
        :key="option[0]"
        :value="option[0]"
        :selected="selection.find(selected => selected[0] == option[0])">
        {{formatValue(option)}}
      </option>
    </select>
  </div>
</template>
<script>
const utils = require('./my-utils');
require('./chosen.jquery.js');
const ls = require('./api/localStorage');

module.exports = {
  mixins: [
    require('./data.js'),
  ],
  data: function() {
    let selection = this.initialSelection;
    const lsKey = this.localstorageKey;
    const tableKey = this.id;
    const localStorageFilter = ls.loadFromLocalStorage(tableKey, lsKey);

    if (lsKey && localStorageFilter) {
      selection = localStorageFilter;
    }
            
    selection = _.map(selection, option => _.isArray(option) ? option : [option]);
    return { selection, lsKey, tableKey };
  },
  props: {
    indexed: { type: Boolean, default: false },
    caption: { type: String },
    rtl: { type: Boolean },
    width: { type: String, default: 'auto' },
    maxSelectedOptions: { type: Number },
    disableSearchThreshold: { type: Number },
    allowSingleDeselect: { type: Boolean, default: true },
    noResultsText: { type: String },
    multiple: { type: Boolean },
    placeholder: { type: String, default: utils.l10n('Select some options') },
    hierarchical: { type: Boolean, default: false },
    initialSelection: { type: Array, default: () => [] },
    filtersFunction: { type: [String, Function] },
    id: { type: String, required: true, default: '' },
    localstorageKey: { type: String, required: true, default: '' },
    format: { type: [Function, String] },
    desiredSort: { type: Array, default: () => [1] },
    autofilter: { type: Boolean, default: true },
  },
  computed: {
    placeholder10n() {
      return utils.l10n(this.placeholder);
    },
    sort() {
      if (this.dims.length > 0) {
        return this.desiredSort || this.initialSort || [1];
      } else {
        return null;
      };
    },
    options() {
      const options = [];
      if (this.report != null) {
        for (let option of this.report.rows) {
          options.push(option);
        }
      }
      for (let selected of this.selection) {
        if (!options.find(option => option[0] == selected[0])) {
          options.push(selected);
        }
      }
      return options;
    },
    filters() {
      if (_.isString(this.filtersFunction)) {
        try {
          return eval(this.filtersFunction).call(this, this);
        }
        catch (ex) {
          console.log(this.filtersFunction);
          console.warn(ex);
          return {};
        }
      }

      if (_.isFunction(this.filtersFunction)) {
        return this.filtersFunction(this);
      }

      let filter1 = '';
      let filter2 = '';
      const filter3 = '';

      if (this.selection.length > 0) {
        const filter = this.meta
          ? `${this.meta.columns[0].calc} ${this.hierarchical ? 'is' : 'in'} [${this.selection.map(([value]) => utils.quote(value, this.report.columns[0])).join(', ')}]`
          : `${this.dims[0]} ${this.hierarchical ? 'is' : 'in'} ${utils.quote(this.selection.map(([value]) => value))}`;
        if (this.indexed) {
          filter1 = filter;
        } else {
          filter2 = filter;
        }
      }

      return {
        groups: this.groups,
        stream: this.stream,
        filter1,
        filter2,
        filter3,
      };
    },
  },
  updated() {
    this.updateChosenWidth();
    $(this.$refs.select).trigger('chosen:updated');
  },
  mounted() {
    window.component = this;
    this.createChosen();
    $(this.$refs.select).trigger('chosen:updated');

    if (this.autofilter) {
      utils.bridge.trigger('filtersChanged', this._uid, this.filters);
    }
  },
  watch: {
    filters() {
      if (this.autofilter)
        utils.bridge.trigger('filtersChanged', this._uid, this.filters);
    },
    rtl: function() { Vue.nextTick(() => this.createChosen()); },
    width: function() { Vue.nextTick(() => this.createChosen()); },
    maxSelectedOptions: function() { Vue.nextTick(() => this.createChosen()); },
    disableSearchThreshold: function() { Vue.nextTick(() => this.createChosen()); },
    allowSingleDeselect: function() { Vue.nextTick(() => this.createChosen()); },
    noResultsText: function() { Vue.nextTick(() => this.createChosen()); },
    multiple: function() { Vue.nextTick(() => this.createChosen()); },
    selection() {
      if (this.localstorageKey && _.isArray(this.selection)) {
        ls.saveToLocalStorage(this.tableKey, this.localstorageKey, this.selection);
      }

      this.$emit('change', this.selection);
    },
  },
  beforeDestroy() {
    $(this.$refs.select).chosen('destroy');
  },
  methods: {
    formatValue(option) {
      if (_.isFunction(this.format))
        return this.format(option[0], option);
      if (_.isString(this.format)) {
        try {
          return eval(this.format)(option[0], option);
        }
        catch (ex) {
          console.warn('failed to format value', ex);
        }
      }
      return option[0];
    },
    createChosen() {
      $(this.$refs.select).chosen('destroy');
      $(this.$refs.select).chosen({
        rtl: this.rtl,
        width: this.width,
        max_selected_options: this.maxSelectedOptions,
        no_results_text: this.noResultsText,
        allow_single_deselect: this.allowSingleDeselect,
        disable_search_threshold: this.disableSearchThreshold,
      }).change(() =>
        this.selection =
                    _(this.$refs.select.options)
                      .filter(({selected, disabled}) => selected && !disabled)
                      .map(({value}) => this.options.find(option => option[0] == value))
                      .filter()
                      .value());
      $(this.$el).find('.chosen-container').on('touchstart', function(e) {
        e.stopPropagation();
        e.preventDefault();
        // Trigger the mousedown event.
        $(this).trigger('mousedown');
      });
      $(this.$el).find('.chosen-search-input').attr('placeholder', this.placeholder10n);
    },
    updateChosenWidth() {
      // let width = $(this.$refs.select).outerWidth()
      // if (this.multiple)
      //     width += 28
      // if (!this.multiple && this.allowSingleDeselect)
      //     width += 20
      // $(this.$refs.root)
      //     .find(".chosen-container")
      //     .css("min-width", `${width}px`)
    },
  },
};
</script>
<style>
.my-content .my-caption {
    float: none;
}
.my-content .chosen-container-single .chosen-single abbr {
    right: 22px;
}
.my-content .chosen-container-multi .chosen-choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2px;
}
.my-content .chosen-container-multi .chosen-choices li.search-choice,
.my-content .chosen-container-multi .chosen-choices li.search-field {
    float: none;
    margin: 2px;
}
.my-content .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin-top: -2px;
    margin-bottom: -2px;
}
.my-content .chosen-container .chosen-drop {
    width: auto;
    border-top: 1px solid rgb(88,151,251);
    margin-top: -1px;
}
.my-content .chosen-container .chosen-results li {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chosen-search-input {
  width: 100%!important;
  max-width: 75px;
}
.my-root .chosen-container {
    margin-bottom: 5px;
    width: auto!important;
    min-width: 70px;
    font-size: 14px;
    font-weight: 500;
}
.my-root .chosen-container-multi .chosen-choices li.search-choice {
    order: 2;
    background-color: #3385FF;
    background-image: none;
    border: none;
    border-radius: 5px;
    color: #ffffff;
}
.my-root .chosen-container-multi .chosen-choices li.search-field {
    order: 1;
}
.my-root .chosen-container .chosen-drop {
    width: auto;
    padding: 2px;
    font-weight: 500;
    font-size: 10px;
    border: none;
    margin-top: 2px;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.my-root .chosen-container-multi .chosen-choices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 8px 10px 12px;
    border: none;
    border-radius: 8px;
    background-image: none;
    background-color: #F7F7F7;
}
.my-dark-theme .chosen-container-multi .chosen-choices {
    border: 1px solid #ffffff;
    background-color: #222;
}
.my-root .chosen-container-single {
  background-color: #F7F7F7;
  border: none;
  border-radius: 8px;
  color: #9B9B9B;
  padding: 8px 12px;
}
.chosen-container-single .chosen-single div b {
  background: url('icons/arrow-down.svg') no-repeat 0 0!important;
}
.chosen-container-single.chosen-with-drop .chosen-single div b {
  background: url('icons/arrow-up.svg')!important;
}
.chosen-container-single .chosen-search input[type=text] {
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
