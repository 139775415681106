<template>
  <div :class="{ 'ao-chart': true, 'ao-chart-compact': compact }">
    <date-filter
      :stream="stream"
      function="date"
      :notBefore="notBefore"
      :notAfter="notAfter"
      :groups="['timerange']"
    />
    <div class="multiselect-container">
      <my-multiselect
        :options="suppliers"
        :selectedOption="suppliers[0]"
        searchable
        placeholder="Выберите поставщика"
        style="width: 300px;"
      />
      <my-multiselect
        :options="products"
        :selectedOption="products[0]"
        searchable
        placeholder="Выберите товар"
        style="width: 535px;"
      />
    </div>
    <my-tooltip
      ref="tooltip"
      :meta="meta"
      :chart="chart"
      :accessor="tooltipAccessor"
      :actions="actions"
    />
    <div class="my-chart" ref="base">
      <gp-data
        id="gp-charts"
        :controls="false"
        :stream="stream"
        :source="source"
        :groups="groups"
        :dims="dims"
        :vals="vals"
        :cols="cols"
        :filter1="filter1"
        :filter2="filter2"
        :instant="instant"
        :throttled="throttled"
        @report="report = $event"
        @reportId="reportId = $event"
        ref="data"
      />
      <div ref="chart" :style="{ opacity: reportId ? 0.3 : 1 }" />
    </div>
  </div>
</template>
<script>
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  mixins: [
    utils.configHelpers,
    require('../dc/base.js'),
    require('../dc/margin.js'),
    require('../dc/coordinate-grid.js'),
    require('../dc/composite-chart.js'),
  ],

  components: {
    'my-multiselect': MyMultiSelect,
  },

  data() {
    const gpndx = crossfilter([]);
    const dim = gpndx.dimension((row) => row[0]);

    return {
      l10n: utils.l10n,
      gpndx,
      dim,
      meta: null,
      rows: [],
      checked: {},
      report: null,
      reportId: null,
      metricsOpened: false,
      nestedCharts: {},
      metricsSearchString: '',
      products: [
        'Колбаса Черкизово Сальчичон сырокопченая нарезка 100г',
        'Колбаса Черкизово Салями Астория сырокопченая нарезка 85г',
        'Ветчина Черкизово Империя Вкуса с индейкой 400г',
      ],
      suppliers: [
        'Черкизово', ' Зелёная Линия', 'Индилайт',
      ],
    };
  },

  props: {
    stream: {
      type: String,
      default: 'default',
      required: false,
    },
    source: { type: Object, default: null },
    groups: { type: Array, default: () => [] },
    notBefore: { type: String, default: '2019-01-01' },
    notAfter: { type: String, default: '2020-12-31' },
    filter1: { type: String },
    filter2: { type: String },
    sections: { type: Array, default: () => [] },
    metrics: { type: Array, default: () => [] },
    formats: { type: Object, default: () => ({}) },
    formulas: { type: Object, default: () => ({}) },
    timeframes: { type: Object, default: () => ({}) },
    attributes: { type: Array, default: () => [] },
    instant: { type: Boolean, default: false },
    throttled: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    username: { type: String },
    actions: { type: Array },
    defaultMetrics: { type: Array, default: () => [] },
    rightYAxisFormats: { type: Array, default: () => ['value'] },
    selectedCharts: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  created() {
    if (this.selectedCharts) {
      this.selectedCharts.forEach((chart) => {
        this.checked[chart] = true;
      });
    }
  },

  computed: {
    vals() {
      const vals = {};

      for (const metric of this.checkedMetrics) {
        const timeframe = 'date';

        const resolveSubstitutes = (calc, depth = 0) => {
          if (depth == 10) { return calc; }
          return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            const formula = this.formulas[symbol];
            if (formula !== undefined && !this.isAggregationFormula(formula)) { return `(${resolveSubstitutes(formula, depth + 1)})`; }
            return symbol;
          });
        };

        const registerFormula = (symbol) => {
          const formula = this.formulas[symbol];

          if (formula !== undefined) {
            if (this.isAggregationFormula(formula)) {
              vals[`${symbol}_${timeframe}`] = this.resolveDateConditions(
                resolveSubstitutes(formula),
              );
            } else {
              for (const [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                registerFormula(symbol);
              }
            }
          }
        };

        registerFormula(metric.formula.split(/[\s,]+/g)[0]);
      }

      return _(vals)
        .toPairs()
        .map(([name, calc]) => ({
          name,
          calc: `${calc} as ${name}`,
          show: false,
        }))
        .value();
    },

    dims() {
      return [{
        calc: 'date',
        name: utils.l10n('date'),
      }];
    },

    cols() {
      cols = [];
      for (const metric of this.checkedMetrics) {
        let calc;
        const symbol = metric.formula.split(/[\s,]+/g)[0];
        const formula = this.formulas[symbol];
        const timeframe = 'date';
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) { calc = `${symbol}_${timeframe}`; } else {
            const resolveSubstitutes = (calc, depth = 0) => {
              if (depth == 10) { return calc; }
              return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                const formula = this.formulas[symbol];
                if (formula !== undefined) {
                  if (this.isAggregationFormula(formula)) return `${symbol}_${timeframe}`;
                  return `(${resolveSubstitutes(formula, depth + 1)})`;
                }
                return symbol;
              });
            };
            calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) =>
              // console.log(2,symbol)
              resolveSubstitutes(symbol));
          }
        }

        if (calc !== undefined) {
          cols.push({ calc, name: metric.name, metric });
        }
      }
      return cols;
    },

    checkedMetrics() {
      return this.metrics.filter(({ formula }) => this.checked[formula]);
    },
    nestedColors() {
      try {
        return eval(this.colors);
      } catch {
        return undefined;
      }
    },
  },

  watch: {
    report(report) {
      if (report) {
        this.gpndx.remove(() => true);
        this.gpndx.add(report.rows);

        // for tooltip
        this.rows = report.rows;
        this.meta = report.meta;

        const knownKeys = new Set();
        let needCompose = false;

        const series = {};

        _.forEach(report.meta.columns, ({ calc, metric }, i) => {
          if (metric) {
            const { name, format } = metric;
            const key = JSON.stringify({ calc, name, format });
            let min = 0;
            let max = 0;
            let sum = 0;
            let cnt = 0;
            const useRightYAxis = _.includes(this.rightYAxisFormats, format);

            // eslint-disable-next-line no-restricted-syntax
            for (const row of report.rows) {
              min = Math.min(min, row[i]);
              max = Math.max(max, row[i]);
              sum += row[i];
              cnt += 1;
            }

            series[key] = {
              min, max, sum, cnt, useRightYAxis,
            };
          }
        });

        const groups = _.keys(series).map((key) => [key]);

        // eslint-disable-next-line no-restricted-syntax
        for (const useRightYAxis of [false, true]) {
          let axisScale;
          // eslint-disable-next-line no-restricted-syntax
          for (const group of groups) {
            if (series[group[0]].useRightYAxis === useRightYAxis) {
              const max = _(group).map((x) => series[x].max).max();
              let scale = 1;

              if (max > 0) {
                while (max * scale > 100) { scale /= 10; }
                while (max * scale < 10) { scale *= 10; }
              }

              if (axisScale === undefined) {
                axisScale = scale;
                scale = 1;
              } else {
                scale = axisScale;
              }

              // eslint-disable-next-line no-restricted-syntax
              for (const x of group) {
                series[x].scale = scale;
              }
            }
          }
        }

        _.forEach(report.meta.columns, ({ calc, metric }, i) => {
          if (metric) {
            const { name, format } = metric;
            const key = JSON.stringify({ calc, name, format });
            const { useRightYAxis, scale } = series[key];
            let grp = this.dim.group().reduce(
              (p, v) => p + v[i] * scale,
              (p, v) => p - v[i] * scale,
              () => 0,
            );
            grp = this.removeEmptyBins(grp);

            if (!this.nestedCharts[key]) {
              if (name === '[MX-CHART] Поступления итог'
              || name === '[MX-CHART] Списания итог'
              || name === '[MX] Списания итог'
              || name === '[MX] Списания прогноз'
              || name === '[MX] Поступления итог'
              || name === '[MX] Поступления плановые'
              || name === '[AZ] Продажи'
              || name === '[AZ] Сумма по полю FactResult'
              ) {
                const chart = dc.barChart(this.chart)
                  .dimension(this.dim)
                  .x(d3.scaleTime())
                  .colors(this.metricColor)
                  .useRightYAxis(useRightYAxis);
                this.nestedCharts[key] = chart;
                needCompose = true;
              } else {
                const chart = dc.lineChart(this.chart)
                  .dimension(this.dim)
                  .x(d3.scaleTime())
                  .colors(this.metricColor)
                  .useRightYAxis(useRightYAxis);
                this.nestedCharts[key] = chart;
                needCompose = true;

                if (name === '[MX-CHART] Остатки прогноз'
                || name === '[MX] Продажи прогноз'
                || name === '[MX] Остатки плановые'
                || name === '[AZ] Сумма по полю fr1'
                || name === '[AZ] Сумма по полю fr2'
                || name === '[AZ] Сумма по полю fr3'
                || name === '[AZ] Сумма по полю fr4'
                ) {
                  chart.dashStyle([2, 3]);
                }
              }
            }

            const chart = this.nestedCharts[key]
              .group(grp, name, (d) => d.value)
              .colors(this.metricColor);
            knownKeys.add(key);
          }
        });

        for (const key of _.keys(this.nestedCharts)) {
          if (!knownKeys.has(key)) {
            delete this.nestedCharts[key];
            needCompose = true;
          }
        }

        if (needCompose) {
          this.chart.compose(_.values(this.nestedCharts));
        }

        this.chart.render();
      }
    },
  },

  methods: {
    tooltipAccessor(data) {
      const row = this.rows.find((row) => row[0] == data.key);

      if (!row) {
        return [];
      }

      return _.map(this.meta.columns, ({ metric }, i) => {
        if (metric) {
          let value = row[i];
          try {
            value = eval(this.formats[metric.format])(value);
          } catch {}
          return {
            name: metric.name,
            value,
            color: this.metricColor(metric.name),
          };
        }
      }).filter(_.identity);
    },

    metricColor(formula) {
      return this.nestedColors ? this.nestedColors(formula) : null;
    },

    createChart(instant) {
      if (!this.chart) {
        this.chart = dc.compositeChart(this.$refs.chart);
        dc.chartRegistry.deregister(this.chart);
        this.setupChart(this.chart);
      }
      this.renderChart(instant);
    },

    isAggregationFormula(formula) {
      return formula.match(/(sum|min|max|one|and|any|cnt|avg|first|last)\s*\(.*\)/);
    },

    removeEmptyBins(sourceGroup) {
      return {
        all() {
          return sourceGroup.all().filter((d) => d.value !== 0);
        },
      };
    },
  },

};
</script>

<style scoped>
  .ao-chart {
    display: flex;
    flex-direction: row;
    height: 80%;
  }
  .ao-chart .my-date-filter {
    position: absolute;
    left: 10px;
    top: 2px;
  }
  .ao-chart .my-chart {
    flex-basis: 1px;
    flex-grow: 1;
    margin-top: 0!important;
  }
  .ao-chart > * {
    transition: opacity 0.3s ease;
  }
  .ao-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
  }
  .ao-chart .my-date-filter {
    top: 4px;
  }
  .ao-chart .gp-data {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 1;
  }
  .ao-chart .my-date-filter {
    top: 4px;
  }
  .ao-chart .dc-chart svg {
    position: absolute;
  }
  .ao-chart .gp-date .feather-icon {
    display: none;
  }
  .multiselect-container {
    position: absolute;
    top: 0px;
    left: 100px;
    display: flex;
    gap: 10px;
    transform: scale(0.7);
    z-index: 1000;
  }
</style>
