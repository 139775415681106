<template>
<td
    :class="classes"
    :style="style"
    :title="title"
    :data-column="column.i"
    :data-type="column.type"
    :data-name="column.name"
    :tabindex="tabindex"
    @mousedown="handleMouseDown"
    >
    <a  v-if="column.actionable && actionicon && !noAction"
        class="my-colum-action"
        href="javascript:void(0)"
        @click="handleAction">
        <feather-icon :name="actionicon"/>
    </a>
    <template v-if="editing">
        <RenderProxy
            v-if="column.tagname"
            :tagname="column.tagname"
            :column="column"
            :meta="meta"
            :row="row"
            :config="column.config"
            />
        <select
            v-else-if="column.options"
            v-model="editingValue"
            @blur="handleBlur"
            @change="handleSelectChange"
            @keydown="handleKeyDown">
            <option v-for="option in column.options" :value="option">
                {{option}}
            </option>
        </select>
        <textarea
            v-else-if="column.type == 'html'"
            v-model="editingValue"
            @blur="handleBlur"
            @change="handleChange"
            @keydown="handleKeyDown"/>
        <input
            v-else
            v-model="editingValue"
            :list="options ? `${column.id}-options` : null"
            :type="column.type === 'date' ? 'date': 'text'"
            @blur="handleBlur"
            @change="handleChange"
            @keydown="handleKeyDown"/>
        <span v-if="column.type !== 'date'">{{editingValue}}<br v-if="editingValue && editingValue.endsWith('\n')"/></span>
        <span v-if="editingValue">{{formattedValue}}</span>
        <datalist v-if="options" :id="`${column.id}-options`">
            <option v-for="option in options" :value="option"/>
        </datalist>
    </template>
    <span v-else-if="column.actionable && !actionicon && !noAction">
        <a href="javascript:void(0)" @click="handleAction">
            {{formattedValue}}
        </a>
    </span>
    <span v-else-if="actionlink">
        <a
            :href="actionlink"
            :target="actionlink.startsWith('http') ? '_blank' : ''"
            @click="handleNavigate">
            {{formattedValue}}
        </a>
    </span>
    <span v-else-if="isLink && !noLink">
        <a :href="value" target="_blank">
            {{formattedValue}}
        </a>
    </span>
    <span v-else>{{formattedValue}}</span>
</td>
</template>
<script>
let utils = require('./my-utils')

module.exports = {
    components: {
        RenderProxy: {
            props: {
                tagname:  { type: String },
                row:      { type: Array  },
                column:   { type: Object },
                meta:     { type: Object },
                config:   { type: Object },
            },
            render(h) {
                let config = _.clone(this.config) || {}
                let props = _.clone(config.props) || {}
                _.assign(props, _.pick(this, ["row", "column"]))
                config.props = props
                return h(this.tagname, config, [])
            },
            mounted() {
                // console.log("RENDER PROXY MOUNTED")
            }
        },
    },
    props: {
        totals:     { type: Boolean, default: false },
        meta:       { type: Object },
        row:        { type: Array },
        rows:       { type: Array },
        column:     { type: Object },
        section:    { type: Object },
        editing:    { type: Boolean, default: false },
        height:     { type: Number },
        noStyle:    { type: Boolean, default: false },
        noClass:    { type: Boolean, default: false },
        noAction:   { type: Boolean, default: false },
        noLink:     { type: Boolean, default: false },
    },
    data() {
        return {
            editingValue: null,
        }
    },
    mounted() {
        if (this.editing)
            this.acttivateEditing()
    },
    watch: {
        editing() {
            if (this.editing)
                this.acttivateEditing()
        }
    },
    computed: {
        info() {
            let {row, column, meta} = this
            return {row, column, meta}
        },
        value() {
            let value = undefined
            if (this.totals)
                value = this.row[this.column.i - this.meta.dims.length]
            else
                value = this.row[this.column.i]
            if (this.column.override)
                value = this.column.override({
                    row: this.row,
                    column: this.column,
                    totals: this.totals,
                    value
                })
            return value
        },
        formattedValue() {
            return this.column.format(
                this.value,
                this.row,
                this.column,
                this.meta)
        },
        actionlink() {
            return this.column.actionlink ?
                this.column.actionlink(this.row, this.column, this.meta) : null
        },
        actionicon() {
            return this.column.actionicon ?
                this.column.actionicon(this.row, this.column, this.meta) : null
        },
        style() {
            if (this.noStyle)
                return null
            let style = this.column.style(
                this.value, this.row, this.column, this.meta)
            if (this.height !== undefined) {
                if (!style)
                    style = {}
                style.height = `${this.height}px`
            }
            return style
        },
        title() {
            return this.column.title(this.value, this.row, this.column, this.meta)
        },
        options() {
            if (!this.editing)
                return null

            if ((this.column.type !== 'string' &&
                this.column.type !== 'tagged') ||
                this.column.calc.indexOf('price_notes') !== -1)
                return null

            return _(this.rows)
                .map(`${this.column.i}`)
                .filter()
                .map(this.formatEditableValue)
                .filter(value => !_.startsWith(value, "***"))
                .countBy()
                .toPairs()
                .sortBy(([value, count]) => -count)
                .take(100)
                .map(([value, count]) => value)
                .sortBy()
                .value()
        },
        classes() {
            if (this.noClass)
                return null
            let {column, meta, row} = this
            let {visibleColumns} = this.section
            let classes = {
                "section-start": visibleColumns[0] === column,
                "section-end": visibleColumns[visibleColumns.length-1] === column,
                "my-column-editable": !this.totals && column.editable && column.editable(row, column, meta),
                "my-column-actionable": !this.totals && column.actionable,
                "editable": this.editing,
            }

            if (column.className)
                classes[column.className] = true

            if (column.columnClass) {
                let class_ = column.columnClass(this.value, row, column, meta)
                if (class_)
                    classes[class_] = true
            }

            return classes
        },
        isLink() {
            return _.startsWith("http", this.value)
        },
        tabindex() {
            return this.editing ? 1 : null
        },
    },
    methods: {
      acttivateEditing() {
            this.editingValue = this.formatEditableValue(this.value)
            _.defer(() => {
                let control = $(this.$el).find("select, input, textarea")
                control.focus()
                control.select()
            })
        },
        formatEditableValue(value) {
            if (_.isBoolean(value))
                return utils.l10n(value ? "yes" : "no")
            if (_.isNumber(value))
                return `${Math.round((value + Number.EPSILON) * 100) / 100}`
            if (_.isDate(value))
                return utils.formatDate(value)
            if (!_.isString(value))
                return `${value}`
            return value
        },
        handleBlur(e) {
            this.$emit("blur", e, this.info)
        },
    handleChange(e) {
      if (['PLM_output_priority'].indexOf(this.column.stream) !== -1) {
        this.checkOutputPriority();
      }

      this.$emit("change", e, this.info);
    },

    editIndefiniteGrade() {
      const formulasPerpetuity = [
        'sg_grade_adjustment_perpetuity_with_class',
        'sg_grade_adjustment_perpetuity',
      ];
      const { section } = this.column;
      const row = this.row;

      if ((!this.column.formula && section === '') || this.column.formula === 'sg_grade_finale') {
        for (let i = 0; i < this.meta.columns.length; i++) {
          if (
            section === this.meta.columns[i].section && this.meta.columns[i].formula
            && formulasPerpetuity.indexOf(this.meta.columns[i].formula) !== -1
          ) {
            if (row[i].toLowerCase() === 'yes') {
              const perpetuity = row[i];
              const grade = this.editingValue;
              this.$emit('requestReactionToConsent', {
                row, section, perpetuity, grade,
              });
            }
          }
        }
      } else if (formulasPerpetuity.indexOf(this.column.formula) !== -1) {
        for (let i = 0; i < this.meta.columns.length; i++) {
          if (
            (section === this.meta.columns[i].section && !this.meta.columns[i].formula)
            || (section === this.meta.columns[i].section && this.meta.columns[i].formula === 'sg_grade_finale')
          ) {
            if (row[i] !== '' && row[i] !== '<Очистить>') {
              const perpetuity = this.editingValue;
              const grade = row[i];
              this.$emit('requestReactionToConsent', {
                row, section, perpetuity, grade,
              });
            } else {
              // TODO: need to make a pop-up window
            }
          }
        }
      }
    },

    checkOutputPriority() {
      if (this.editingValue != 1) {
        return;
      }

      const getRowIndex = (key) => {
        const { columns } = this.meta;
        return columns.findIndex((item) => item.calc === key);
      };

      const { id } = this.column;
      const indexTG = getRowIndex('category_plus');
      const index = this.meta.columns.findIndex((item) => item.id === id);
      this.row[index] = Number(this.editingValue);
      const priorityRow = this.rows.find((item) => item.key !== this.row.key && item[index] == 1 && item[indexTG] === this.row[indexTG]);
      this.$emit('requestOutputPriority', {
        row: this.row,
        foundRow: priorityRow,
        filter: this.meta.filters.extraFilter2,
      });
    },

    handleSelectChange(e) {
      if (['store_adjusted_grades', 'store_adjusted_grades_with_class',
        'grade_adjustment_perpetuity', 'grade_adjustment_perpetuity_with_class'].indexOf(
        this.column.stream) !== -1) {
        this.editIndefiniteGrade();
      }

      this.$emit("change", e, this.info);
    },
        
        handleKeyDown(e) {
            this.$emit("keydown", e, this.info)
        },
        handleMouseDown(e) {
            this.$emit("click", e, this.info)
        },
        handleAction(e) {
            this.$emit("action", e, this.info)
        },
        handleNavigate(e) {
            this.$emit("navigate", e, this.info)
        },
    }
}
</script>
<style>
    
</style>