var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "gp-check": true,
        "form-check": !_vm.inline,
        "form-check-inline": _vm.inline
      }
    },
    [
      _c("input", {
        ref: "checkbox",
        staticClass: "form-check-input input",
        attrs: {
          id: _vm.id,
          type: _vm.type,
          name: _vm.name,
          disabled: _vm.disabled
        },
        domProps: { checked: _vm.checked || false },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          change: function($event) {
            return _vm.$emit("change", $event.target.checked)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "form-check-label", attrs: { for: _vm.id } },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }