let MicroEvent = require('microevent')


d3.selection.prototype.finishTextTransitons = function() {
    this.each(function() {
        let slots = d3.select(this).node().__transition
        _(slots)
            .values()
            .forEach((slot) => {
                let text = _.get(slot, "value.text")
                if (text !== undefined) {
                    $(this).text(text)
                }
                let i = _.findIndex(slot.tween, ({name}) => name === "text")
                if (i !== -1) {
                    slot.tween.splice(i,i+1)
                }
            })
    })
}

module.exports.jsyamlSchema = jsyaml.Schema.create([
    new jsyaml.Type('!flatten',   { kind: 'sequence', construct: _.flatten }),
    new jsyaml.Type('!assign',    { kind: 'sequence', construct: x => _.assign({}, ...x) }),
    new jsyaml.Type('!merge',     { kind: 'sequence', construct: x => _.merge({}, ...x) }),
    new jsyaml.Type('!stringify', { kind: 'mapping',  construct: JSON.stringify }),
    new jsyaml.Type('!stringify', { kind: 'sequence', construct: JSON.stringify }),
    new jsyaml.Type('!stringify', { kind: 'scalar',   construct: JSON.stringify }),
    new jsyaml.Type('!freeze', { kind: 'mapping',  construct: Object.freeze }),
    new jsyaml.Type('!freeze', { kind: 'sequence', construct: Object.freeze }),
    new jsyaml.Type('!eval', { kind: 'scalar', construct: code => {
        try {
            return eval(code)
        }
        catch (ex) {
            console.warn(code)
            console.warn(ex.message)
        }
    }})
])

let reuseData = (x,y) => {
    if (_.isEqual(x,y))
        return x
    else if (_.isPlainObject(x) && _.isPlainObject(y)) {
        let z = {}
        for (let k of _.keys(y))
            z[k] = reuseData(x[k], y[k])
        return z
    }
    /*else if (_.isArray(x) && _.isArray(y)) {
        let z = []
        for (let i=0; i<y.length; ++i)
            z[i] = reuseData(x[i], y[i])
    }*/
    else return y
}

module.exports.reuseData = reuseData

let l10n_table = {}
module.exports.l10n_table = l10n_table
module.exports.l10nEnable = window.location.hostname != "demo.goalprofit.com"

module.exports.l10n = (text, translation) => module.exports.l10nEnable ? l10n_table[text] || text : text

module.exports.prompt_l10n = (value) => {
    let translated_value = prompt(`Please provide translation for "${value}":`, l10n_table[value])
    if (translated_value === null)
        return
    if (translated_value !== "")
        l10n_table[value] = translated_value
    else
        delete l10n_table[value]
    module.exports.bridge.trigger("l10n", value)

    let patch = JSON.stringify([[value, translated_value !== "" ? translated_value : null]])

    Promise.resolve(
        $.ajax({
            url: '/graphql',
            method: 'POST',
            data: JSON.stringify({query: `mutation { updateTranslation(translation:${utils.quote(patch)}) }`}),
            dataType: 'json',
            contentType: 'application/json'
        }))
        .then(console.log)
        .catch(console.warn)
}


let quote_string = (x) =>
    `"${
        `${x}`.replace(/\\/g, "\\\\")
              .replace(/"/g, "\\\"")
              .replace(/\n/g, "\\n")
              .replace(/\r/g, "\\r")
              
     }"`

module.exports.quote_string = quote_string


let quote = (x, column) => {
    if (x === undefined)
        return "null"

    let type = column !== undefined ? column.type : undefined;
   
    switch (type) {
        case "docid":
        case "int8":
        case "int16":
        case "int32":
        case "int64":
        case "float":
        case "double":
        case "boolean":
            return `${x}`
        case "string":
        case "tagged":
            return quote_string(x) 
    }

    if (_.isString(x))
        return quote_string(x)

    if (_.isDate(x)) {
        if (type == "datetime")
            return (((((
                x.getFullYear()   * 100 +
                (x.getMonth()+1)) * 100 +
                x.getDate())      * 100 +
                x.getHours())     * 100 +
                x.getMinutes())   * 100 +
                x.getSeconds())
        else if (type === "date")
            return `\`${x.getFullYear()}-${`${x.getMonth()+1}`.padStart(2,'0')}-${`${x.getDate()}`.padStart(2,'0')}\``
        else if (type === "time")
            return (((
                x.getHours())     * 100 +
                x.getMinutes())   * 100 +
                x.getSeconds())
        else return `"${x.toISOString().split(".")[0]}"`
    }

    if (_.isArray(x))
        return `[${x.map((y) => quote(y, column)).join(", ")}]`

    if (_.isSet(x))
        return `[${[...x].map((y) => quote(y, column)).join(", ")}]`

    if (_.isPlainObject(x))
        return `{${_.toPairs(x).map(([k,v]) => `${k}: ${quote(v, column)}`).join(" ")}}`

    return `${x}`
}

module.exports.quote = quote

let parseDate = text => {
    let [y,m,d] = text.split("-").map((x) => parseInt(x))
    return new Date(y,m-1,d)
}

let parseDateTime = text => {
    let [ymd, hms] = text.split("T")
    let [Y,M,D] = ymd.split("-").map((x) => parseInt(x))
    let [h,m,s] = hms.split(":").map((x) => parseInt(x))
    return new Date(Y,M-1,D,h,m,s)   
}

module.exports.parseDate = parseDate
module.exports.parseDateTime = parseDateTime

let nextDate = date => {
    date = new Date(date)
    date.setDate(date.getDate() + 1)
    return date
}

module.exports.nextDate = nextDate

let prevDate = date => {
    date = new Date(date)
    date.setDate(date.getDate() - 1)
    return date
}

module.exports.prevDate = prevDate

let formatDate = date => {
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    return `${y}-${m<10?'0':''}${m}-${d<10?'0':''}${d}`
}

let formatTime = date => {
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()
    return `${h<10?'0':''}${h}:${m<10?'0':''}${m}:${s<10?'0':''}${s}`
}

let formatMoney = x => Number(x).toLocaleString(
    "en-US", {
      minimumFractionDigits:2,
      maximumFractionDigits:2
    }).replace(",","")

module.exports.formatDate = formatDate
module.exports.formatTime = formatTime
module.exports.formatMoney = formatMoney

let pendingRequests = []
let activeRequests = new Set()
module.exports.maxActiveRequests = 10
module.exports.maxBatchRequests = 10

let fetchWithAjaxOpts = async ajaxOpts => {
    let response = await fetch(ajaxOpts.url, {
        method: ajaxOpts.method,
        body: ajaxOpts.data,
        headers: {
            'content-type': ajaxOpts.contentType,
        }
    })

    if (response.status === 202) {
        let location = response.headers.get("location")
        while (true) {
            response = await fetch(location)
            if (response.status !== 204)
                break
        }
    }

    if (ajaxOpts.loadstart)
        ajaxOpts.loadstart()

    try {
        if (ajaxOpts.progress) {
            let contentLength = response.headers.get("x-content-length")
            if (contentLength) {
                contentLength = +contentLength
                let receivedLength = 0
                let chunks = []
                let reader = response.body.getReader()
                while (true) {
                    const {done, value} = await reader.read()
                    if (done)
                        break
                    chunks.push(value)
                    receivedLength += value.length
                    ajaxOpts.progress(receivedLength / contentLength)
                }
                let chunksAll = new Uint8Array(receivedLength)
                let position = 0;
                for( let chunk of chunks) {
                    chunksAll.set(chunk, position)
                    position += chunk.length
                }
                let result = new TextDecoder("utf-8").decode(chunksAll)
                return JSON.parse(result)
            }
        }
        return await response.json()
    }
    finally {
        if (ajaxOpts.loadend)
            ajaxOpts.loadend()
    }
}

module.exports.fetchWithAjaxOpts = fetchWithAjaxOpts

module.exports.fetch = async (url, init) => {
    let response = await fetch(url, init)
    if (response.status === 202) {
        let location = response.headers.get("location")
        while (true) {
            response = await fetch(location)
            if (response.status !== 204)
                break
        }
    }
    return response
}

let pickNextRequest = () => {
    if (pendingRequests.length > 0 && activeRequests.size < module.exports.maxActiveRequests) {
        let requests =
            _(pendingRequests)
                .sortBy((request) => {
                    let element = request.component && request.component.$el
                    if (element && module.exports.isElementInViewport(element)) {
                        let {top,left} = $(element).offset()
                        return $(document.body).width() * top + left
                    }
                    else return Infinity
                })
                .take(module.exports.maxBatchRequests)
                .value()
        if (requests.length > 0) {
            let visibleRequests = requests.filter((request) => {
                let element = request.component && request.component.$el
                return element && module.exports.isElementInViewport(element)
            })
            // console.log("requests", requests.length, "visibleRequests", visibleRequests.length)
            if (visibleRequests.length > 0)
                requests = visibleRequests
            for (request of requests)
                pendingRequests = _.without(pendingRequests, request)
            let batches = {}
            let aloneRequests = []
            for (let request of requests) {
                if (request.ajaxOpts.url.startsWith("/graphql")) {
                    // console.log(request.ajaxOpts.data)
                    let query = JSON.parse(request.ajaxOpts.data).query
                    let match = query.match(/^\s*query\s*{((\n|.)+)}\s*$/)
                    if (match) {
                        // console.log({request})
                        let batch = _.get(request, "component.batch") || ""
                        if (!batches[batch])
                            batches[batch] = { queries: [], requests: [] }
                        batches[batch].queries.push(match[1])
                        batches[batch].requests.push(request)
                        continue
                    }
                }
                aloneRequests.push(request)
            }
            
            for (let batch in batches) {
                let { queries, requests } = batches[batch]
                console.log(_.now(), "batch", batch, "requests", requests.length)
                if (batch) {
                    let query = `query {${_(queries).map((query, i) => `dataset${i}: ${query}`).join("\n")}}`
                    let ajaxOpts = {
                        url: `/graphql?batch=${batch}`,
                        method: 'POST',
                        data: JSON.stringify({query}),
                        dataType: 'json',
                        contentType: 'application/json'
                    }
                    fetchWithAjaxOpts(ajaxOpts)
                        .then((response) => {
                            for (let i=0; i < requests.length; ++i) {
                                let request = requests[i]
                                if (response.data) {
                                    let dataset = response.data[`dataset${i}`]
                                    try {
                                        request.deferred.resolve({data:{dataset}})
                                    }
                                    catch (ex) {
                                        console.warn(ex)
                                    }
                                }
                                else {
                                    request.deferred.resolve(response)
                                }
                            }
                        })
                        .catch((error) => {
                            for (let i=0; i < requests.length; ++i) {
                                let request = requests[i]
                                try {
                                    request.deferred.reject(error)
                                }
                                catch (ex) {
                                    console.warn(ex)
                                }
                            }
                        })  
                }
                else {
                    Promise.all(requests.map(request => fetchWithAjaxOpts(request.ajaxOpts)))
                        .then((responses) => {
                            console.log(_.now(), "batch completed")
                            for (let i=0; i<requests.length; ++i) {
                                let request = requests[i]
                                let response = responses[i]
                                request.deferred.resolve(response)
                            }
                        })
                        .catch((error) => {
                            for (let i=0; i<requests.length; ++i) {
                                let request = requests[i]
                                request.deferred.reject(error)
                            }
                        })
                }
                for (let request of requests) {
                    activeRequests.add(request)
                }
            }

            for (let request of aloneRequests) {
                if (request.component)
                    console.log("fetching data for", $(request.component.$el).attr("id"))
                // console.log(request.ajaxOpts)
                fetchWithAjaxOpts(request.ajaxOpts)
                    .then(request.deferred.resolve)
                    .catch(request.deferred.reject)
                activeRequests.add(request)
            }
        }
    }
}

let scheduleRequest = (component, ajaxOpts, instant) => {
    console.log(_.now(), "schedule request", _.get(component, "$attrs.id"), _.get(component, "reportId"), instant, ajaxOpts)
    if (instant) {
        return fetchWithAjaxOpts(ajaxOpts)
    }
     else {
        let deferred = $.Deferred()
        let request = {
            component,
            ajaxOpts,
            deferred,
        }
        pendingRequests.push(request)
        deferred.always(() => {
            activeRequests.delete(request)
            _.defer(() => pickNextRequest())
        })
        _.defer(() => pickNextRequest())
        return deferred.promise()
    }
}

module.exports.scheduleRequest = scheduleRequest

module.exports.query = ({id, name,report,stream,source,vars,dims,vals,cols,sort,filter0,filter1,filter2,filter3,expand,cores,cache,batch}, component, instant) => {
    let makeReport = (params, inner) => {
        // console.log("makeReport", params, inner)
        return `
            report(
                cores: ${quote(params.cores || 16)},
                ${ params.id ? `id: ${quote(params.id)},` : "" }
                ${ params.name ? `name: ${quote(params.name)},` : "" }
                ${ params.cache !== undefined ? `cache: ${params.cache},` : "" }
                ${ params.filter0 ? `filter0: ${quote(params.filter0)},` : "" }
                ${ params.filter1 ? `filter1: ${quote(params.filter1)},` : "" }
                ${ params.filter2 ? `filter2: ${quote(params.filter2)},` : "" }
                ${ params.filter3 ? `filter3: ${quote(params.filter3)},` : "" }
                ${ !_.isEmpty(vars) ? `vars: ${utils.quote(JSON.stringify(vars))},` : ""}
                ${ !_.isEmpty(params.dims) ? `dims: ${quote(params.dims.join(","))},` : "" }
                ${ !_.isEmpty(params.vals) ? `vals: ${quote(params.vals.join(","))},` : "" }
                ${ !_.isEmpty(params.cols) ? `cols: ${quote(params.cols.join(","))},` : "" }
                ${ !_.isEmpty(params.links) ? `links: ${quote(params.links)},` : "" }
                ${ !_.isEmpty(params.funcs) ? `funcs: ${quote(params.funcs)},` : "" }
                ${ params.expand ? `expand: ${quote(params.expand)},` : "" }
                sort: [${_.isArray(params.sort) ? params.sort.join(",") : "" }])
            {
                ${inner}
            }`
    }

    let query = makeReport({
            name,
            cores,
            cache,
            filter0,
            filter1,
            filter2,
            filter3,
            dims,
            vals,
            cols,
            sort,
            expand,
        },
        "rows columns { type }")

    // console.log(query)

    while (source) {
        query = makeReport(source, query)
        source = source.source
    }

    if (stream)
        query = `
            query {
                dataset {
                    streams {
                        ${stream} {
                           ${query} 
                        }
                    }
                }
            }`
    else
        query = `
            query {
                dataset {
                    report(name:"${report}") {
                       ${query} 
                    }
                }
            }`

    let ajaxOpts = {
        url: "/graphql",
        method: "POST",
        data: JSON.stringify({query}),
        dataType: "json",
        contentType: "application/json",
    }
    if (!component && batch)
        component = {batch}
    return scheduleRequest(component, ajaxOpts, instant)
        .then((result) => {
            if (result.errors) {
                console.warn(query)
                for (error of result.errors)
                    console.warn(error.message)
                throw result.errors
            }
            let report = stream
                ? result.data.dataset.streams[stream].report
                : result.data.dataset.report.report
            while (report.report) {
                report = report.report
            }
            let {rows, columns} = source ? report.report : report
            for (let i = 0; i < columns.length; ++i) {
                switch (columns[i].type) {
                    case "date":
                        for (row of rows) {
                            let key = row[i]
                            if (key == 0)
                                row[i] = null
                            else
                                row[i] = new Date(
                                    key / 10000,
                                    key / 100 % 100 - 1,
                                    key / 1 % 100)
                        }
                        break

                    case "datetime":
                        for (row of rows) {
                            let key = row[i]
                            if (key == 0)
                                row[i] = null
                            else {
                            // console.log(key)
                                row[i] = new Date(
                                    key / 10000000000,
                                    key / 100000000 % 100 - 1,
                                    key / 1000000 % 100)
                                row[i].setHours(
                                    key / 10000 % 100,
                                    key / 100 % 100,
                                    key / 1 % 100)
                            }
                        }
                        break
                }
            }
            return rows
        })
}

module.exports.randomId = () => Math.random().toString(36).substring(2,16)

module.exports.nextReportId = () => Math.random().toString(36).substring(2,16)

module.exports.bridge = new MicroEvent()

module.exports.formatTemperature = (x) =>
    x != null ? new Number((x * 1.8) + 32).toLocaleString(undefined, {maximumFractionDigits:0}) + "°" : ""

let formatLocales = {
    "ar-001": require("./d3-format/locale/ar-001"),
    "ar-JO": require("./d3-format/locale/ar-JO"),
    "ar-SA": require("./d3-format/locale/ar-SA"),
    "da-DK": require("./d3-format/locale/da-DK"),
    "es-MX": require("./d3-format/locale/es-MX"),
    "nl-NL": require("./d3-format/locale/nl-NL"),
    "ar-AE": require("./d3-format/locale/ar-AE"),
    "ar-KM": require("./d3-format/locale/ar-KM"),
    "ar-SD": require("./d3-format/locale/ar-SD"),
    "de-CH": require("./d3-format/locale/de-CH"),
    "fi-FI": require("./d3-format/locale/fi-FI"),
    "pl-PL": require("./d3-format/locale/pl-PL"),
    "ar-BH": require("./d3-format/locale/ar-BH"),
    "ar-KW": require("./d3-format/locale/ar-KW"),
    "ar-SO": require("./d3-format/locale/ar-SO"),
    "de-DE": require("./d3-format/locale/de-DE"),
    "fr-CA": require("./d3-format/locale/fr-CA"),
    "pt-BR": require("./d3-format/locale/pt-BR"),
    "ar-DJ": require("./d3-format/locale/ar-DJ"),
    "ar-LB": require("./d3-format/locale/ar-LB"),
    "ar-SS": require("./d3-format/locale/ar-SS"),
    "en-CA": require("./d3-format/locale/en-CA"),
    "fr-FR": require("./d3-format/locale/fr-FR"),
    "pt-PT": require("./d3-format/locale/pt-PT"),
    "ar-DZ": require("./d3-format/locale/ar-DZ"),
    "ar-LY": require("./d3-format/locale/ar-LY"),
    "ar-SY": require("./d3-format/locale/ar-SY"),
    "en-GB": require("./d3-format/locale/en-GB"),
    "he-IL": require("./d3-format/locale/he-IL"),
    "ru-RU": require("./d3-format/locale/ru-RU"),
    "ar-EG": require("./d3-format/locale/ar-EG"),
    "ar-MA": require("./d3-format/locale/ar-MA"),
    "ar-TD": require("./d3-format/locale/ar-TD"),
    "en-IE": require("./d3-format/locale/en-IE"),
    "hu-HU": require("./d3-format/locale/hu-HU"),
    "sl-SI": require("./d3-format/locale/sl-SI"),
    "ar-EH": require("./d3-format/locale/ar-EH"),
    "ar-MR": require("./d3-format/locale/ar-MR"),
    "ar-TN": require("./d3-format/locale/ar-TN"),
    "en-IN": require("./d3-format/locale/en-IN"),
    "it-IT": require("./d3-format/locale/it-IT"),
    "sv-SE": require("./d3-format/locale/sv-SE"),
    "ar-ER": require("./d3-format/locale/ar-ER"),
    "ar-OM": require("./d3-format/locale/ar-OM"),
    "ar-YE": require("./d3-format/locale/ar-YE"),
    "en-US": require("./d3-format/locale/en-US"),
    "ja-JP": require("./d3-format/locale/ja-JP"),
    "uk-UA": require("./d3-format/locale/uk-UA"),
    "ar-IL": require("./d3-format/locale/ar-IL"),
    "ar-PS": require("./d3-format/locale/ar-PS"),
    "ca-ES": require("./d3-format/locale/ca-ES"),
    "es-BO": require("./d3-format/locale/es-BO"),
    "ko-KR": require("./d3-format/locale/ko-KR"),
    "zh-CN": require("./d3-format/locale/zh-CN"),
    "ar-IQ": require("./d3-format/locale/ar-IQ"),
    "ar-QA": require("./d3-format/locale/ar-QA"),
    "cs-CZ": require("./d3-format/locale/cs-CZ"),
    "es-ES": require("./d3-format/locale/es-ES"),
    "mk-MK": require("./d3-format/locale/mk-MK"),
}

let timeLocales = {
    "ar-EG": require("./d3-time-format/locale/ar-EG"),
    "ar-SY": require("./d3-time-format/locale/ar-SY"),
    "ca-ES": require("./d3-time-format/locale/ca-ES"),
    "cs-CZ": require("./d3-time-format/locale/cs-CZ"),
    "da-DK": require("./d3-time-format/locale/da-DK"),
    "de-CH": require("./d3-time-format/locale/de-CH"),
    "de-DE": require("./d3-time-format/locale/de-DE"),
    "en-CA": require("./d3-time-format/locale/en-CA"),
    "en-GB": require("./d3-time-format/locale/en-GB"),
    "en-US": require("./d3-time-format/locale/en-US"),
    "es-ES": require("./d3-time-format/locale/es-ES"),
    "es-MX": require("./d3-time-format/locale/es-MX"),
    "fa-IR": require("./d3-time-format/locale/fa-IR"),
    "fi-FI": require("./d3-time-format/locale/fi-FI"),
    "fr-CA": require("./d3-time-format/locale/fr-CA"),
    "fr-FR": require("./d3-time-format/locale/fr-FR"),
    "he-IL": require("./d3-time-format/locale/he-IL"),
    "hr-HR": require("./d3-time-format/locale/hr-HR"),
    "hu-HU": require("./d3-time-format/locale/hu-HU"),
    "it-IT": require("./d3-time-format/locale/it-IT"),
    "ja-JP": require("./d3-time-format/locale/ja-JP"),
    "ko-KR": require("./d3-time-format/locale/ko-KR"),
    "mk-MK": require("./d3-time-format/locale/mk-MK"),
    "nb-NO": require("./d3-time-format/locale/nb-NO"),
    "nl-BE": require("./d3-time-format/locale/nl-BE"),
    "nl-NL": require("./d3-time-format/locale/nl-NL"),
    "pl-PL": require("./d3-time-format/locale/pl-PL"),
    "pt-BR": require("./d3-time-format/locale/pt-BR"),
    "ru-RU": require("./d3-time-format/locale/ru-RU"),
    "sv-SE": require("./d3-time-format/locale/sv-SE"),
    "tr-TR": require("./d3-time-format/locale/tr-TR"),
    "uk-UA": require("./d3-time-format/locale/uk-UA"),
    "vi-VN": require("./d3-time-format/locale/vi-VN"),
    "zh-CN": require("./d3-time-format/locale/zh-CN"),
    "zh-TW": require("./d3-time-format/locale/zh-TW"),
}


module.exports.locales = _.merge(timeLocales, formatLocales)

for (let locale of _.keys(module.exports.locales)) {
    module.exports.locales[locale.split('-')[1]] = module.exports.locales[locale]
}

let formatMillisecond = d3.timeFormat(".%L")
let formatSecond = d3.timeFormat(":%S")
let formatMinute = d3.timeFormat("%I:%M")
let formatHour = d3.timeFormat("%I %p")
let formatDay = d3.timeFormat("%a %d")
let formatWeek = d3.timeFormat("%b %d")
let formatMonth = d3.timeFormat("%B")
let formatYear = d3.timeFormat("%Y")

module.exports.multiFormatDate = (date) => {
    return (d3.timeSecond(date) < date ? d3.timeFormat(".%L")
          : d3.timeMinute(date) < date ? d3.timeFormat(":%S")
          : d3.timeHour(date)   < date ? d3.timeFormat("%I:%M")
          : d3.timeDay(date)    < date ? d3.timeFormat("%I %p")
          : d3.timeMonth(date)  < date ? d3.timeFormat("%b %d") /*(d3.timeWeek(date) < date ? formatDay : formatWeek)*/
          : d3.timeYear(date)   < date ? d3.timeFormat("%B")
          : d3.timeFormat("%Y"))(date)
}

module.exports.isElementInViewport = (el) => {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    let visible = $(el).is(":visible")
    let rect = el.getBoundingClientRect();

    // console.log("isElementInViewport", el, visible, rect)

    return (
        visible &&
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

module.exports.getScrollbarSize = () => {
    let envelope = document.createElement("div")
    envelope.style.overflow = "scroll"
    envelope.style.visibility = "hidden"
    envelope.style.msOverflowStyle = "scrollbar"
    document.body.appendChild(envelope)

    let content = document.createElement("div")
    envelope.appendChild(content)

    let scrollbarSize = (envelope.offsetWidth - content.offsetWidth)

    document.body.removeChild(envelope)

    return scrollbarSize
}

module.exports.transparentize = (x,k) => {
    if (_.isString(x)) {
        if (k < 0) k = 0
        if (k > 1) k = 1
        if (x.indexOf("rgb(") == 0) {
            return x
                .replace("rgb(", "rgba(")
                .replace(")", `,${1-k})`)
        }
        if (x.indexOf("#") == 0) {
            let y = Math.round((1-k)*100).toString(16)
            return `${x}${y.length == 1 ? "0" : ""}${y}` 
        }
    }
    return x
}

module.exports.loadLocalConfigs = (family) => {
    let configs = []
    let config = localStorage[`${family}-config`] || null
    if (config) {
        try {
            config = JSON.parse(config)
        }
        catch (ex) {
            console.warn("failed to load local config", family, ex)
            delete localStorage[`${family}-config`]
        }
        if (!_.isPlainObject(config))
            config = null
    }
    return { config, configs }
}

module.exports.loadSavedConfigs = async (family) => {
    let configs = await fetch(
        `/storage/${family}`,
        {method: "GET", headers: utils.nocahe()}
    ).then(res => res.json())
    configs =
        _(configs)
            .toPairs()
            .map(([id, config]) => _.assign(config, {id}))
            .sortBy(({name}) => name)
            .value()

    return { configs }
}

window.utils = module.exports

module.exports.referenceDateHelper = {
    data() {
        return { referenceDate: window.referenceDate }
    },
    mounted() {
        this.referenceDate = window.referenceDate
        module.exports.bridge.bind("referenceDateChanged", this.updateReferenceDate)
    },
    beforeDestroy() {
        module.exports.bridge.unbind("referenceDateChanged", this.updateReferenceDate)
    },
    methods: {
        updateReferenceDate() {
            this.referenceDate = window.referenceDate
        },
    }
}

module.exports.hasAggregationHint = (name) =>
    name.match(/(sum|min|max|one|and|any|all|cnt|avg|first|last|mix)_/)

module.exports.isAggregationFormula = (formula) =>
    formula.match(/(sum|min|max|one|and|any|all|cnt|avg|first|last)\s*\(.*\)/)

module.exports.hasTimeframeFilter = (formula) =>
    formula.match(/(^|[^.a-z_])in_date_range/) ||
    formula.match(/(^|[^.a-z_])at_end_date/) ||
    formula.match(/(^|[^.a-z_])at_start_date/) ||
    formula.match(/(^|[^.a-z_])at_date_after_end/) ||
    formula.match(/(^|[^.a-z_])at_date_before_start/) ||
    formula.match(/(^|[^.a-z_])at_reference_date/) ||
    formula.match(/(^|[^.a-z_])start_date/) ||
    formula.match(/(^|[^.a-z_])end_date/) ||
    formula.match(/(^|[^.a-z_])date_before_start/) ||
    formula.match(/(^|[^.a-z_])date_after_end/) ||
    formula.match(/(^|[^.a-z_])reference_date_plus_1/) ||
    formula.match(/(^|[^.a-z_])reference_date/)


module.exports.resolveSubstitutes = (calc, formulas) => {
    let loop = (calc, phase = "cols", depth = 0) => {
        if (depth == 10)
            return calc
        return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            let formula = formulas[symbol]
            if (formula !== undefined) {
                let nextPhase = phase
                if (module.exports.isAggregationFormula(formula)) {
                    switch (phase) {
                        case "cols":
                            nextPhase = "vals"
                            break
                        case "vals":
                            return symbol
                    }
                }
                return `(${loop(formula, nextPhase, depth + 1)})`
            }
            else
                return symbol
        })
    }
    calc = loop(calc)
    if (calc.match(/^\(.*\)$/))
        calc = calc.slice(1, calc.length-1)
    return calc
}

module.exports.configHelpers = {
    props: {
        locale:     { type: String,  default: "EN" },
        currency:   { type: String,  default: "USD" },
        metrics:    { type: Array,   default: () => [] },
        formats:    { type: Object,  default: () => ({}) },
        formulas:   { type: Object,  default: () => ({}) },
        timeframes: { type: Object,  default: () => ({}) },
        attributes: { type: Array,   default: () => [] },
        calc_columns: { type: Array,   default: () => [] },
    },
    computed: {
        attributesByName() {
            return _(this.attributes).filter(attribute => !attribute.deleted).map(attribute => [attribute.name, attribute]).fromPairs().value()
        },
        attributesByCalc() {
            return _(this.attributes).filter(attribute => !attribute.deleted).map(attribute => [attribute.calc, attribute]).fromPairs().value()
        },
        attributesByNameCalc() {
            return _(this.attributes).filter(attribute => !attribute.deleted).map(attribute => [attribute.name + attribute.calc, attribute]).fromPairs().value();
        },
        attributesById() {
            return _(this.attributes).filter(attribute => !attribute.deleted).map(attribute => [attribute.id, attribute]).fromPairs().value();
        },
        metricsByName() {
            return _(this.metrics).filter(metric => !metric.deleted).map(metric => [metric.name, metric]).fromPairs().value()
        },
        metricsByFormula() {
            return _(this.metrics).filter(metric => !metric.deleted).map(metric => [metric.formula.split(/[\s,]+/g)[0], metric]).fromPairs().value()
        },
        calcColumnById() {
            return _(this.calc_columns).filter(calc_column => !calc_column.deleted).map(calc_column => [calc_column.id, calc_column]).fromPairs().value()
        },
    },
    methods: {
        isAggregationFormula(formula) {
            return module.exports.isAggregationFormula(formula)
        },
        resolveSubstitutes(calc) {
            return module.exports.resolveSubstitutes(calc, this.formulas)
        },
        resolveDateConditions(calc, start_date, end_date, reference_date) {
            if (start_date) {
                let date_before_start = new Date(start_date)
                date_before_start.setDate(date_before_start.getDate() - 1)

                let date_after_end = new Date(end_date)
                date_after_end.setDate(date_after_end.getDate() + 1)

                if (!reference_date)
                    reference_date = start_date

                reference_date_plus_1 = module.exports.nextDate(reference_date)

                let quote_date = (x) => quote(x, {type:"date"})

                let in_date_range = `date >= ${quote_date(start_date)} && date <= ${quote_date(end_date)}`
                let at_start_date = `date == ${quote_date(start_date)}`
                let at_end_date = `date == ${quote_date(end_date)}`
                let at_date_before_start = `date == ${quote_date(date_before_start)}`
                let at_date_after_end = `date == ${quote_date(date_after_end)}`
                let at_reference_date = `date == ${quote_date(reference_date)}`

                return calc
                    .replace(/(^|[^.a-z_])in_date_range/g,        (a,b) => b+in_date_range)
                    .replace(/(^|[^.a-z_])at_end_date/g,          (a,b) => b+at_end_date)
                    .replace(/(^|[^.a-z_])at_start_date/g,        (a,b) => b+at_start_date)
                    .replace(/(^|[^.a-z_])at_date_after_end/g,    (a,b) => b+at_date_after_end)
                    .replace(/(^|[^.a-z_])at_date_before_start/g, (a,b) => b+at_date_before_start)
                    .replace(/(^|[^.a-z_])at_reference_date/g,    (a,b) => b+at_reference_date)
                    .replace(/(^|[^.a-z_])start_date/g,           (a,b) => b+quote_date(start_date))
                    .replace(/(^|[^.a-z_])end_date/g,             (a,b) => b+quote_date(end_date))
                    .replace(/(^|[^.a-z_])date_before_start/g,    (a,b) => b+quote_date(date_before_start))
                    .replace(/(^|[^.a-z_])date_after_end/g,       (a,b) => b+quote_date(date_after_end))
                    .replace(/(^|[^.a-z_])reference_date_plus_1/g,(a,b) => b+quote_date(reference_date_plus_1))
                    .replace(/(^|[^.a-z_])reference_date/g,       (a,b) => b+quote_date(reference_date))
            }
            else {
                return calc
                    .replace(/(^|[^.a-z_])in_date_range/g,        (a,b) => b+"true")
                    .replace(/(^|[^.a-z_])at_end_date/g,          (a,b) => b+"true")
                    .replace(/(^|[^.a-z_])at_start_date/g,        (a,b) => b+"true")
                    .replace(/(^|[^.a-z_])at_date_after_end/g,    (a,b) => b+"false")
                    .replace(/(^|[^.a-z_])at_date_before_start/g, (a,b) => b+"false")
                    .replace(/(^|[^.a-z_])at_reference_date/g,    (a,b) => b+"true")
                    .replace(/(^|[^.a-z_])start_date/g,           (a,b) => b+"date")
                    .replace(/(^|[^.a-z_])end_date/g,             (a,b) => b+"date")
                    .replace(/(^|[^.a-z_])date_before_start/g,    (a,b) => b+"(date-1)")
                    .replace(/(^|[^.a-z_])date_after_end/g,       (a,b) => b+"(date+1)")
                    .replace(/(^|[^.a-z_])reference_date_plus_1/g,(a,b) => b+"date")
                    .replace(/(^|[^.a-z_])reference_date/g,       (a,b) => b+"(date-1)")
            }
        }
    },
}

module.exports.extraFilters = {
    props: {
        stream: { type: String, default: "default" },
        groups: { type: Array, default: () => [] },
    },
    data() {
        return {
            crossFilters: this.$crossFilters,
        }
    },
    mounted() {
        module.exports.bridge.bind("crossFiltersChanged", this.crossFiltersChanged)
    },
    beforeDestroy() {
        module.exports.bridge.unbind("crossFiltersChanged", this.crossFiltersChanged)
    },
    computed: {
        extraFilters() {
            let extraFilter0 = []
            let extraFilter1 = []
            let extraFilter2 = []
            let extraFilter3 = []

            let getBounds = (node) => node.bounds || node.$parent && getBounds(node.$parent)

            let uids = {}
            let root = this
            while (root.$parent !== undefined) {
                root = root.$parent
            }
            let loop = (node) => {
                uids[node._uid] = node
                for (child of node.$children)
                    loop(child)
            }
            loop(root)

            let bounds = getBounds(this)

            _(this.crossFilters)
                .toPairs()
                .filter(([uid]) => {
                    let node = uid < 0 ? uids[-uid] : uids[uid]
                    return node && bounds === getBounds(node)
                })
                .filter(([uid, {groups, stream}]) =>
                    uid != this._uid &&
                    (_.isEmpty(groups) || _.isEmpty(this.groups)
                        ? stream === this.stream
                        : _.intersection(groups.slice(0,1), this.groups).length > 0))
                .forEach(([uid, {filter0, filter1, filter2, filter3}]) => {
                    if (!_.isEmpty(filter0)) extraFilter0.push(filter0)
                    if (!_.isEmpty(filter1)) extraFilter1.push(filter1)
                    if (!_.isEmpty(filter2)) extraFilter2.push(filter2)
                    if (!_.isEmpty(filter3)) extraFilter3.push(filter3)
                })

            return {
                extraFilter0: extraFilter0.join(" && "),
                extraFilter1: extraFilter1.join(" && "),
                extraFilter2: extraFilter2.join(" && "),
                extraFilter3: extraFilter3.join(" && "),
            }
        },
        extraFilter0() { return this.extraFilters.extraFilter0 },
        extraFilter1() { return this.extraFilters.extraFilter1 },
        extraFilter2() { return this.extraFilters.extraFilter2 },
        extraFilter3() { return this.extraFilters.extraFilter3 },
    },
    methods: {
        crossFiltersChanged() {
            this.crossFilters = this.$crossFilters
        },
        joinFilters(filters) {
            return _(filters)
                .filter((filter) => !_.isEmpty(filter))
                .map((filter) => `(${filter})`)
                .join(" && ")
        },
    }
}

module.exports.getTextWidth = function(text, font) { // "bold 12pt arial"
    let canvas = this.canvas || (this.canvas = document.createElement("canvas"))
    let context = canvas.getContext("2d")
    context.font = font
    return context.measureText(text).width
}

module.exports.formatSearchItem = (text, matches) => {
    if (!matches)
        return [{text, matched: false}]
    let indices = _(matches).map(({indices}) => indices).flatten().value()
    let i = 0
    let parts = []
    while (i < text.length) {
        if (indices.length > 0) {
            let [a,b] = indices[0]
            if (i === a) {
                parts.push({
                    text: text.slice(a, b+1),
                    matched: true
                })
                i = b+1
                indices = indices.slice(1)
            }
            else {
                parts.push({
                    text: text.slice(i, a),
                    matched: false
                })
                i = a
            }
        }
        else {
            parts.push({
                text: text.slice(i),
                matched: false
            })
            i = text.length
        }
    }
    return parts
}

let deepFreeze = (object, depth=0) => {
    if (object?.__ob__) {
        console.warn("attempt to freeze reactive object", object)
        return object
    }

    if (depth == 10) {
        console.warn("maximum deep freeze recursion depth reached", object)
        return object
    }
    let propNames = Object.getOwnPropertyNames(object)

    for (const name of propNames) {
        if (name.startsWith("__"))
            continue

        let value = object[name];

        if (value && typeof value === "object") {
            deepFreeze(value, depth+1)
        }
    }
    return Object.freeze(object)
}

module.exports.deepFreeze = deepFreeze

module.exports.removeLocationHash = () =>
    history.replaceState(
        null,
        document.title,
        window.location.pathname + window.location.search)

module.exports.nocahe = () => {
    let headers = new Headers()
    headers.append('pragma', 'no-cache')
    headers.append('cache-control', 'no-cache')
    return headers
}


module.exports.makeFilter = (filters) =>
    _(filters)
        .filter((filter) => !_.isEmpty(filter))
        .map((filter) => `(${filter})`)
        .join(" && ")

module.exports.setInclude = (uid, data) =>
    module.exports.bridge.trigger("setInclude", uid, data)

module.exports.unsetInclude = (uid) =>
    module.exports.bridge.trigger("unsetInclude", uid)

module.exports.columnsHelpers = {
    methods: {
        resolveVars(name) {
            return name
        },
        makeCols(cols, section, column) {
            let order = cols.length + 1
            let [style, rowStyle] = this.makeStyles(column)

            if (column.type == "attribute") {
                let name = column.alias || column.name
                let calc = column.calc.replace(".", "_")
                let format = this.formats[column.format] || column.format
                if (!format)
                    calc = `prefix(min_${calc}, max_${calc})`
                name = this.resolveVars(name)
                cols.push(
                    _.assign({
                        order,
                        name,
                        calc,
                        style,
                        rowStyle,
                        format,
                        section: this.sectionName(section),
                        className: 'my-calc-' + _.kebabCase(column.calc),
                    }, _.omit(column, ["name", "type", "calc", "format", "style", "rowStyle"])))
            }
            if (column.type == "metric") {
                let calc = undefined
                let symbols = column.formula.split(/[\s,]+/g)
                for (let symbol of symbols) {

                    let formula = this.formulas[symbol]
                    let timeframe = column.timeframe || section.timeframe
                    if (timeframe === "past")
                        timeframe = this.pastTimeframe
                    if (timeframe === "future")
                        timeframe = this.futureTimeframe
                    if (!this.timeframes[timeframe])
                        timeframe = "reference_date"
                    if (formula !== undefined) {
                        if (this.isAggregationFormula(formula))
                            calc = `${symbol}_${timeframe}`
                        else {
                            let resolveSubstitutes = (calc, depth = 0) => {
                                if (depth == 10)
                                    return calc
                                return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                                    let formula = this.formulas[symbol]
                                    if (formula !== undefined)
                                        if (this.isAggregationFormula(formula))
                                            return `${symbol}_${timeframe}`
                                        else
                                            return `(${resolveSubstitutes(formula, depth + 1)})`
                                    else
                                        return symbol
                                })
                            }
                            calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                                // console.log(2,symbol)
                                return resolveSubstitutes(symbol)
                            })
                        }
                    }

                    let override = undefined

                    if (column.editable)
                        override = this.override

                    let name = column.name

                    if (this.hyphenate)
                        name = hyphenate(name, this.hyphenate)

                    if (column.metric && 
                        column.metric.timeframe !== timeframe &&
                        !(column.metric.timeframe === "past" && timeframe === this.pastTimeframe) &&
                        !(column.metric.timeframe === "future" && timeframe === this.futureTimeframe))
                    {
                        name = `${name} (${this.getTimeframeName(timeframe)})`
                    }

                    if (column.alias)
                        name = column.alias

                    name = this.resolveVars(name)

                    if (calc !== undefined) {
                        if (symbol === symbols[0])
                            cols.push(
                                _.assign({
                                    order,
                                    name,
                                    calc,
                                    style,
                                    rowStyle,
                                    format: this.formats[column.format] || column.format,
                                    section: this.sectionName(section),
                                    override,
                                    className: 'my-calc-' + _.kebabCase(symbol),
                                },
                                _.omit(column, ["name", "type", "format", "style", "rowStyle"])))
                        else
                            cols.push({
                                order,
                                name: calc,
                                calc,
                                section: this.sectionName(section),
                                show: false,
                                id: `${column.id}_${symbols.indexOf(symbol)}`
                            })
                    }
                }
            }
        },
        makeVals(vals, section, column) {
            if (column.type == "attribute") {
                let {calc} = column
                let format = this.formats[column.format] || column.format

                let referenceDate = parseDate(this.referenceDate)
                let date = utils.nextDate(referenceDate)

                let resolve = calc =>
                    this.resolveDateConditions(
                        this.resolveSubstitutes(calc),
                        date,
                        date,
                        referenceDate)

                if (!format) {
                    vals[`min_${calc.replace(".", "_")}`] = `min(${resolve(calc)})`
                    vals[`max_${calc.replace(".", "_")}`] = `max(${resolve(calc)})`
                }
                else
                    vals[calc.replace(".", "_")] = resolve(calc)
            }
            if (column.type == "metric") {
                // let startDate = null
                // let endDate = null
                // try {
                let referenceDate = parseDate(this.referenceDate)
                let timeframe = column.timeframe || section.timeframe
                if (timeframe === "past")
                    timeframe = this.pastTimeframe
                if (timeframe === "future")
                    timeframe = this.futureTimeframe
                if (!this.timeframes[timeframe])
                    timeframe = "reference_date"

                let [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate)

                let resolveSubstitutes = (calc, depth = 0) => {
                    // console.log("resolveSubstitutes", calc, depth)
                    if (depth == 10)
                        return calc
                    return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                        let formula = this.formulas[symbol]
                        if (formula !== undefined && !this.isAggregationFormula(formula))
                            return `(${resolveSubstitutes(formula, depth + 1)})`
                        else
                            return symbol
                    })
                }

                let registerFormula = (symbol) => {
                    // console.log("registerFormula", symbol)
                    let formula = this.formulas[symbol]
                    if (formula !== undefined) {
                        if (this.isAggregationFormula(formula)) {
                            vals[`${symbol}_${timeframe}`] =
                                this.resolveDateConditions(
                                    resolveSubstitutes(formula),
                                    startDate,
                                    endDate,
                                    referenceDate)
                        }
                        else {
                            for (let [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                                // console.log(1,symbol)
                                registerFormula(symbol)
                            }
                        }
                    }
                }

                let symbols = column.formula.split(/[\s,]+/g)
                for (let symbol of symbols)
                    registerFormula(symbol)
            }
        },
        getTimeframeName(timeframe) {
            return this.timeframes[timeframe]?.name || timeframe
        },
    }
}

module.exports.editingHelpers = {
    props: {
        username: { type: String },
        product: { type: String, default: "pim" },
    },
    mounted() {
        this.editableSeqN = 0
        this.updateReports = {}
    },
    methods: {
        modifyRow(row, func) {
            let table = this.$refs.table
            if (row.key) {
                let key = row.key
                row = _.clone(row)
                Vue.set(table.rowOverrides, key, row)
            }
            row.__cache = {}
            func(row)
            return row
        },
        async updateRow(row, streams = []) {
            let startTime = performance.now()

            let table = this.$refs.table

            let rowFilters1 = []
            let rowFilters2 = []
            _.forEach(table.dims, (dim, i) => {
                let calc = _.isString(dim) ? dim : dim.calc
                if (calc === "item" || calc === "class")
                    rowFilters1.push(`${calc} == ${utils.quote(row[i])}`)
                else
                    rowFilters2.push(`${calc} == ${utils.quote(row[i])}`)
            })

            let makeFilter = (filters) =>
                _(filters)
                    .filter()
                    .map((filter) => `(${filter})`)
                    .join(" && ")

            let filter0 = makeFilter([table.extraFilter0, table.filter0])
            let filter1 = makeFilter([table.extraFilter1, table.filter1].concat(rowFilters1))
            let filter2 = makeFilter([table.extraFilter2, table.filter2].concat(rowFilters2))

            let reportId = utils.randomId()
            let reportKey = JSON.stringify({filter0, filter1, filter2})
            if (this.updateReports[reportKey])
                utils.bridge.trigger("cancelReport", this.updateReports[reportKey])
            this.updateReports[reportKey] = reportId

            let vals = table.vals
            let cols = table.cols

            let affectedVals = []
            let affectedCols = []

            for (let stream of streams) {

                if (!this.dependencies) {
                    this.dependencies = await this.getDependencies()
                    console.log("dependencies", this.dependencies)
                }
                if (!this.streamLinks) {
                    let {data:{dataset:{streams:{stream:{links}}}}} = await fetch("/graphql", {
                        method: "POST",
                        body: JSON.stringify({query: `query{dataset{streams{stream:${this.stream}{links{linkName sourceName}}}}}`})
                    }).then(response => response.json())
                    this.streamLinks = _(links)
                        .map(({linkName, sourceName}) => [linkName, sourceName])
                        .fromPairs()
                        .value()
                    console.log("streamLinks", this.streamLinks)
                }
                
                let affectedSources = new Set()
                affectedSources.add(stream)
                let collectAffectedSources = source => {
                    for (let dep of this.dependencies[source] || []) {
                        if (!affectedSources.has(dep)) {
                            affectedSources.add(dep)
                            collectAffectedSources(dep)
                        }
                    }
                }
                collectAffectedSources(stream)

                for (let val of vals) {
                    for (let match of val.calc.matchAll(
                        /([a-zA-Z_][a-zA-Z_0-9]*)\.[a-zA-Z_][a-zA-Z_0-9]*/g))
                    {
                        let source = this.streamLinks[match[1]]
                        if (affectedSources.has(source) && affectedVals.indexOf(val) === -1)
                            affectedVals.push(val)
                    }
                }
            }

            let additionalVals = []

            for (let col of cols) {
                if (_.some(affectedVals, val => col.calc.indexOf(val.name) !== -1)) {
                    affectedCols.push(col)
                    for (let match of col.calc.matchAll(/([a-zA-Z_][a-zA-Z_0-9]*)/g)) {
                        let val = vals.find(({name}) => name === match[1])
                        if (val &&
                                affectedVals.indexOf(val) === -1 &&
                                additionalVals.indexOf(val) === -1)
                            additionalVals.push(val)
                    }
                }
            }

            for (let val of additionalVals)
                affectedVals.push(val)

            // console.log({affectedSources, affectedVals, affectedCols, additionalVals})

            console.log("updateRow", "affected entities collected in", performance.now() - startTime)

            if (affectedCols.length > 0) {

                filter0 = this.makeFilter([this.makeDatesFilter(affectedCols), filter0])

                startTime = performance.now()

                let [src] = await utils.query({
                    name: "gp-edit-rows",
                    vars: this.vars,
                    stream: table.stream,
                    report: table.report,
                    cores: table.cores,
                    dims: [],
                    vals: _.map(affectedVals, "calc"),
                    cols: _.map(affectedCols, "calc"),
                    filter0,
                    filter1,
                    filter2,
                }, undefined, true)

                console.log("gp-edit-rows", {src})

                if (src && this.updateReports[reportKey] === reportId) {
                    delete this.updateReports[reportKey]
                    this.modifyRow(row, row => {
                        for (let i=0; i<src.length; ++i)
                            Vue.set(row, cols.indexOf(affectedCols[i]) + table.dims.length, src[i])
                    })
                    // table.$forceUpdate()
                }

                console.log("updateRow", "affected entities computed in", performance.now() - startTime)
            }
        },
        async getDependencies() {
            let {data:{dataset:{reports:deps}}} = await fetch("/graphql", {
                method: "POST",
                body: JSON.stringify({query: "query{dataset{reports{name,deps}}}"})
            }).then(response => response.json())
            console.log({deps})
            return _(deps)
                .map(({name, deps}) => deps.map((dep) => [dep, name]))
                .flatten()
                .groupBy(([dep, name]) => dep)
                .toPairs()
                .map(([dep, pairs]) => [dep, pairs.map(([dep, name]) => name)])
                .fromPairs()
                .value()
        },
        async handleCellEdit({row, column, value, meta, shift=0}) {
            console.log("cellEdited", {row, column, value, shift})
            let startTime = performance.now()

            let makeFilter = (filters) =>
                _(filters)
                    .filter()
                    .map((filter) => `(${filter})`)
                    .join(" && ")

            let rowFilters1 = []
            let rowFilters2 = []
            let table = this.$refs.table

            if (_.isString(value)) {
                value = _.trim(value)
                switch (column.type) {
                    case "docid":
                    case "int8":
                    case "int16":
                    case "int32":
                    case "int64":
                        if (value === "")
                            value = 0
                        else
                            value = parseInt(value)
                        if (_.isNaN(value))
                            return
                        break
                    case "float":
                    case "double":
                        if (value === "")
                            value = 0
                        else
                            value = parseFloat(value.replace(',', '.'))
                        if (_.isNaN(value))
                            return
                        break
                    case "bool":
                        value = value.match(/^[yY1дД]/) !== null || value == utils.l10n("yes")
                        break
                    case "date":
                        value = value || null
                        break
                }
            }

            console.log({value})

            if (row) {
                _.forEach(table.dims, (dim, i) => {
                    let calc = _.isString(dim) ? dim : dim.calc
                    if (calc === "item" || calc === "class")
                        rowFilters1.push(`${calc} == ${utils.quote(row[i])}`)
                    else
                        rowFilters2.push(`${calc} == ${utils.quote(row[i])}`)
                })
                row = this.modifyRow(row, row => Vue.set(row, column.i + shift, value))
            }
            else {
                for (let row of table.rows)
                    this.modifyRow(row, row => Vue.set(row, column.i + shift, value))
                if (table.report.totals)
                    table.report.totals.rows[0][column.i - table.dims.length + shift] = value
            }

            table.reportId = null
            this.editableSeqN += 1
            let editableSeqN = this.editableSeqN

            let filter0 = undefined
            if (this.product != "pim")
                filter0 = this.makeDatesFilter()
            let filter1 = makeFilter([table.extraFilter1, table.filter1].concat(rowFilters1))
            let filter2 = makeFilter([table.extraFilter2, table.filter2].concat(rowFilters2))

            let reportKey = JSON.stringify({filter0, filter1, filter2})
            delete this.updateReports[reportKey]

            let keys = column.keys
            let extra = _.map(column.extra, (field) => this[field] ? this[field] : field)

            clearTimeout(this.requestDataTimeout)

            let dateFilter = undefined
            if (this.product != "pim")
                dateFilter = `date == \`${
                    utils.formatDate(
                        utils.nextDate(
                            utils.parseDate(this.referenceDate)))}\``

            let rows = await utils.query({
                name: "gp-edit-keys",
                vars: this.vars,
                stream: table.stream,
                report: table.report,
                cores: table.cores,
                dims: keys,
                filter0: makeFilter([dateFilter, filter0]),
                filter1: makeFilter([dateFilter, filter1]),
                filter2,
            })

            console.log("gp-edit-keys", {rows})

            let createUser = this.username
            let createTime = Date.now()
            if (this.product == "pim")
                createTime = new Date().toISOString().split(".")[0]
            let records = []
            for (let i=0; i<rows.length; ++i) {
                let row = rows[i]
                let record = []
                if (this.product == "pim") {
                    record.push("manual")
                    record.push(0)
                }
                for (let j=0; j<keys.length; ++j)
                    record.push(row[j])
                if (this.product == "pim") {
                    record.push(value)
                    record.push(createTime)
                    record.push(createUser)
                    record.push(createTime)
                    record.push(createUser)
                }
                else {
                    record.push(createUser)
                    record.push(createTime)
                    record.push(value)
                    if (extra)
                        record = record.concat(extra)
                }
                records.push(record)
            }
            records = JSON.stringify(records)

            let stream = column.stream
            let query = `
                mutation {
                    appendRecords(
                        stream: ${utils.quote(stream)},
                        format: "json",
                        records: ${utils.quote(records)})
                }`
            await fetch("/graphql", {
                method: "POST",
                body: JSON.stringify({query}),
                headers: {"Content-Type": "application/json"},
            })

            console.log("cellEdited", "processed in", performance.now() - startTime)
            utils.bridge.trigger("streamModified", column.stream)
            await this.updateRow(row, [column.stream])
        },
    }
}

module.exports.rawQuery = async (query, path="data", component, instant) => {
    let ajaxOpts = {
        url: "/graphql",
        method: "POST",
        data: JSON.stringify({query}),
        dataType: "json",
        contentType: "application/json",
    }
    return scheduleRequest(component, ajaxOpts, instant)
        .then(result => _.get(result, path))
}

module.exports.fetchRecords = async (stream, filter, columns) => {
    return (await module.exports.rawQuery(`
      query {
        dataset {
          streams {
            stream:${stream} {
              records(filter:${
                utils.quote(
                    _(filter)
                        .toPairs()
                        .map(([k, v]) => _.isArray(v)
                            ? `${k} in ${utils.quote(v)}`
                            : `${k} == ${utils.quote(v)}`)
                        .join(" && "))}) {
                rows(columns:${utils.quote(columns)})
              }
            }
          }
        }
      }`, "data.dataset.streams.stream.records.rows"))
}

module.exports.removeRecords = async (stream, filter) => {
    let ids = (await module.exports.fetchRecords(stream, filter, ["__id"])).map(row => row[0])
    return await module.exports.rawQuery(`
      mutation {
        removeRecords(
          stream: "${stream}"
          ids: ${utils.quote(ids)})
      }`, "data.removeRecords")
}

module.exports.appendRecords = async (stream, records) => {
    return await module.exports.rawQuery(`
      mutation {
        appendRecords(
          stream: "${stream}"
          format: "json"
          records: ${utils.quote(JSON.stringify(records))}
          )
      }`, "data.appendRecords")
}

module.exports.removeRecords = async (stream, filter) => {
    let ids = (await module.exports.fetchRecords(stream, filter, ["__id"])).map(row => row[0])
    return await module.exports.rawQuery(`
      mutation {
        removeRecords(
          stream: "${stream}"
          ids: ${utils.quote(ids)})
      }`, "data.removeRecords")
}

module.exports.replaceRecords = async (stream, filter, records) => {
    let ids = (await module.exports.fetchRecords(stream, filter, ["__id"])).map(row => row[0])
    return await module.exports.rawQuery(`
      mutation {
        removeRecords(
          stream: "${stream}"
          ids: ${utils.quote(ids)})
        appendRecords(
          stream: "${stream}"
          format: "json"
          records: ${utils.quote(JSON.stringify(records))}
          )
      }`, "data.appendRecords")
}
