<template>
<div class="gp-rules" ref="base">
    <gp-stored
        family="strategies"
        v-model="config"
        :username="username"
        :shareGroups="shareGroups"
        @saved="configChanged"
        @change="configChanged"/>

    <div class="form-group">
        <label><l10n value="Rules scope"/></label>

        <gp-filter
            v-model="editingFilter"
            :stream="stream"
            :source="source"
            :groups="groups"
            :filter0="extraFilter0"
            :filter1="extraFilter1"
            :filter2="extraFilter2"
            :threshold="threshold"
            :attributes="attributes"
            :formulas="formulas"
            :vars="vars"
            />
    </div>


    <!--gp-check
        v-model="hideRulesOutOfScope"
        >
        <l10n value="Hide rules out of scope"/>
    </gp-check-->

    <div class="gp-rules-dates">
        <l10n value="Start date" />
        :
        <inline-input type="date" v-model="config.startDate" />
        <l10n value="End date" />
        :
        <inline-input type="date" v-model="config.endDate" />
    </div>

    <div class="gp-rules-hint"><l10n value="Use drag & drop to reorder sections and rules"/></div>
    <div class="gp-rules-weight"><l10n value="Rule weight"/><inline-help text="Optimization minimizes cummulative rules violation measured in price delta beyond boundaries defined by rules. Rule weight allows to control importance of every rule or an entire section of rules."/></div>
    <div class="gp-rules-sections" ref="sections">
        <div
            v-for="section in editingSections"
            :key="section.id"
            :data-section="section.id"
            class="gp-rules-section"
            :class="{expanded:expandedSections[section.id]}"
            >
            <label>
                <a href="javascript:void(0)" @click="selectedSection = section">
                    <feather-icon name="more-vertical"/>
                </a>
                <a href="javascript:void(0)" @click="promptSectionWeight(section)" class="gp-rules-weight">
                    {{formatPercent(section.weight !== undefined ? section.weight * 100 : 100)}}
                </a>
                <div class="gp-section-opt-types-icons" v-if="types.length > 1">
                    <span
                        v-for="type in types" 
                        :class="{active:section.types.includes(type.code)}"><l10n :value="type.icon"/></span>
                </div>
                <a href="javascript:void(0)" @click="$set(expandedSections, section.id, !expandedSections[section.id])">
                    <feather-icon :name="expandedSections[section.id] ? 'chevrons-down': 'chevrons-right'"/>
                    {{sectionName(section)}}
                </a>
                –
                <l10n value="{rules} price rules" :rules="new Number(section.rules.length).toLocaleString()"/>
            </label>
            <a
                href="javascript:void(0)"
                v-if="section.rules.length"
                @click="
                    isAllRulesExpanded(section) ?
                        collapseAllRules(section) :
                        expandAllRules(section)">
                <l10n :value="isAllRulesExpanded(section) ? 'collapse all' : 'expand all'"/>
            </a>
            <div class="gp-section-opt-types-checks" v-if="types.length > 1 && expandedSections[section.id]">
                <gp-check
                    v-for="type in types"
                    :key="type.code"
                    :checked="section.types.includes(type.code)"
                    @click="toogleSectionType(section, type.code)">
                    <l10n :value="type.name"/>
                </gp-check>
            </div>
            <ul class="gp-rules-list" ref="rules" v-if="expandedSections[section.id]">
                <li
                    v-for="rule, n in section.rules"
                    v-if="isRuleVisible(rule)"
                    :key="rule.id"
                    :data-section="section.id"
                    :data-rule="rule.id"
                    :class="{expanded:expandedRules[rule.id]}">
                    <label>
                        <a href="javascript:void(0)" @click="selectedRule = rule">
                            <feather-icon name="more-vertical"/>
                        </a>
                        <a
                            href="javascript:void(0)"
                            @click="promptRuleWeight(rule)"
                            class="gp-rules-weight"
                            v-if="
                                rule.type !== 'rounding' &&
                                rule.type !== 'allowable_percent' &&
                                rule.type !== 'price_change_cnt_limits' &&
                                rule.type !== 'weak_optimization' &&
                                rule.type !== 'optimization_effect_limits'
                            ">
                            {{formatPercent(rule.weight !== undefined ? rule.weight * 100 : 100)}}
                        </a>
                        <a href="javascript:void(0)" @click="$set(expandedRules, rule.id, !expandedRules[rule.id])">
                            <feather-icon :name="expandedRules[rule.id] ? 'chevron-down': 'chevron-right'"/>
                            #{{ruleNumbers[rule.id]}}
                            <l10n :value="rule.name"/>
                        </a>
                    </label>

                    <gp-check
                        class="float-right"
                        v-if="expandedRules[rule.id] && strictRules.includes(rule.type)"
                        v-model="rule.strict"
                        >
                        <l10n value="strict rule"/>
                    </gp-check>

                    <p class="gp-rule-filter"
                        v-if="
                            expandedRules[rule.id] &&
                            rule.type !== 'price_change_cnt_limits' &&
                            rule.type !== 'optimization_effect_limits'
                        ">
                        <gp-filter
                            v-model="rule.filter"
                            :stream="stream"
                            :source="source"
                            :groups="groups"
                            :filter0="extraFilter0"
                            :filter1="extraFilter1"
                            :filter2="extraFilter2"
                            :threshold="threshold"
                            :attributes="attributes"
                            :formulas="formulas"
                            :vars="vars"
                            />
                        <gp-filter
                            v-model="rule.filter_not"
                            :stream="stream"
                            :source="source"
                            :groups="groups"
                            :filter0="extraFilter0"
                            :filter1="extraFilter1"
                            :filter2="extraFilter2"
                            :threshold="threshold"
                            :attributes="attributes"
                            :formulas="formulas"
                            :vars="vars"
                            />
                    </p>

                    <p v-if="expandedRules[rule.id]">
                        <l10n :value="rule.text">
                            <span v-for="part in parseRule(rule)">
                                <template v-if="part.tag == 'span'">{{part.text}}</template>
                                <template v-if="part.tag == 'input'">
                                    <template v-if="activeControl == rule.id+part.prop">
                                        <my-popup portal="popup">
                                            <template v-slot:anchor>
                                            <a href="javascript:void(0)">{{part.format(part.value)}}</a>
                                            </template>
                                            <div class="gp-rule-popup">
                                                <gp-endings
                                                    v-if="part.prop.endsWith('wholeEndings')"
                                                    ref="prop"
                                                    :value="part.value"
                                                    suffix="."
                                                    @change="newPropValue = $event"
                                                />
                                                <gp-endings
                                                    v-else-if="part.prop.endsWith('fractionalEndings')"
                                                    ref="prop"
                                                    :value="part.value"
                                                    prefix="."
                                                    @change="newPropValue = $event"
                                                />
                                                <select
                                                    v-else-if="rule.options && rule.options[part.prop]"
                                                    ref="prop"
                                                    :value="part.value"
                                                    autofocus
                                                    :multiple="rule.multiple"
                                                    class="form-control form-control-sm"
                                                    @change="newPropValue = rule.multiple ? Array.from($event.target.options).filter(x => x.selected).map(x => x.value) : $event.target.value"
                                                    @keydown="
                                                        if ($event.key == 'Escape') {
                                                            activeControl = null
                                                        }
                                                        if ($event.key == 'Enter') {
                                                            newPropValue = rule.multiple ? Array.from($event.target.options).filter(x => x.selected).map(x => x.value) : $event.target.value
                                                            changeProp(part)
                                                            activeControl = null
                                                        }"
                                                    >
                                                    <template v-if="rule.options[part.prop].length">
                                                        <option v-for="key in rule.options[part.prop]" :value="key">{{key}}</option>
                                                    </template>
                                                    <template v-else>
                                                        <option v-for="(value, key) in rule.options[part.prop]" :value="key">{{key}}</option>
                                                    </template>
                                                </select>
                                                <div v-else-if="part.type === 'dates'">
                                                    <my-date-picker
                                                        ref="prop"
                                                        :value="rule.md_dates"
                                                        @input="
                                                            newPropValue = $event
                                                            changeProp(part)
                                                            "
                                                        />
                                                    <div style="margin-top: 10px">
                                                        <button
                                                            class="btn btn-danger btn-xs"
                                                            style="width: 100%"
                                                            @click="
                                                                rule.md_dates = []
                                                                rule.md_force_percents = {}
                                                                newPropValue = []
                                                                changeProp(part)
                                                                activeControl = null
                                                                $event.stopPropagation()
                                                            ">
                                                            <l10n value="Reset dates"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-else-if="part.type === 'value_by_date'">
                                                    <v-date-picker 
                                                        :available-dates="rule.md_dates"
                                                        :value="currentMdDate"
                                                        :attributes="[
                                                            {
                                                                highlight: {
                                                                    fillMode: 'outline',
                                                                    color: 'blue'
                                                                },
                                                                dates: Object.keys(rule.md_force_percents).filter(x => rule.md_force_percents[x].type === 'eq')
                                                            }, {
                                                                highlight: {
                                                                    fillMode: 'outline',
                                                                    color: 'red'
                                                                },
                                                                dates: Object.keys(rule.md_force_percents).filter(x => rule.md_force_percents[x].type === 'le')
                                                            }]"
                                                        @dayclick="
                                                            if (rule.md_dates.map(x => formatDate(x)).includes($event.id)) {
                                                                currentMdDate = $event.id
                                                                rule.md_force_percents[currentMdDate] = rule.md_force_percents[currentMdDate] || {type: 'eq', value: 0}
                                                            }
                                                            "
                                                        />
                                                    <my-popup 
                                                        v-if="currentMdDate"
                                                        portal=""
                                                        @clickoutside="currentMdDate = undefined"
                                                        >
                                                        <div class="gp-rule-popup gp-table-dates">
                                                            <span>
                                                                <l10n value="Discount"/>
                                                                <select
                                                                    :style="`min-width: auto; color: ${rule.md_force_percents[currentMdDate].type == 'eq' ? 'var(--cyan)' : 'var(--red)'}`"
                                                                    :value="rule.md_force_percents[currentMdDate].type"
                                                                    @change="
                                                                        rule.md_force_percents[currentMdDate].type = $event.target.value
                                                                        newPropValue = {...rule.md_force_percents}
                                                                        changeProp(part)
                                                                        "
                                                                    >
                                                                    <option style="color: var(--cyan)" value="eq">equals</option>
                                                                    <option style="color: var(--red)" value="le">deep or equal</option>
                                                                </select>
                                                                <span class="gp-date">
                                                                    <a>
                                                                        <span class="btn-link">{{rule.md_force_percents[currentMdDate].value}}</span>%
                                                                        <input 
                                                                            type="number"
                                                                            style="min-width: auto; width: 40px;"
                                                                            @click="
                                                                                $event.target.parentElement.parentElement.classList.add('focused')
                                                                                "
                                                                            @keydown="
                                                                                if ($event.key == 'Escape') {
                                                                                    $event.target.parentElement.parentElement.classList.remove('focused')
                                                                                }
                                                                                if ($event.key == 'Enter') {
                                                                                    rule.md_force_percents[currentMdDate].value = parseFloat($event.target.value)
                                                                                    newPropValue = {...rule.md_force_percents}
                                                                                    changeProp(part)
                                                                                    $event.target.parentElement.parentElement.classList.remove('focused')
                                                                                }"
                                                                            />
                                                                    </a>
                                                                </span>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    style="margin-left: 15px"
                                                                    @click="
                                                                        delete rule.md_force_percents[currentMdDate]
                                                                        currentMdDate = undefined
                                                                        ">
                                                                    <feather-icon name="trash-2" color="var(--red)" />
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </my-popup>
                                                    <div style="margin-top: 10px">
                                                        <button
                                                            class="btn btn-danger btn-xs"
                                                            style="width: 100%"
                                                            @click="
                                                                rule.md_force_percents = {}
                                                                newPropValue = {}
                                                                changeProp(part)
                                                                $event.stopPropagation()
                                                            ">
                                                            <l10n value="Reset force percents"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <input
                                                    v-else
                                                    ref="prop"
                                                    :type="part.type"
                                                    :value="part.value !== -10000000 && part.value !== 10000000 ? part.value : ''"
                                                    autofocus
                                                    class="form-control form-control-sm"
                                                    @change="newPropValue = $event.target.value"
                                                    @keydown="
                                                        if ($event.key == 'Escape') {
                                                            activeControl = null
                                                        }
                                                        if ($event.key == 'Enter') {
                                                            newPropValue = $event.target.value
                                                            changeProp(part)
                                                            activeControl = null
                                                        }"
                                                    />
                                                <div class="gp-rule-popup-actions">
                                                    <button
                                                        class="btn btn-primary btn-xs"
                                                        @click="
                                                            changeProp(part)
                                                            activeControl = null
                                                            $event.stopPropagation()
                                                        ">
                                                        <l10n value="OK"/>
                                                    </button>
                                                    <button
                                                        class="btn btn-secondary btn-xs"
                                                        @click="
                                                            activeControl = null
                                                            $event.stopPropagation()">
                                                        <l10n value="Cancel"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </my-popup>
                                    </template>
                                    <a v-else
                                        href="javascript:void(0)"
                                        @click="activeControl = rule.id+part.prop">
                                        {{part.format(part.value)}}</a>
                                </template>
                            </span>
                        </l10n>
                    </p>
                </li>
            </ul>

            <div v-if="expandedSections[section.id]" class="gp-rules-add-rule">
                <my-popup
                    v-if="showRulesLibrary === section.id"
                    @escape="showRulesLibrary = null"
                    @clickoutside="showRulesLibrary = null"
                    portal="popup"
                    placement="bottom-start"
                    >
                    <template v-slot:anchor>
                        <a
                            href="javascript:void(0)"
                            @click="showRulesLibrary = null"
                            style="display: block;">
                            <feather-icon name="plus"/>
                            <l10n value="Add price rule"/>
                        </a>
                    </template>
                    <ul class="gp-rules-library">
                        <li v-for="rule in rulesLibrary" v-if="rule.show !== false">
                            <a href="javascript:void(0)" @click="addRule(section, rule)">
                                {{rule.name}}
                            </a>
                        </li>
                    </ul>
                </my-popup>
                <a  v-else
                    href="javascript:void(0)"
                    @click="showRulesLibrary = section.id">
                    <feather-icon name="plus"/>
                    <l10n value="Add price rule"/>
                </a>
            </div>
        </div>
        <a
            href="javascript:void(0)"
            @click="addSection">
            <feather-icon name="plus"/>
            <l10n value="Add price rules section"/>
        </a>
        <a
            href="javascript:void(0)"
            @click="exportToExcel">
            <feather-icon name="download"/>
            <l10n value="Export rules to Excel"/>
        </a>
        <a
            href="javascript:void(0)"
            @click="importFromExcel">
            <feather-icon name="upload"/>
            <l10n value="Import rules from Excel"/>
        </a>
    </div>
    <p>
        <div class="form-group">
            <label><l10n value="Optimization run name"/></label>
            <input class="form-control" v-model="runName" :placeholder="config && config.name"/>
        </div>
        <div class="form-group">
            <gp-check v-model="temporaryRun"><l10n value="Don't save optimization results"/></gp-check>
        </div>
        <div class="form-group optimization-types" v-if="types.length > 1">
            <label><l10n value="Optimization type"/></label>
            <div>
                <gp-check
                    v-for="type in types"
                    :key="type.code"
                    :type="multipleTypes ? 'checkbox' : 'radio'"
                    :checked="optimizationTypes.includes(type.code)"
                    @click="optimizationTypes = multipleTypes ?
                        (optimizationTypes.includes(type.code) ?
                            optimizationTypes.filter(code => code != type.code) :
                            optimizationTypes.concat(type.code)) :
                        [type.code]"
                    name="optimization-type">
                    <l10n :value="type.name"/>
                </gp-check>
            </div>
        </div>
        <button
            class="btn btm-sm btn-secondary"
            @click="optimize"
            :disabled="optimizing"
            >
            <l10n value="Optimize"/>
        </button>
    </p>

    <div v-if="optimizing">
        <l10n :value="optimizationStatus"/>
        <div class="progress">
            <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                :style="{width: `${optimizationProgress * 100}%`}">
            </div>
        </div>
    </div>

    <div v-if="stats" class="gp-rules-stats">
        <div>
            <l10n
                value="{count} price changes"
                :count="new Number(stats.changes).toLocaleString()"/>
        </div>

        <div v-if="stats.increased">
            <l10n
                value="{count} price increased on {avg} in average"
                :count="new Number(stats.increased).toLocaleString()"
                :avg="formatPercent(stats.avg_increase * 100)"
                />
        </div>

        <div v-if="stats.decreased">
            <l10n
                value="{count} price decreased on {avg} in average"
                :count="new Number(stats.decreased).toLocaleString()"
                :avg="formatPercent(stats.avg_decrease * -100)"
                />
        </div>

        <div v-if="stats.dropped_impact > 0">
            <l10n
                value="{count} price changes are dropped due to metric impact limit"
                :count="new Number(stats.dropped_impact).toLocaleString()"/>
        </div>

        <div v-if="stats.dropped_limits > 0">
            <l10n
                value="{count} price changes are dropped due to price change limit"
                :count="new Number(stats.dropped_limits).toLocaleString()"/>
        </div>
        <div v-if="stats.dropped_markdown_budget > 0">
            <l10n
                value="{count} price changes are dropped due to markdown budget limit"
                :count="new Number(stats.dropped_markdown_budget).toLocaleString()"/>
        </div>

        <div v-if="stats.errors > 0">
            <l10n
                value="{errors} optimization tasks failed"
                :errors="new Number(stats.errors).toLocaleString()"/>
        </div>
    </div>

    <!--div class="form-group">
        <gp-check v-model="config.scheduled">
            <l10n value="Put this strategy on schedule"/>
        </gp-check>
    </div-->

    <template v-if="config.schedule">
        <gp-data
            id="gp-rules-categories"
            :stream="stream"
            :source="source"
            :groups="['gp-rules-categories', 'reference-date']"
            :filter0="filter0"
            :filter1="filter1"
            :filter2="filter2"
            filter3="dim1 != ''"
            :dims="['classif.category']"
            :initialSort="[1]"
            :instant="false"
            v-model="categoriesReport"
            />
        <ul class="gp-runs-schedule">
            <li
                v-for="scheduledRun, scheduledRunId in config.schedule"
                :key="scheduledRunId"
                :class="{expanded:expandedScheduledRuns[scheduledRunId]}"
                >
                <a href="javascript:void(0)"
                    @click="expandedScheduledRuns[scheduledRunId] ?
                        $delete(expandedScheduledRuns, scheduledRunId) :
                        $set(expandedScheduledRuns, scheduledRunId, true)">
                    <feather-icon :name="expandedScheduledRuns[scheduledRunId] ? 'chevron-down' : 'chevron-right'"/>
                    <l10n
                        value="Run optimization on {days} at {time} for {categories}."
                        :days="
                            scheduledRun.days && scheduledRun.days.length && scheduledRun.days.map(day => parseDate(day).toLocaleDateString()).join(', ') ||
                            scheduledRun.dows && scheduledRun.dows.length && scheduledRun.dows.map(l10n).join(', ') ||
                            l10n('[no days selected]')"
                        :time="scheduledRun.time || l10n('[no time selected]')"
                        :categories="(scheduledRun.categories || []).map(category => category.name).join(', ') || l10n('[no categories selected]')"
                        />
                    <l10n
                        v-if="scheduledRun.combineCategories"
                        value="Optimize all categories at once."
                        />
                    <l10n
                        v-if="scheduledRun.autoAccept"
                        value="Automatically accept recommendations if price change is within {left} to {right} range."
                        :left="scheduledRun.autoAcceptBoundsLeft ? scheduledRun.autoAcceptBoundsLeft + '%' : l10n('[no min limit selected]')"
                        :right="scheduledRun.autoAcceptBoundsRight ? scheduledRun.autoAcceptBoundsRight + '%' : l10n('[no max limit selected]')"
                        />
                    <l10n
                        v-if="scheduledRun.autoAcceptExcludeStrictViolations"
                        value="Do not accept if strict rules are violated"/>
                </a>
                <template v-if="expandedScheduledRuns[scheduledRunId]">
                    <div class="form-group">
                        <label><l10n value="Run at:"/></label>
                        <input
                            type="time"
                            class="form-control form-control-sm"
                            v-model="scheduledRun.time"/>
                    </div>
                    <div class="form-group">
                        <label><l10n value="Run on:"/></label>
                        <div class="btn-group-toggle" v-if="scheduledRun.days.length == 0">
                            <label
                                v-for="dow in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                                :class="{
                                    'btn btn-sm btn-secondary': true,
                                    active: scheduledRun.dows.indexOf(dow) != -1
                                }">
                                <input
                                    type="checkbox"
                                    :checked="scheduledRun.dows.indexOf(dow) != -1"
                                    @change="
                                        $event.target.checked ?
                                        scheduledRun.dows.push(dow) :
                                        scheduledRun.dows.splice(scheduledRun.dows.indexOf(dow), 1)"
                                    >
                                <l10n :value="dow"/>
                            </label>
                        </div>
                        <v-calendar
                            v-if="scheduledRun.dows.length == 0"
                            :locale="locale"
                            :is-dark="darkTheme"
                            is-expanded
                            trim-weeks
                            title-position="left"
                            :attributes="
                                scheduledRun.days.map(day => ({ key: day, highlight: true, dates: parseDate(day) }))"
                            @dayclick="
                                scheduledRun.days.indexOf($event.id) >= 0 ?
                                scheduledRun.days.splice(scheduledRun.days.indexOf($event.id), 1) :
                                scheduledRun.days.push($event.id)
                                "
                            />
                    </div>
                    <div class="form-group">
                        <label><l10n value="Run for:"/></label>
                        <gp-pills
                            v-model="scheduledRun.categories"
                            :options="knownCategories"
                            :placeholder="l10n('Categories')"
                            recentOptionsKey="recentCategories"
                            />
                        <gp-filter
                            v-model="scheduledRun.filter"
                            :stream="stream"
                            :source="source"
                            :attributes="attributes"
                            :formulas="formulas"
                            :groups="['gp-rules-filter', 'reference-date']"
                            :vars="vars"
                            />
                    </div>
                    <div class="form-group">
                        <gp-check v-model="scheduledRun.combineCategories">
                            <l10n value="Optimize all categories at once"/>
                        </gp-check>
                        <gp-check v-model="scheduledRun.autoAccept">
                            <l10n value="Automatically accept recommendations"/>
                        </gp-check>
                        <div v-if="scheduledRun.autoAccept" class="gp-rules-auto-accept-bounds">
                            <l10n value="if price change is within"/>
                            <input
                                class="form-control form-control-sm"
                                v-model="scheduledRun.autoAcceptBoundsLeft"
                                type="number" step="0.1"
                                />
                            <span>%</span>
                            <input
                                class="form-control form-control-sm"
                                v-model="scheduledRun.autoAcceptBoundsRight"
                                type="number" step="0.1"
                                />
                            <span>%</span>
                        </div>
                        <!--div v-if="scheduledRun.autoAccept" class="gp-rules-auto-accept-strict">
                            <gp-check v-model="scheduledRun.autoAcceptExcludeStrictViolations">
                                <l10n value="do not accept if strict rules are violated"/>
                            </gp-check>
                        </div-->
                    </div>
                    <div class="form-group">
                        <a href="javascript:void(0)" @click="$delete(config.schedule, scheduledRunId)">
                            <l10n value="Delete this scheduled optimization run"/>
                        </a>
                    </div>
                </template>
            </li>
        </ul>
    </template>

    <div class="form-group">
        <a href="javascript:void(0)" @click="addScheduledRun">
            <feather-icon name="calendar"/>
            <l10n value="Schedule optimization run"/>
        </a>
    </div>

    <!--template v-if="config.scheduled">
        <div class="form-group">
            <label><l10n value="Run at:"/></label>
            <input
                type="time"
                class="form-control form-control-sm"
                v-model="config.scheduleTime"/>
        </div>
        <div class="form-group">
            <label><l10n value="Run on:"/></label>
            <div class="btn-group-toggle">
                <label
                    v-for="dow in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                    :class="{
                        'btn btn-sm btn-secondary': true,
                        active: config[`schedule${dow}`]
                    }">
                    <input
                        type="checkbox"
                        :checked="config[`schedule${dow}`]"
                        @change="$set(config, `schedule${dow}`, $event.target.checked)"
                        >
                    <l10n :value="dow"/>
                </label>
            </div>
        </div>
        <gp-data
            id="gp-rules-categories"
            :stream="stream"
            :source="source"
            :groups="['gp-rules-categories', 'reference-date']"
            :filter0="filter0"
            :filter1="filter1"
            :filter2="filter2"
            filter3="dim1 != ''"
            :dims="['classif.category']"
            :initialSort="[1]"
            :instant="false"
            v-model="categoriesReport"
            />
        <div class="form-group">
            <label><l10n value="Run for:"/></label>
            <gp-pills
                v-model="config.scheduleCategories"
                :options="knownCategories"
                :placeholder="l10n('Categories')"
                recentOptionsKey="recentCategories"
                />
            <gp-filter
                v-model="config.scheduleFilter"
                :stream="stream"
                :source="source"
                :attributes="attributes"
                :formulas="formulas"
                :groups="['gp-rules-filter', 'reference-date']"
                :vars="vars"
                />
        </div>
        <div class="form-group">
            <gp-check v-model="config.scheduleCombineCategories">
                <l10n value="Optimize all categories at once"/>
            </gp-check>
            <gp-check v-model="config.scheduleAutoAccept">
                <l10n value="Automatically accept recommendations"/>
            </gp-check>
            <div v-if="config.scheduleAutoAccept" class="gp-rules-auto-accept-bounds">
                <l10n value="if price change is within"/>
                <input
                    class="form-control form-control-sm"
                    v-model="config.scheduleAutoAcceptBoundsLeft"
                    type="number" step="0.1"
                    />
                <span>%</span>
                <input
                    class="form-control form-control-sm"
                    v-model="config.scheduleAutoAcceptBoundsRight"
                    type="number" step="0.1"
                    />
                <span>%</span>
            </div>
            <div v-if="config.scheduleAutoAccept" class="gp-rules-auto-accept-strict">
                <gp-check v-model="config.scheduleAutoAcceptExcludeStrictViolations">
                    <l10n value="do not accept if strict rules are violated"/>
                </gp-check>
            </div>
        </div>
    </template-->

    <div class="gp-rules-configuration">
        <p>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="show-rules-configYaml"
                    :checked="showConfig"
                    @click="toogleConfig"
                    />
                <label class="form-check-label" for="show-rules-configYaml">
                    <l10n value="Show configuration"/>
                </label>
            </div>
        </p>
        <ace-editor
            v-if="showConfig"
            v-model="configYaml"
            mode="yaml"
            :auto-height="true"
            />
        <button
            v-if="showConfig"
            class="btn btn-sm btn-secondary"
            @click="applyConfig">
            <l10n value="Submit changes"/>
        </button>
    </div>

    <my-popup
        portal="popup"
        v-if="selectedSection"
        @escape="selectedSection = null"
        @clickoutside="selectedSection = null"
        placement="bottom-end"
        :anchor="`*[data-section='${selectedSection.id}'] .feather-icon-more-vertical`">
        <div class="popover show plain-table-manage-table">
            <div class="popover-body">
                <ul>
                    <li>
                        <a 
                            href="javascript:void(0)"
                            @click="
                                promptSectionName(selectedSection)
                                selectedSection = null">
                            <feather-icon name="edit-3"/>
                            <l10n value="Rename section"/>
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:void(0)"
                            @click="
                                cloneSection(selectedSection)
                                selectedSection = null">
                            <feather-icon name="copy"/>
                            <l10n value="Duplicate section"/>
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:void(0)"
                            @click="
                                deleteSection(selectedSection)
                                selectedSection = null">
                            <feather-icon name="trash"/>
                            <l10n value="Delete section"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </my-popup>

    <my-popup
        portal="popup"
        v-if="selectedRule"
        @escape="selectedRule = null"
        @clickoutside="selectedRule = null"
        placement="bottom-end"
        :anchor="`*[data-rule='${selectedRule.id}'] .feather-icon-more-vertical`">
        <div class="popover show plain-table-manage-table">
            <div class="popover-body">
                <ul>
                    <li>
                        <a
                            href="javascript:void(0)"
                            @click="
                                promptRuleName(selectedRule)
                                selectedRule = null">
                            <feather-icon name="edit-3"/>
                            <l10n value="Rename price rule"/>
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:void(0)"
                            @click="
                                cloneRule(selectedRule)
                                selectedRule = null">
                            <feather-icon name="copy"/>
                            <l10n value="Duplicate price rule"/>
                        </a>
                    </li>
                    <li>
                        <a
                            href="javascript:void(0)"
                            @click="
                                deleteRule(selectedRule)
                                selectedRule = null">
                            <feather-icon name="trash"/>
                            <l10n value="Delete price rule"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </my-popup>

    <!--gp-data
        ref="data"
        id="gp-rules"
        :stream="stream"
        :source="source"
        :groups="groups"
        :dims="[]"
        :vals="vals"
        :filter2="makeScopeFilter(currentFilter)"
        @report="updateCurrentRulesInScope"
        /-->
</div>
</template>
<script>
let utils = require("../my-utils")
let FuzzySearch = require("fuzzy-search").default
let levenshtein = require('js-levenshtein')

let reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
let reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
let dateParser = (key, value) => {
    // first, just make sure the property is a string:
    if (typeof value === 'string') {
        // then, use regex to see if it's an ISO-formatted string
        let a = reISO.exec(value);
        if (a) {
            // if so, Date() can parse it:
            return new Date(value);
        }
        // otherwise, see if it's a wacky Microsoft-format string:
        a = reMsAjax.exec(value);
        if (a) {
            // and perform some jujitsu to make use of it:
            let b = a[1].split(/[-+,.]/);
            return new Date(b[0] ? +b[0] : 0 - +b[1]);
        }
        // here, you could insert any additional tests and parse instructions you like, for other date syntaxes...
    }
    // important: you need to return any values you're not parsing, or they die...
    return value;
};
let dateReplacer = (key, value) => {
    if (key === "md_dates") {
        return value.map(x => utils.formatDate(x))
    }
    return value;
};

let defaultStrategyDates = () => {
    let startDate = new Date()
    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 30)
    return [utils.formatDate(startDate), utils.formatDate(endDate)]
}

module.exports = {
    mixins: [
        utils.extraFilters,
        utils.configHelpers,
        utils.referenceDateHelper
    ],
    props: {
        username:       { type: String, default: null },
        darkTheme:      { type: Boolean },
        types:          { type: Array, default: () => [{
            code: 'rbp',
            name: 'Base price optimization',
            icon: 'BP',
        }, {
            code: 'markdown',
            name: 'Markdown optimization',
            icon: 'MD',
        }]},
        stream:         { type: String, default: "default" },
        source:         { type: Object },
        groups:         { type: Array  },
        filter0:        { type: String },
        filter1:        { type: String },
        filter2:        { type: String },
        threshold:      { type: Number, default: 100 },
        rulesLibrary:   { type: Array,  default: () => [] },
        strategy:       { type: String, default: "fixed" },
        placement:      { type: [String, Array], default: "bottom" },
        competitors:    { type: Array,  default: () => [] },
        optimization:   { type: Object, default: () => ({}) },
        strictRules:    { type: Array, default: () => ["pct_change", "cpi_change"] },
        shareGroups:    { type: Array, default: () => [] },
        defaultTypes:   { type: Array, default: () => ["rbp"] },
        multipleTypes:  { type: Boolean, default: false },
        defaultScheduledRun: { type: Object, default: () => ({ days: [], dows: [], time: null, categories: [] }) },
        settings:       { type: Object, default: () => ({}) },
        categories:     { type: Object },
        threadsCount:   { type: Number, default: () => 1000 },
    },
    data() {
        let config = this.loadFromLocalStorage("currentRulesConfig")

        if (!config) {
            config = { sections: [], filter: [] }
            let [defaultStrategyStartDate, defaultStrategyEndDate] = defaultStrategyDates()
            config.startDate = config.startDate || defaultStrategyStartDate
            config.endDate = config.endDate || defaultStrategyEndDate
        }

        let currentSections = []
        let editingSections = []

        if (config.sections) {
            currentSections = _.cloneDeep(config.sections)
            editingSections = _.cloneDeep(config.sections)
        }
        else if (config.rules) {
            let id = utils.randomId()
            currentSections = [{id, rules: _.cloneDeep(config.rules)}]
            editingSections = [{id, rules: _.cloneDeep(config.rules)}]
        }

        let currentFilter = _.cloneDeep(config.filter) || []
        let editingFilter = _.cloneDeep(config.filter) || []

        let possibleValues = {
            competitors: Promise.resolve(this.competitors)
        }
        return {
            l10n: utils.l10n,
            config,
            used: [],
            patterns: [],
            optimalPrices: null,
            activeControl: null, 
            stats: null,
            possibleValues,
            autocomplete: null,
            currentFilter,
            editingFilter,
            editingSections,
            currentSections,
            showRulesLibrary: null,
            // hideRulesOutOfScope: true,
            // currentRulesInScope: null,
            showConfig: false,
            configYaml: null,
            expandedSections: {},
            expandedRules: {},
            expandedScheduledRuns: {},
            newPropValue: null,
            optimizationId: null,
            optimizationStatus: null,
            optimizationProgress: 0,
            runName: "",
            categoriesReport: null,
            selectedSection: null,
            selectedRule: null,
            optimizationTypes: _.clone(this.defaultTypes),
            temporaryRun: false,
            currentMdDate: null,
            vars: {},
        }
    },
    beforeMount() {
        this.actualizeSections()
    },
    mounted() {
        utils.bridge.bind("ws-message", this.handleWsMessage);
        utils.bridge.bind('varsChanged', this.handleVarsChanged);
        utils.bridge.trigger('getVars');
        $(this.$refs.sections)
            .sortable({
                axis: "y",
                handle: "> label",
                cursor: "move",
                helper: "original",
                distance: 5,
                forceHelperSize: true,
                forcePlaceholderSize: true,
                start: (e, ui) => {
                    ui.item.data("n", -1)
                    let h = ui.item.height();
                    console.log({h})
                    ui.placeholder.height(h)
                },
                change: (e, ui) => {
                    let x = ui.placeholder[0]
                    let n = Array.prototype.indexOf.call(x.parentNode.childNodes, x)
                    ui.item.data("n", n)
                },
                beforeStop: (e, ui) => {
                    this.preventEditing = true
                    _.defer(() => this.preventEditing = false)
                    ui.item.parent().sortable("cancel")

                    let section = ui.item.data("section")
                    let n = ui.item.data("n")
                    if (n != -1) {
                        let sections = this.editingSections
                        let i = sections.findIndex(({id}) => id === section)
                        console.log({i,n})
                        section = sections.splice(i, 1)[0]
                        sections.splice(n > i ? n-1 : n, 0, section)
                    }
                }
            })
        this.setupRulesSortable()
    },
    beforeDestroy() {
        utils.bridge.unbind("ws-message", this.handleWsMessage)
        $(this.$refs.sections).sortable("destroy")
        $(this.$refs.rules).sortable("destroy")
        if (this.autocomplete)
            this.autocomplete.autocomplete("destroy")
    },
    computed: {
        knownCategories() {
            return _(this.categoriesReport?.rows)
                .groupBy("0")
                .toPairs()
                .map(([name, rows]) => ({name, classes: _.map(rows, "1")}))
                .value()
        },
        optimizing() {
            return this.optimizationId != null
        },
        ruleNumbers() {
            return _(this.editingSections)
                .map(({rules}) => _.map(rules, ({id}, n) => [id,n+1]))
                .flatten()
                .fromPairs()
                .value()
        },
        /*scopeFilter2() {
            return this.joinFilters([
                this.extraFilter2,
                this.makeProbeFilter(this.filter)])
        },*/
        somethingEdited() {
            return (
                !_.isEqual(this.currentSections, this.editingSections) ||
                !_.isEqual(this.currentFilter, this.editingFilter))
        },
        /*vals() {
            return _(this.currentSections)
                .map("rules")
                .flatten()
                .map(({id, filter}) => ({
                    name: id,
                    calc: this.makeProbeFilter(filter)
                }))
                .value()
        },*/
    },
    methods: {
        formatDate: utils.formatDate,
        parseDate: utils.parseDate,

        async exportToExcel() {

            let formatFilter = filter =>
                _(filter)
                    .filter(part => !_.isEmpty(part))
                    .map(part => _(part)
                        .toPairs()
                        .map(([calc, vals]) =>
                            `${this.attributesByCalc[calc]?.name || calc}: ${utils.quote(vals.length == 1 ? vals[0] : vals)}`)
                        .join(", "))
                    .join("; ")

            let formatParameters = rule =>
                [...rule.text
                    .matchAll(/{([^}\.\[]+)[^}]*?}/g)]
                    .map(match => match[1])
                    .map(param => `${param}: ${utils.quote(rule[param])}`)
                    .join(", ")

            let XLSX = await import("xlsx")
            let workbook = XLSX.utils.book_new()

            for (let section of this.editingSections) {
                let rows = []

                rows.push(["Section weight:", section.weight || 1])
                rows.push(["Section types:", section.types?.join(", ")])
                rows.push([])

                rows.push(["Rules"])
                rows.push([
                    "Type",
                    "Name",
                    "Parameters",
                    "Include",
                    "Exclude",
                    "Strict",
                    "Weight",
                    "Text",
                ])

                for (let rule of section.rules) {
                    rows.push([
                        rule.type,
                        rule.name,
                        formatParameters(rule),
                        formatFilter(rule.filter),
                        formatFilter(rule.filter_not),
                        rule.strict || false,
                        rule.weight || 1,
                        rule.text,
                    ])
                }
                
                let worksheet = XLSX.utils.aoa_to_sheet(rows)

                XLSX.utils.book_append_sheet(
                    workbook,
                    worksheet,
                    section.name)
            }

            XLSX.writeFile(workbook, `${this.config?.name || "strategy"}.xlsx`)
        },

        importFromExcel() {},

        addScheduledRun() {
            if (!this.config.schedule)
                this.$set(this.config, "schedule", {})
            let scheduledRunId = utils.randomId()
            this.$set(this.config.schedule, scheduledRunId, _.cloneDeep(this.defaultScheduledRun) || {})
            this.$set(this.expandedScheduledRuns, scheduledRunId, true)
        },
        toogleSectionType(section, type) {
            let i = section.types.indexOf(type)
            if (i !== -1)
                section.types.splice(i,1)
            else
                section.types.push(type)
        },
        saveToLocalStorage(key, value) {
            localStorage[key] = JSON.stringify(value)
        },
        loadFromLocalStorage(key) {
            try {
                if (localStorage[key])
                    return JSON.parse(localStorage[key], dateParser)
            }
            catch (ex) {
                console.warn(`cannot load ${key} from local storage`, ex)
            }
        },
        actualizeSections(sections) {
            if (sections === undefined) {
                this.actualizeSections(this.editingSections)
                this.actualizeSections(this.currentSections)
                return
            }
            for (let section of sections) {
                if (section.types === undefined) {
                    section.types = this.types.map(type => type.code)
                }
                for (let rule of section.rules) {
                    let libraryRule = _(this.rulesLibrary)
                        .filter(({type}) => type === rule.type)
                        .minBy(({name, text}) =>
                            text === rule.text || name == rule.name ? 0 :
                                1 + levenshtein(text.slice(0, 40), rule.text.slice(0, 40)))
                                
                    if (libraryRule) {
                        console.log("UPGRADE", rule.name, "with", libraryRule.name)

                        // we need original non-migrated options here
                        for (let prop of _.keys(rule)) {
                            if (rule[prop] !== undefined &&
                                rule.options &&
                                _.isPlainObject(rule.options[prop]) &&
                                rule.options[prop][rule[prop]] !== undefined &&
                                libraryRule.options &&
                                _.isPlainObject(libraryRule.options[prop]) &&
                                libraryRule.options[prop][rule[prop]] === undefined)
                            {
                                let value = rule.options[prop][rule[prop]]
                                for (let name of _.keys(libraryRule.options[prop])) {
                                    if (libraryRule.options[prop][name] == value) {
                                        console.log("REPLACE", prop, rule[prop], "with", name)
                                        rule[prop] = name
                                    }
                                }
                            }
                        }

                        // this.$set(rule, "name", libraryRule.name)
                        this.$set(rule, "text", libraryRule.text)
                        this.$set(rule, "grouper", libraryRule.grouper || [])
                        this.$set(rule, "options", libraryRule.options || {})

                        let systemKeys = new Set([
                            "type",
                            "name",
                            "text",
                            "grouper",
                            "options"])

                        let editedKeys = new Set([...libraryRule.text.matchAll(/{([^}\.\[]+)[^}]*?}/g)].map((match) => match[1]))
                        // console.log({systemKeys, editedKeys})

                        for (let key of Object.keys(libraryRule)) {
                            if (!systemKeys.has(key) &&
                                (!editedKeys.has(key) || rule[key] === undefined) &&
                                libraryRule[key] !== undefined &&
                                !_.isEqual(libraryRule[key], rule[key]))
                            {
                                console.log("REPLACE", key, rule[key], "with", libraryRule[key])
                                this.$set(rule, key, libraryRule[key])
                            }
                        }
                    }
                    if (rule.min === -9999)
                        rule.min = -99999
                    if (rule.max === 10001)
                        rule.max = 100001
                    if (rule.rounding_ranges) {
                        for (range of rule.rounding_ranges) {
                            let fixEndings = (endings) => {
                                let fixedEndings = _(endings)
                                        .map(_.trim)
                                        .filter()
                                        .map(x => x.split(/[^0-9]+/g))
                                        .flatten()
                                        .uniq()
                                        .sort()
                                        .value()
                                if (!_.isEqual(endings, fixedEndings)) {
                                    console.log("REPLACE", "endings", endings, "WITH", fixedEndings)
                                    endings.splice(0, endings.length, ...fixedEndings)
                                }
                            }
                            fixEndings(range.wholeEndings)
                            fixEndings(range.fractionalEndings)
                            if (range.ignorePrices === undefined)
                                range.ignorePrices = []
                        }
                    }
                    if (rule.type === "weak_optimization")
                        rule.type = "balanced_optimization"

                    for (let filter of rule.filter || []) {
                        let zones = filter["store.price_zone"]
                        if (zones !== undefined) {
                            delete filter["store.price_zone"]
                            filter["price_zone"] = zones
                        }
                    }
                }
            }
        },
        handleWsMessage(message) {
            if (message.optimizationId == this.optimizationId) {
                if (message.status !== undefined)
                    this.optimizationStatus = message.status
                if (message.progress !== undefined)
                    this.optimizationProgress = message.progress
                if (message.changes !== undefined) {
                    this.optimizationId = null
                    this.stats = message
                    utils.bridge.trigger("optimizationComplete", message.optimizationId)
                }
            }
        },
        isRuleVisible(rule) {
            return true
            // return this.hideRulesOutOfScope ?
            //     this.currentRulesInScope !== null &&
            //     this.currentRulesInScope[rule.id] !== false : true
        },
        sectionName(section) {
            return section.name || utils.l10n("Default section")
        },
        addSection() {
            let name = window.prompt(utils.l10n("Please enter rules section name"))
            if (name) {
                let id = utils.randomId()
                this.editingSections.push({id, name, rules: [], types:_.clone(this.defaultTypes)})
                this.$set(this.expandedSections, id, true)
            }
        },
        promptEntityName(entity, text) {
            let name = window.prompt(utils.l10n(text, entity.name), entity.name)
            if (name)
                this.$set(entity, "name", name)
        },
        promptEntityWeight(entity, text) {
            let weight = entity.weight !== undefined ? entity.weight * 100 : 100;
            weight = window.prompt(utils.l10n(text), new Number(weight).toLocaleString())
            if (weight)
                this.$set(entity, "weight", parseFloat(weight.replace(",",".")) / 100)
        },
        promptSectionName(section) {
            this.promptEntityName(section, "Please enter rules section name", section.name)
        },
        promptSectionWeight(section) {
            this.promptEntityWeight(section, "Please enter rules section weight")
        },
        cloneSection(section) {
            let newSection = _.cloneDeep(section)
            newSection.id = utils.randomId()
            newSection.name = this.sectionName(section)
            newSection.name += ' '
            newSection.name += utils.l10n('(copy)')
            for (let rule of newSection.rules)
                rule.id = utils.randomId()
            this.editingSections.splice(this.editingSections.indexOf(section)+1, 0, newSection)
        },
        deleteSection(section) {
            if (window.confirm(
                utils.l10n("Are you sure you want to delete section {section}?")
                .replace("{section}", this.sectionName(section))))
            {
                this.editingSections.splice(this.editingSections.indexOf(section), 1)
            }
        },
        cloneRule(rule) {
            let newRule = _.cloneDeep(rule)
            newRule.id = utils.randomId()
            newRule.name += ' '
            newRule.name += utils.l10n('(copy)')
            for (let section of this.editingSections) {
                let i = section.rules.indexOf(rule)
                if (i !== -1)
                    section.rules.splice(i+1, 0, newRule)
            }
        },
        deleteRule(rule) {
            if (window.confirm(
                utils.l10n("Are you sure you want to delete rule {rule}?")
                .replace("{rule}", rule.name)))
            {
                for (let section of this.editingSections) {
                    let i = section.rules.indexOf(rule)
                    if (i !== -1)
                        section.rules.splice(i, 1)
                }
            }
        },
        setupRulesSortable() {
            console.log("setupRulesSortable")
            $(this.$refs.rules)
                .sortable({
                    axis: "y",
                    handle: "label",
                    cursor: "move",
                    helper: "original",
                    distance: 5,
                    forceHelperSize: true,
                    forcePlaceholderSize: true,
                    start: (e, ui) => {
                        ui.item.data("n", -1)
                        let h = ui.item.height();
                        console.log({h})
                        ui.placeholder.height(h)
                    },
                    change: (e, ui) => {
                        let x = ui.placeholder[0]
                        let n = Array.prototype.indexOf.call(x.parentNode.childNodes, x)
                        ui.item.data("n", n)
                    },
                    beforeStop: (e, ui) => {
                        this.preventEditing = true
                        _.defer(() => this.preventEditing = false)
                        ui.item.parent().sortable("cancel")

                        let section = ui.item.data("section")
                        let rule = ui.item.data("rule")
                        let n = ui.item.data("n")
                        if (n != -1) {
                            section = this.editingSections.find(({id}) => id === section)
                            let i = _.findIndex(section.rules, ({id}) => id === rule)
                            rule = section.rules.splice(i, 1)[0]
                            section.rules.splice(n > i ? n-1 : n, 0, rule)
                        }
                    }
                })
        },
        expandAllRules(section) {
            for (let rule of section.rules)
                this.$set(this.expandedRules, rule.id, true)
        },
        collapseAllRules(section) {
            for (let rule of section.rules)
                this.$delete(this.expandedRules, rule.id)
        },
        isAllRulesExpanded(section) {
            return _.every(section.rules, ({id}) => this.expandedRules[id])
        },
        promptRuleName(rule) {
            this.promptEntityName(rule, "Please enter price rule name")
        },
        promptRuleWeight(rule) {
            this.promptEntityWeight(rule, "Please enter price rule weight")
        },
        /*makeScopeFilter(filter) {
            return !_.isEmpty(filter) ?
                _(filter)
                    .map((condition) =>
                        _(condition)
                            .toPairs()
                            .map(([key, value]) => 
                                value.length == 1
                                    ? `(${this.resolveSubstitutes(key)}) == ${utils.quote(value[0])}`
                                    : `(${this.resolveSubstitutes(key)}) in ${utils.quote(value)}`)
                            .join(" && "))
                    .join(" || ") : "true"
        },
        makeProbeFilter(filter) {
            return !_.isEmpty(filter) ?
                `any(${_(filter)
                    .map((condition) =>
                        _(condition)
                            .toPairs()
                            .map(([key, value]) => 
                                value.length == 1
                                    ? `(${this.resolveSubstitutes(key)}) == ${utils.quote(value[0])}`
                                    : `(${this.resolveSubstitutes(key)}) in ${utils.quote(value)}`)
                            .join(" && "))
                    .join(" || ")})` : "true"
        },*/
        configChanged(config) {
            console.log("CONFIG CHANGED", config)
            if (config) {
                let [defaultStrategyStartDate, defaultStrategyEndDate] = defaultStrategyDates()
                config.startDate = config.startDate || defaultStrategyStartDate
                config.endDate = config.endDate || defaultStrategyEndDate
                let sections = config.sections
                if (!sections && config.rules) {
                    let id = utils.randomId()
                    sessions = [{id, rules: config.rules}]
                }
                this.editingSections = _.cloneDeep(sections) || []
                this.currentSections = _.cloneDeep(sections) || []
                this.editingFilter = _.cloneDeep(config.filter) || []
                this.currentFilter = _.cloneDeep(config.filter) || []
                this.actualizeSections()
            }
        },
        toogleConfig() {
            this.showConfig = !this.showConfig
            if (this.showConfig) {
                let config = _.omitBy({
                    filter: this.editingFilter,
                    sections: this.editingSections,
                    schedule: this.config?.schedule,
                }, _.isUndefined)
                console.log({config})
                this.configYaml = jsyaml.safeDump(config)
            }
        },
        applyConfig() {
            try {
                let config = jsyaml.safeLoad(this.configYaml)
                this.editingFilter = config.filter
                this.editingSections = config.sections
                this.$set(this.config, "schedule", config.schedule)
            }
            catch (ex) {
                alert(ex)
            }
        },
        formatPercent(x) {
            if (x == null)
                return "–"
            switch (x) {
                case -10000000: return "-∞"
                case 10000000: return "+∞"
                default:
                    return new Number(x/100).toLocaleString(this.locale, {
                        style: "percent",
                        maximumFractionDigits: 1
                    })
            }
        },
        formatPrice(x) {
            if (x == null)
                return "–"
            switch (x) {
                case -10000000: return "-∞"
                case 10000000: return "+∞"
                default:
                    return new Number(x).toLocaleString(this.locale, {
                        style: "currency",
                        currency: this.currency,
                        maximumFractionDigits: 2
                    })
            }
        },
        formatUnits(x) {
            if (x == null)
                return "–"
            switch (x) {
                case -10000000: return "-∞"
                case 10000000: return "+∞"
                default:
                    return new Number(x).toLocaleString(this.locale, {
                        maximumFractionDigits: 2
                    })
            }
        },
        formatMoney(x) {
            if (x == null)
                return "+∞"
            switch (x) {
                case -1000000000000: return "-∞"
                case 1000000000000: return "+∞"
                default:
                    return new Number(x).toLocaleString(this.locale, {
                        style: "currency",
                        currency: this.currency,
                        maximumFractionDigits: 2
                    })
            }
        },
        /*updateCurrentRulesInScope(report) {
            let rules = _.map(report.meta.columns, "name")
            let flags = report.rows[0]
            console.log("updateCurrentRulesInScope", {rules, flags})
            if (flags)
                this.currentRulesInScope = _.fromPairs(_.zip(rules, flags))
            else
                this.currentRulesInScope = _.fromPairs(_.map(rules, rule => [rule, false]))
        },*/
        addRule(section, rule) {
            console.log("add rule", rule)
            rule = _.cloneDeep(rule)
            rule.id = utils.randomId()
            if (!rule.filter)
                rule.filter = []
            if (!rule.grouper)
                rule.grouper = []
            section.rules.push(rule)
            this.$set(this.expandedRules, rule.id, true)
            this.showRulesLibrary = null
        },
        changeProp(part) {
            if (this.newPropValue !== null)
                part.handleChange(this.newPropValue)
        },
        submitChanges() {
            this.currentSections = _.cloneDeep(this.editingSections)
            this.currentFilter = _.cloneDeep(this.editingFilter)
        },
        discardChanges() {
            this.editingSections = _.cloneDeep(this.currentSections)
            this.editingFilter = _.cloneDeep(this.currentFilter)
        },
        getPossibleValues(calc) {
            let possibleValues = this.possibleValues
            let values = possibleValues[calc]
            if (!values) {
                values = utils.query({
                    stream: this.stream,
                    source: this.source,
                    dims: [calc],
                    sort: [1],
                    filter3: "dim1 != ''"
                }).then((rows) => {
                    let fuzzySearch = new FuzzySearch(
                        rows,
                        ["0"],
                        { caseSensitive: false, sort: true })
                    window.fuzzySearch = fuzzySearch
                    return fuzzySearch
                })
                this.$set(possibleValues, calc, values)
            }
            return values
        },
        getAttributeByCalc(calc) {
            return this.attributes.find((attribute) => attribute.calc == calc) || {calc, name:calc}
        },
        getAttributeByName(name) {
            return this.attributes.find((attribute) => attribute.name == name) || {name, calc:name}
        },
        changeCondition(rule, i, calc) {
            rule.filter.splice(
                i,
                1,
                _(rule.filter[i])
                    .toPairs()
                    .map((condition) => {
                        if (condition[0] == calc) {
                            return [
                                this.getAttributeByName(this.$refs.attribute[0].value).calc,
                                _(this.$refs.values[0].value)
                                    .split("\n")
                                    .map(_.trim)
                                    .filter()
                                    .uniq()
                                    .value()]
                        }
                        else return condition
                    })
                    .fromPairs()
                    .value())
        },
        deleteCondition(rule, i, calc) {
            this.$delete(rule.filter[i], calc)
        },
        cleanupConditions(rule) {
            this.$set(rule, "filter",
                rule.filter
                    .map((conditions) => {
                        this.$delete(conditions, "")
                        return conditions
                    })
                    .filter((conditions) => !_.isEmpty(conditions)))
        },
        async optimize() {
            this.submitChanges()
            for (let optimizationType of this.optimizationTypes) {
                if (optimizationType.startsWith("_"))
                    continue
                let sections = _.cloneDeep(this.currentSections)
                let filter = _.cloneDeep(this.currentFilter) || []
                let weight = this.startWeight
                let number = 1
                let rules = []
                for (let section of sections) {
                    if (!section.types.includes(optimizationType))
                        continue
                    if (section.weight === undefined)
                        section.weight = 1
                    for (let rule of section.rules) {
                        rule.number = number
                        if (rule.weight === undefined)
                            rule.weight = 1
                        rule.weight *= section.weight
                        if (rule.options)
                            for (let key of Object.keys(rule))
                                if (_.isPlainObject(rule.options[key])) {
                                    let isArray = Array.isArray(rule[key])
                                    for (let i = 0; i < (isArray ? rule[key].length : 1); i++) {
                                        let v = isArray ? rule[key][i] : rule[key]
                                        let value = rule.options[key][v]
                                        if (value !== undefined) {
                                            if (isArray)
                                                rule[key][i] = value
                                            else
                                                rule[key] = value
                                        }
                                    }    
                                }
                        number = number + 1
                        rules.push(rule)
                    }
                }
                this.stats = null
                this.optimizationId = utils.randomId()
                this.optimizationStatus = "starting optimization"
                this.optimizationProgress = 0

                let opt_configuration = _.clone(this.optimization)

                let [opt_start_date, opt_end_date] =
                    eval(this.timeframes[gptable.futureTimeframe].calc)(
                        utils.parseDate(this.referenceDate))

                opt_configuration.opt_start_date = this.config.startDate || utils.formatDate(opt_start_date)
                opt_configuration.opt_end_date = this.config.endDate || utils.formatDate(opt_end_date)

                opt_configuration.type = optimizationType
                
                if (opt_configuration.plan_start_date)
                    opt_configuration.plan_start_date = `${opt_configuration.plan_start_date} if isnull(${opt_configuration.plan_start_date} != 0, false) else \`${opt_configuration.opt_start_date}\``
                if (opt_configuration.endoflife)
                    opt_configuration.endoflife = `${opt_configuration.endoflife} if isnull(${opt_configuration.endoflife} != 0, false) else \`${opt_configuration.opt_end_date}\``

                let config_name = this.runName || this.config.name || "untitled"

                let priceZone = gptable.priceZone

                let type = this.types.find(type => type.code == optimizationType)
                if (type) {
                    if (type.priceZone)
                        priceZone = type.priceZone
                    if (type.type) {
                        opt_configuration.type = type.type
                        config_name = `${config_name} – ${type.name}`
                    }
                }

                let intials = this.username?.slice(0,2)?.toUpperCase() || "NA"
                let serial = (await fetch(`/storage/user/${this.username}/optimization`)
                    .then(res => res.status == 200 ? res.json() : null))?.serial || 0
                serial += 1
                await fetch(
                    `/storage/user/${this.username}/optimization`,
                    {method: 'PUT', body: JSON.stringify({serial})})

                config_name = `${intials}-${String(serial).padStart(4,'0')} ${config_name}`
                if (this.temporaryRun)
                    config_name = `[tmp] ${config_name}`

                let opt_included = "!isnull(optimization_exclusion.excluded, false)"
                await fetch("/optimize", {
                    method: "POST",
                    headers: {"content-type": "application/json"},
                    body: JSON.stringify({
                        extra_filter0: this.extraFilter0,
                        extra_filter1: this.extraFilter1,
                        extra_filter2: this.extraFilter2 ? (this.extraFilter2+" && " + opt_included) : opt_included,
                        run_id: this.optimizationId,
                        config_id: this.config.id || "untitled",
                        config_name,
                        config_data: this.config,
                        filter,
                        rules,
                        reference_date: window.referenceDate,
                        effective_date: window.effectiveDate,
                        competitors: this.competitors,
                        formulas: this.formulas,
                        opt_configuration: opt_configuration,
                        permalink: await gptable?.createPermalink({optimizationId: this.optimizationId}),
                        config: _.assign({}, this.settings, { location: priceZone }),
                        vars: this.vars,
                        threads_count: this.threadsCount,
                    }, dateReplacer, 2),
                })
            }
        },

        handleVarsChanged(vars) {
            this.vars = vars;
        },

        parseRule(rule) {
            let parts = []
            for (let segment of utils.l10n(rule.text).split('{')) {
                let closingPosition = segment.indexOf('}')
                if (closingPosition !== -1) {
                    let prop = segment.slice(0, closingPosition)
                    let type = "text"
                    let value = _.get(rule, prop)
                    let format = (x) => `${x}`
                    switch (prop) {
                        case "budget":
                            console.log("parse rule: budget")
                            type = "number"
                            format = this.formatMoney
                            break
                        case "min":
                        case "max":
                        case "secondary_min":
                        case "secondary_max":
                        case "target":
                            type = "number"
                            if (value != null)
                                value = Math.round((value - 1) * 1000) / 10
                            format = this.formatPercent
                            break
                        case "limit":
                        case "goal_target":
                            type = "number"
                            if (value != null)
                                value = Math.round(value * 1000) / 10
                            format = this.formatPercent
                            break
                        case "demand_limit":
                        case "md_cnt":
                        case "md_gap":
                        case "md_lag":
                        case "min_cpi":
                        case "max_cpi":
                            type = "number"
                            format = this.formatUnits
                            break
                        case "range_start":
                        case "range_end":
                        case "rounding_ranges[0].start":
                        case "rounding_ranges[0].end":
                        case "revenue_limit":
                        case "margin_limit":
                        case "range_start":
                        case "range_end":
                        case "min_abs":
                        case "max_abs":
                            type = "number"
                            format = this.formatPrice
                            break
                        case "rounding_ranges[0].wholeEndings":
                        case "rounding_ranges[0].fractionalEndings":
                        case "rounding_ranges[0].ignorePrices":
                        case "percents":
                        case "md_percents":
                        case "md_states":
                            format = (endings) => _.isEmpty(endings) ? utils.l10n("<empty>") : endings.join(", ")
                            break
                        case "md_dates":
                            type = "dates"
                            format = (endings) => _.isEmpty(endings) ? utils.l10n("<empty>") : [...endings].sort((a, b) => a - b).map(x => x.toLocaleDateString()).join(", ")
                            break
                        case "md_force_percents":
                            type = "value_by_date"
                            format = (endings) => _.isEmpty(endings)
                                ? utils.l10n("<empty>") 
                                : Object.keys(endings)
                                    .map(x => `${new Date(x).toLocaleDateString()}: ${endings[x].type ==="le" ? ">=" : ""}${endings[x].value}`)
                                    .join(", ")
                            break

                    }
                    parts.push({
                        tag: "input",
                        type,
                        prop,
                        value,
                        format,
                        handleChange: (value) => {
                            console.log("handleChange", { value })                            
                            switch (prop) {
                                case "min":
                                case "max":
                                case "secondary_min":
                                case "secondary_max":
                                case "target":
                                    if (value === "" || value === "-" || value === "–") {
                                        switch (prop) {
                                            case "min":
                                            case "secondary_min":
                                                value = -10000000
                                                break
                                            case "max":
                                            case "secondary_max":
                                                value = 10000000
                                                break
                                            case "target":
                                                value = null
                                                break
                                        }
                                        if (value != null)
                                            rule[prop] = 1 + value / 100
                                        else
                                            rule[prop] = null
                                    }
                                    else {
                                        value = parseFloat(value)
                                        if (!_.isNaN(value))
                                            rule[prop] = 1 + value / 100 
                                    }
                                    break
                                case "limit":
                                case "goal_target":
                                    value = parseFloat(value)
                                    if (!_.isNaN(value))
                                        rule[prop] = value / 100
                                    break
                                case "range_start":
                                case "range_end":
                                case "rounding_ranges[0].start":
                                case "rounding_ranges[0].end":
                                    if (value === "") {
                                        switch (prop) {
                                            case "range_start":
                                            case "rounding_ranges[0].start":
                                                value = -10000000
                                                break
                                            default:
                                                value = 10000000
                                        }
                                        _.set(rule, prop, value)
                                    }
                                    else {
                                        value = parseFloat(value)
                                        if (!_.isNaN(value))
                                            _.set(rule, prop, value)
                                    }
                                    break
                                case "budget":
                                    console.log("parts.push budget")
                                    if (value === "" || value === "-" || value === "–" || value === "+∞") {
                                        value = 1000000000000
                                        _.set(rule, prop, value)
                                    }
                                    else {
                                        value = parseFloat(value)
                                        if (!_.isNaN(value))
                                            _.set(rule, prop, value)
                                    }
                                    break
                                case "demand_limit":
                                case "revenue_limit":
                                case "margin_limit":
                                case "md_cnt":
                                case "md_gap":
                                case "md_lag":
                                case "min_cpi":
                                case "max_cpi":
                                case "min_abs":
                                case "max_abs":
                                    if (value === "")
                                        _.set(rule, prop, null)
                                    else {
                                        value = parseFloat(value)
                                        if (!_.isNaN(value))
                                            _.set(rule, prop, value)
                                    }
                                    break
                                case "rounding_ranges[0].ignorePrices":
                                    value = value.split(/[^0-9]+/).filter(x => x)
                                    _.set(rule, prop, value)
                                    break
                                case "md_states":
                                case "percents":
                                    value = value.split(/[^0-9]+/).map(x => parseInt(x)).filter(x => x)
                                    _.set(rule, prop, value)
                                    break
                                case "md_percents":
                                    value = value.split(/[^0-9.]+/).map(x => parseFloat(x)).filter(x => x)
                                    _.set(rule, prop, value)
                                    break
                                // case "rounding_ranges[0].wholeEndings":
                                // case "rounding_ranges[0].fractionalEndings":
                                //     value = value.split(/[\s,]+/g)
                                default:
                                    _.set(rule, prop, value)
                                    break
                            }
                            this.$forceUpdate()
                        }
                    })
                    let text = segment.slice(closingPosition + 1)
                    parts.push({
                        tag: "span",
                        text: text
                    })
                }
                else {
                    parts.push({
                        tag: "span",
                        text: segment
                    })
                }
            }
            return parts
        },
    },
    watch: {
        config: {
            deep: true,
            handler() {
                this.saveToLocalStorage("currentRulesConfig", this.config)
            },
        },
        rulesLibrary() {
            this.actualizeSections()
        },
        expandedSections() {
            this.$nextTick(() => this.setupRulesSortable())
        },
        editingSections: {
            deep: true,
            handler(sections) {
                this.$set(this.config, "sections", sections)
                this.$delete(this.config, "rules")
            }
        },
        editingFilter: {
            deep: true,
            handler(filter) {
                this.$set(this.config, "filter", filter)
            }
        },
        activeControl() {
            this.newPropValue = null
            Vue.nextTick(() => {
                let self = this
                $("*[autofocus]").focus()
                $("*[autofocus]").select()
                if (this.autocomplete)
                    this.autocomplete.autocomplete("destroy")
                this.autocomplete = $(this.$refs.values).autocomplete({
                    minLength: 0,
                    appendTo: this.$refs.autocomplete,
                    source: function(request, response) {
                        let searchTerm = _.last(request.term.split("\n"))
                        self.getPossibleValues(
                            self.getAttributeByName(
                                $(self.$refs.attribute).val()).calc)
                            .then((fuzzySearch) => {
                                let values = fuzzySearch.search(searchTerm)
                                if (values.length > self.threshold) {
                                    let more = utils.l10n("and {more} more...").replace("{more}", new Number(values.length-self.threshold).toLocaleString())
                                    values = values.slice(0, self.threshold)
                                    values.push([more])
                                }
                                response(values.map((row) => row[0]))
                            })
                            .catch(() => response([]))
                    },
                    focus: () => false,
                    select: (event, ui) => {
                        let terms = $(this.$refs.values).val().split("\n")
                        terms.pop()
                        terms.push(ui.item.value)
                        let value = terms.join("\n")
                        $(this.$refs.values).val(value)
                        return false;
                    },
                })
            })
        }
    }
}
</script>
<style>
.gp-rules-hint {
    margin: 10px 0;
    font-size: 0.9em;
    font-style: italic;
    opacity: 0.9;
}
.gp-rules-sections {
    margin: 20px -20px;
}
.gp-rules-add-rule {
    margin: 4px 32px;
}
.gp-rules-sections > a {
    margin-top: 10px;
    margin-left: 32px;
    display: block;
}
.gp-rules-sections > a + a {
    margin-top: 5px;
}
.gp-rules-section {
    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
    margin-top: -1px;
}
.my-dark-theme .gp-rules-section {
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
}
.gp-rules-section > label {
    display: block;
    background-color: var(--light);
    padding: 4px 10px;
    margin: 0;
}
.my-dark-theme .gp-rules-section > label {
    background-color: var(--dark);
}
.gp-rules-add-rule {
    margin-bottom: 10px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    float: right;
}
.gp-rules-section > label .feather-icon-more-vertical {
    visibility: hidden;
}
.gp-rules-section.expanded > label .feather-icon-more-vertical {
    visibility: visible;
}
.gp-rules-list {
    list-style: none;
    margin: 10px 10px;
    padding: 0;
}
.gp-rules .feather-icon svg {
    width: 18px;
    height: 18px;
}
.gp-rules-list > li {
    padding: 2px 0;
    margin: 0;
    border-top: 1px solid transparent;
}
.gp-rules-list > li label {
    /*background-color: var(--light);*/
    /*padding-top: 0;*/
    /*display: block;*/
    /*margin: 0;*/
    /*padding: 0;*/
    padding: 0;
    margin: 0;
    display: block;
    /*line-height: 1;*/
}
.gp-rules-list > li.expanded > label {
    padding-bottom: 2px;
    border-bottom: 1px solid var(--dark);
}
.gp-rules-list > li.expanded > label + p {
    padding-top: 8px;
}
.gp-rules-list > li p {
    font-size: 0.9em;
    padding: 4px 0;
    padding-left: 10px;
    margin-left: 30px;
    border-left: 1px solid var(--dark);
}
.gp-rules-list > li p:last-child {
    margin-bottom: 10px;
}
.gp-rules-list .feather-icon-more-vertical {
    float: right;
}
.gp-rules-list .feather-icon-more-vertical {
    visibility: hidden
}
.gp-rules-list .expanded .feather-icon-more-vertical {
    visibility: visible;    
}
.gp-rules-list > li label .feather-icon svg {
    display: inline-block;
    vertical-align: top;
    /*margin-top: 2px;*/
}
.gp-rules-list > li p {
    line-height: 1.3;
    font-size: 0.9em;
}
.gp-rule-conditions {
    display: inline;
}
.gp-rule-popup {
    padding: 8px 8px;
    border-radius: 4px;
    display: inline-block;
    background-color: rgb(191,223,294);
}
.gp-rule-popup input,
.gp-rule-popup select {
    margin-bottom: 6px;
}
.gp-rule-popup textarea + div {
    margin-bottom: 6px;
    position: relative;
}
.gp-rule-popup .btn.btn-xs {
    padding: 0 8px;
    font-size: 0.9em;
}
.gp-rules .gp-rules-list .new svg {
    /*padding: 1px;*/
    color: #1489FF80;
    border: none;
    /*border-radius: 4px;*/
    margin-top: -2px;
    margin-bottom: -6px;
    vertical-align: top;
}
.gp-rules .operator {
    opacity: 0.8;
    font-style: italic;
}
.gp-rule-popup-actions {
    display: flex;
    margin-right: -6px;
}
.gp-rule-popup-actions > * {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 6px;
}
.ui-autocomplete {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute!important;
    left: inherit!important;
    top: inherit!important;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /*margin-left: 8px;*/
    /*margin-top: -8px;*/
    /*width: 100px!important;*/
}
.ui-autocomplete .ui-menu-item-wrapper {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    padding: 0 8px;
    font-size: 1em;
}
.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    background-color: #3498db30!important;
    color: inherit!important;
}
.gp-rule-popup {
    box-shadow: 0 0 10px 10px #00000010;
    border: 1px solid var(--cyan);
}
.my-dark-theme .gp-rule-popup {
    background-color: rgb(35,53,74);
    border: 1px solid black;
}
.gp-rule-popup input,
.gp-rule-popup select {
    min-width: 150px;
}
.gp-rule-popup input[type="number"] {
    max-width: 150px;
}
.gp-rules-actions {
    display: flex;
    margin-right: -10px;
}
.gp-rules-actions > * {
    margin-right: 10px;
    flex-grow: 1;
}
.gp-rule-delete {
    float: right;
    margin-right: 4px;
}
.gp-rules .feather-icon-filter svg {
    width: 16px;
    height: 16px;
    opacity: 0.8;
}
.gp-rule-delete {
    margin-top: 2px;
}
.gp-rules-configuration .ace_editor {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px)!important;
    margin-bottom: 15px;
}
.gp-rules-library li {
    padding: 2px 0;
}
.gp-rules-list + .gp-rules-list {
    margin-top: 0;
    margin-bottom: 10px;
}
.gp-rules-library {
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid gray;
    font-size: 0.9em;
    line-height: 1.4;
}
.gp-rules-library li {
    cursor: pointer;
}
.gp-rules-library li a {
    padding: 1px 20px;
    display: block;
    color: #444;
    text-decoration: none!important;
}
.gp-rules-library li:hover {
    background-color: var(--blue);
}
.gp-rules-library li:hover a {
    color: white;
}
.gp-rules-actions {
    margin-bottom: 15px;
}
.gp-rules-section > label + a {
    float: right;
    display: none;
    margin-top: 3px;
    margin-right: 12px;
    font-size: 0.9em;
}
.gp-rules-section.expanded > label + a {
    display: block;
}
.gp-rules-section > label + a + ul {
    content: "";
    clear: right;
    display: block;
}
.gp-rules > .gp-check {
    margin-top: 10px;
}
.gp-rule-popup,
.gp-filter-popup {
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.gp-filter-values textarea {
    height: 100px;
}
.gp-rule-popup > *,
.gp-filter-popup > * {
    margin-bottom: 10px!important;
}
.gp-rule-popup > *:last-child,
.gp-filter-popup > *:last-child {
    margin-bottom: 0!important;
}
.gp-rule-popup .btn.btn-xs {
    padding: 2px 8px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    margin-top: -2px;
}
.gp-rules-list .gp-rule-filter {
    padding-top: 8px;
}
.gp-rules-list .gp-check {
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
}
.gp-rules-weight {
    float: right;
    font-size: 0.9em;
    margin-right: 4px;
}
.gp-rules-weight svg {
    width: 22px!important;
    height: 22px!important;
    margin-left: 2px;
    margin-right: 4px;
}
.gp-rules-sections {
    clear: right;
}
.gp-rules-list {
    margin-top: 30px;
}
.gp-rules-weight {
    margin-left: 5px;
}
.gp-rules-list {
    margin-top: 30px;
}
.gp-rules-section > label .feather-icon-more-vertical {
    margin-top: -2px;
}
.gp-rules-list .gp-rule-filter {
    padding-top: 8px;
}
.gp-rules-list .gp-check {
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
}
.gp-rules-weight {
    float: right;
    font-size: 0.9em;
    margin-right: 4px;
}
.gp-rules-weight svg {
    width: 22px!important;
    height: 22px!important;
    margin-left: 2px;
    margin-right: 4px;
}
.gp-rules-sections {
    clear: right;
}
.gp-rules-stats {
    padding: 10px 15px;
    margin: 10px 0;
    margin-bottom: 20px;
    border: 2px solid var(--cyan);
    border-radius: 10px;
    font-size: 0.95em;
}
.gp-rule-filter .gp-filter {
    display: inline;
}
.gp-rule-filter .feather-icon-plus {
    margin-top: 1px;
    margin-bottom: -1px;
    vertical-align: top;
    display: inline-block;
}
.gp-rule-filter .gp-filter + .gp-filter:before {
    content: "exclude ( ";
}
.gp-rule-filter .gp-filter + .gp-filter:after {
    content: " )";
}
.gp-rule-filter .gp-filter + .gp-filter .feather-icon-filter {
    display: none;
}
.gp-rules-list {
    margin-top: 10px;
}
.gp-section-opt-types-checks {
    margin-top: 10px;
    margin-left: 30px;
}
.gp-section-opt-types-icons {
    float: right;
    font-size: 0.9em;
    margin: 0 4px;
}
.gp-section-opt-types-icons > :not(.active) {
    color: var(--gray);
}
.gp-section-opt-types-icons span + span {
    margin-left: 4px;
}
.gp-runs-schedule {
    list-style: none;
    padding-left: 25px;
}
.gp-runs-schedule > li {
    position: relative;
}
.gp-runs-schedule > li.expanded:before {
    content: "";
    position: absolute;
    left: -20px;
    width: 2px;
    top: 20px;
    bottom: 0;
    background: var(--cyan);
}
.gp-runs-schedule > li > a .feather-icon svg {
    position: absolute;
    margin-top: 2px;
    margin-left: -29px;
    width: 20px;
    height: 20px;
}
.gp-runs-schedule .btn-group-toggle + .vc-container {
    margin-top: 15px;
}
.gp-runs-schedule .vc-container {
    display: block;
    margin: auto;
}
.gp-rules .btn-group-toggle {
    display: flex;
    margin-right: -8px;
}
.gp-rules .btn-group-toggle .btn {
    flex-basis: 1px;
    flex-grow: 1;
    margin-right: 8px;
}
.gp-rules .btn-group-toggle .btn.btn-secondary {
    color: inherit;
    background-color: inherit;
}
.gp-rules .btn-group-toggle .btn.btn-secondary.active {
    color: inherit;
    background-color: #3498db50;
}
.my-dark-theme .btn-group-toggle .btn.btn-secondary.active {
    color: white;
    background-color: var(--blue);
}
.gp-rules-stats {
    padding: 10px 15px;
    margin: 10px 0;
    margin-bottom: 20px;
    border: 2px solid var(--cyan);
    border-radius: 10px;
    font-size: 0.95em;
}
.gp-rules .progress {
    margin-bottom: 15px;
}
.gp-rules .gp-rules-list .new svg {
    color: inherit;
}
.gp-rules-auto-accept-bounds {
    display: flex;
    margin-right: -10px;
    margin-top: 5px;
    margin-left: 20px;
}
.gp-rules-auto-accept-bounds > * {
    margin-right: 10px;
    align-self: center;
}
.gp-rules-auto-accept-bounds span {
    font-size: 0.9em;
    line-height: 1.3em;
    white-space: pre-line;
}
.gp-rules-auto-accept-bounds input {
    width: 70px;
}
.gp-rules > .form-group > a > .feather-icon {
    margin-top: -2px;
    vertical-align: top;
    display: inline-block;
}
</style>