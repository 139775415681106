let {escape, renderClasses, renderStyle} = require("./plain-table-static-utils.js")

let rowClass = (props) => {
    let classes = {
        primary: props.primary,
        selected: props.selected,
    }
    for (let section of props.sections) {
        for (let column of section.columns) {
            if (column.rowClass) {
                let class_ = column.rowClass(
                    props.row[column.i],
                    props.row,
                    column,
                    props.meta)
                if (class_)
                    classes[class_] = true
            }
        }
    }
    return classes
}
let rowStyle = (props) => {
    let style = {}
    for (let section of props.sections)
        for (let column of section.columns)
            if (column.rowStyle)
                _.assign(style,
                    column.rowStyle(
                        props.row[column.i],
                        props.row,
                        column,
                        props.meta))
    if (props.row.level !== undefined)
        style["--level"] = `${props.row.level}`
    return style
}
let staticCells = (props) => {
    if (props.row.__cache.staticCells)
        return props.row.__cache.staticCells
    let t0 = performance.now()
    let staticCells = []
    let cellProps = {
        row: props.row,
        meta: props.meta,
    }
    for (let section of props.sections) {
        cellProps.section = section
        for (let column of section.visibleColumns) {
            cellProps.column = column
            staticCells.push(
                new PlainTableCellStatic(cellProps).render())
        }
    }
    staticCells = staticCells.join("")
    let t1 = performance.now()
    // console.log("static cells rendering for row", props.i, "took", t1 - t0, "ms")
    props.row.__cache.staticCells = staticCells
    return staticCells
}

export class PlainTableRowStatic {
    constructor(props) {
        this.props = props
    }
    render() {
    return `
    <tr
        :data-row="${this.props.i}"
        :class="${escape(renderClasses(rowClass(this.props)))}"
        :style="${escape(renderStyle(rowStyle(this.props)))}">
        ${staticCells(this.props)}
    <tr>`
    }
}
