<template>
  <div class="productcard-container">
    <header class="header">
      <div class="header__title-block">
        <button
          type="button"
          class="button-return"
          @click="onBackBtnClick"
        />
        <p class="header-title">Happy Horses (apt. HRSS707)</p>
        <my-tooltip-simple>
          <button type="button" class="button-info" />
          <template v-slot:tooltip-data>
            <div class="header-title-tooltip">
              <p>ID карточки №001936</p>
              <p>Создано: 04/03/2023</p>
              <p>Автор: Tatyana I.</p>
              <p>Последнее изменение: 06/10/2023</p>
            </div>
          </template>
        </my-tooltip-simple>
      </div>
      <div class="header__search-form">
        <autoorder-search />
      </div>
    </header>
    <main class="main">
      <section class="main__section section">
        <h3 class="section__title">Справочник товара</h3>
        <div class="inputblock">
          <label
            class="inputblock__label"
            for="vendor-code"
          >
            Артикул
          </label>
          <input
            v-model="vendorCode"
            class="inputblock__input input"
            id="vendor-code"
            placeholder="Введите артикул товара"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="suppliers">Поставщики</label>
          <my-multiselect
            :options="suppliers"
            :selectedOption="selectedSuppliers"
            multiple
            searchable
            placeholder="Выберите поставщиков"
            @select="onSuppliersSelect"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="manufacturer">Производитель</label>
          <my-multiselect
            :options="manufacturers"
            :selectedOption="selectedManufacturer"
            searchable
            @select="onTrademarkSelect"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="trademark">Торговая марка</label>
          <my-multiselect
            :options="trademarks"
            :selectedOption="selectedTrademark"
            searchable
            @select="onTrademarkSelect"
          />
        </div>
      </section>
      <section class="main__section">
        <h3 class="section__title">Атрибуты товара</h3>
        <div class="inputblock">
          <label class="inputblock__label" for="expiration-date">Срок годности, дн.</label>
          <input
            v-model="expirationDaysQuantity"
            class="inputblock__input input"
            id="expiration-date"
            placeholder="Укажите срок годности товара"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="volume">Вес / Объём</label>
          <input
            v-model="volume"
            class="inputblock__input input"
            id="volume"
            placeholder="Укажите срок годности товара"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="package-capacity">Вложение в короб</label>
          <input
            v-model="packageCapacity"
            class="inputblock__input input"
            id="package-capacity"
            placeholder="Укажите срок годности товара"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="analogs">Аналоги</label>
          <input
            v-model="analogs"
            class="inputblock__input input"
            id="analogs"
            placeholder="Укажите аналоги"
          />
        </div>
        <div class="inputblock">
          <label class="inputblock__label" for="category">Категория</label>
          <my-multiselect
            :options="categories"
            :selectedOption="selectedCategory"
            searchable
            @select="onCategorySelect"
          />
        </div>
      </section>
      <section class="main__section">
        <div class="fileinput">
          <div v-if="!filePreview" class="fileinput__stub" />
          <div v-if="filePreview" class="fileinput__image-container">
            <img
              :src="filePreview"
              alt="fileName"
              class="fileinput__image"
              style="max-height: 256px; margin-bottom: 5px; border-radius: 5px;"
            >
          </div>
          <my-fileinput @file-updated="captureFile($event)" />
        </div>
      </section>
    </main>
    <footer class="footer">
      <div class="footer__checkbox">
        <gp-check
          :checked="isActiveCard"
          @change="handleChange"
        >
          &nbsp;&nbsp;
          <l10n value="Активная карточка" />
        </gp-check>
      </div>
      <div class="footer__buttons-block">
        <button
          type="button"
          class="button button_primary"
        >
          Дублировать
        </button>
        <button
          type="button"
          class="button button_secondary"
        >
          Сохранить
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
const MyFileInput = require('../my-fileinput.vue').default;
const MyTooltipSimple = require('../my-tooltip-simple.vue').default;
const MyMultiSelect = require('../my-multiselect.vue').default;
const AutoorderSearch = require('./autoorder-search.vue').default;

module.exports = {
  components: {
    'my-fileinput': MyFileInput,
    'my-tooltip-simple': MyTooltipSimple,
    'my-multiselect': MyMultiSelect,
    'autoorder-search': AutoorderSearch,
  },

  props: {

  },

  data() {
    return {
      isActiveCard: true,
      isPieceGoods: true,
      selectedManufacturer: 'Procter&Gamble',
      manufacturers: ['Sunny Fields ent. ltd', 'Sony', 'Microsoft', 'Procter&Gamble', 'PagerDuty', 'Pfizer'],
      suppliers: ['Sunny Fields ent. ltd', 'Sony', 'Microsoft', 'Procter&Gamble', 'PagerDuty', 'Pfizer'],
      selectedSuppliers: '',
      selectedTrademark: 'Little Freddie',
      trademarks: ['Little Freddie', 'Sony Vaio', 'Microsoft Windows', 'Head&Shoulders', 'GNFR', 'Viagra'],
      expirationDaysQuantity: 5,
      vendorCode: 'HPPHRS002',
      volume: '1000 г',
      packageCapacity: 12,
      analogs: '-',
      categories: ['Sunny Fields ent. ltd', 'Sony', 'Microsoft', 'Procter&Gamble', 'PagerDuty', 'Pfizer'],
      selectedCategory: 'Pfizer',
      filePreview: '',
      fileName: '',
    };
  },

  methods: {
    handleChange() {
      console.log('changed');
    },

    onClickSearchBtn() {
      console.log('search btn clicked');
    },

    onBackBtnClick() {
      console.log('back btn clicked');
    },

    onTrademarkSelect(value, id) {
      console.log('onTrademarkInput', value, id);
    },

    onManufacturerSelect(value, id) {
      console.log('onManufacturerInput', value, id);
    },

    onCategorySelect(value, id) {
      console.log('onCategorySelect', value, id);
    },

    onSuppliersSelect(value) {
      console.log('onSuppliersSelect', value);
    },

    captureFile($event) {
      this.filePreview = $event.previewBase64;
      this.fileName = $event.name;
    },
  },
};
</script>

<style scoped>
.productcard-container {
  display: flex;
  flex-direction: column;
  width: 1376px;
  min-height: 880px;
  margin: 0 auto;
  padding: 0.65rem;
  font-weight: 600;
}
.productcard-container h2 {
  font-weight: 600;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.header__title-block {
  display: flex;
  align-items: center;
}
.header-title {
  margin-bottom: 0;
  margin-right: 12px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}
.header-title-tooltip {
  width: 210px;
  text-align: left;
}
.header-title-tooltip p {
  margin-bottom: 0;
}
.main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
}
.main__section {
  display: flex;
  flex-direction: column;
  width: 437px;
}

.section__title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.inputblock {
  margin-bottom: 16px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}
.input {
  display: flex;
  align-items: center;
  width: 438px;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.input:hover {
  background-color: #E9E9E9;
}
.input:focus {
  border-color: #5295D5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
  box-shadow: 0 0 0 0.2rem #5295D5;
}
.select {
  display: flex;
  align-items: center;
  width: 438px;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7 url('./arrow-down.svg') no-repeat center right 10px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
.fileinput__stub {
  width: 438px;
  height: 440px;
  background: #F7F7F7 url('./image-icon.svg') no-repeat center center;
  border-radius: 8px;
}
.fileinput__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 438px;
  height: 440px;
  background: #F7F7F7;
  border-radius: 8px;
  overflow: hidden;
}
.button-return {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border: none;
  border-radius: 50%;
  background: #F7F7F7 url('./arrow-left.svg') no-repeat center center;
}
.button-return:hover {
  background-color: #E9E9E9;
}
.button-return_disabled {
  background-color: #E9E9E9;
}
.button-return:focus {
  outline: none;
}
.button-info {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-bottom: 1px;
  border: none;
  border-radius: 50%;
  background: transparent;
  background: url('./info.svg') no-repeat center center;
}
.button-info:focus {
  outline: none;
}
.icon-button svg {
  stroke: #cfcfcf;
}
.icon-button:focus {
  outline: none;
  box-shadow: 0px 0px 3px 3px #5295D5;
}
.header__search-form {
  display: flex;
}
.main {
  display: flex;
  justify-content: space-between;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.modal-container {
  left: 305px;
  max-height: 100vh;
  min-width: 300px;
  max-width: 300px;
  padding: 0.5rem;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 46px;
  border: none;
  border-radius: 8px;
  transition: background-color .3s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.button_primary {
  background-color: #5295D5;
  color: #ffffff;
}
.button_secondary {
  background-color: #F7F7F7;
  color: #000000;
}
.button_primary:hover {
  background-color: #3C80C1;
  transition: background-color .3s ease-in-out;
}
.button_secondary:hover {
  background-color: #E9E9E9;
  transition: background-color .3s ease-in-out;
}
.button_primary:focus,
.button_secondary:focus {
  border-color: #5295D5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
  box-shadow: 0 0 0 0.2rem #5295D5;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.button_disabled {
  background-color: #F7F7F7;
  color: #D4D4D4;
  cursor: default;
}
.button-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 20px;
  text-decoration: underline;
  color: #3385FF;
}
.button-upload_disabled {
  color: #9B9B9B;
}
.footer__buttons-block {
  display: flex;
}
.footer__buttons-block .button_primary {
  margin-right: 8px;
}
.footer__checkbox {
  width: 200px;
}
</style>
