module.exports = _.merge({
    watch: {
        ordering: function() {
            console.log("ORDERING!")
            this.createChart()
        }
    },
    methods: {
        tuneChart: function(chart) {
            chart.select("g.axis").attr("transform", "translate(0,0)")
            chart.selectAll("line.grid-line").attr("y2", chart.effectiveHeight())
            chart.selectAll("x-axis-label, y-axis-label").remove()
            if (this.xAxisLabel)
                chart.svg()
                    .append("text")
                    .attr("class", "x-axis-label")
                    .attr("text-anchor", "middle")
                    .attr("x", chart.width()/2)
                    .attr("y", chart.height()-3.5)
                    .text(this.xAxisLabel);
            if (this.yAxisLabel) 
                chart.svg()
                    .append("text")
                    .attr("class", "y-axis-label")
                    .attr("text-anchor", "middle")
                    .attr("transform", `translate(12,${chart.height()/2}),rotate(-90)`)
                    .text(this.yAxisLabel);
        },
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length

            let dimension = this.ndx.dimension((row) => row.slice(0, n))

            let group = dimension.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))


            let chart = dc.rowChart(base)
            chart.dimension(dimension)

            for (let i = 0; i < this.vals.length; ++i) {
                let name = this.vals[i].name || this.vals[i].calc
                if (i == 0)
                    chart.group(group, name, (d) => d.value[i])
                else
                    chart.stack(group, name, (d) => d.value[i])
            }

            return chart
            /*let n = this.dims.length
            let groups = this.cols.length > 0 ? this.cols.slice(n) : this.vals
            let m = groups.length

            let dim = this.ndx.dimension((row) => row.slice(0,n))

            let grp = dim.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.rowChart(this.$refs.chart)

            chart.dimension(dim)

            _(groups)
                .forEach((group, i) => {
                    let name = _.isString(group) ? group : group.name
                    if (i === 0)
                        chart.group(grp, name, (d) => d.value[i])
                    else
                        chart.stack(grp, name, (d) => d.value[i])
                })

            return chart*/
        },
    },
}, require("./props").chartProps({
    marginTop:      { type: Number, default: 20 },
    x:              { type: [Function, String], default: d3.scaleLinear().domain([]) },
    xAxis:          { type: [Function, String], default: d3.axisTop() },
    xTickFormat:    { type: [Function, String] },
    elasticX:       { type: Boolean, default: false },
    elasticY:       { type: Boolean, default: false },
    keyAccessor:    { type: [Function, String], default: (d) => d.key.join("\n") },
    xAxisLabel:     { type: String, default: undefined },
    yAxisLabel:     { type: String, default: undefined },
}))
