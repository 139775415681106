<template>
    <div class="pim-main-foot" :class="{opened: show}">
        <div
            class="pim-main-foot-calendar"
            v-if="mode == 'simulation' && show"
            @click="$event.stopPropagation()"
            @keydown="
                $event.key == 'ArrowLeft' && calendar.days.indexOf(simulationDay) > 0 ?
                    simulationDay = calendar.days[calendar.days.indexOf(simulationDay)-1] :
                $event.key == 'ArrowRight' && calendar.days.indexOf(simulationDay) < calendar.days.length - 1 ?
                    simulationDay = calendar.days[calendar.days.indexOf(simulationDay)+1] :
                 null"
            >
            <select class="form-control form-control-sm" v-model="selectedMonth">
                <option v-for="month in calendar.months" :value="month">
                    {{month.split('-').slice(0,2).join('-')}}
                </option>
            </select>
            <a href="javascript:void()" @click="
                    selectedMonth = calendar.months[calendar.months.indexOf(selectedMonth) - 1]">
                <feather-icon name="chevrons-left"/>
            </a>
            <template v-for="day, n in calendar.days">
                <span 
                    class="day"
                    tabindex="0" 
                    :data-day="new Date(day).getDay()"
                    :class="{
                        active: day == simulationDay,
                        holiday: isHolilday(day)
                    }"
                    @focus="simulationDay = day">
                    {{day.split('-')[2]}}
                </span>
            </template>
            <a href="javascript:void()" @click="
                    selectedMonth = calendar.months[calendar.months.indexOf(selectedMonth) + 1]">
                <feather-icon name="chevrons-right"/>
            </a>
        </div>
        <div class="pim-main-foot-body" v-if="show">
            <template v-if="entry && entry.type == 'category'">
                <plain-table
                    ref="table"
                    :key="mode"
                    :stream="tableConfig.stream"
                    :source="tableConfig.source"
                    :style="{'--tile-size': `${tileSize}px`}"
                    :mode="tab"
                    :dims="tableConfig.dims"
                    :vals="tableConfig.vals"
                    :cols="tableConfig.cols"
                    :filter1="tableConfig.filter1"
                    :filter2="tableConfig.filter2"
                    :initialSort="[1]"
                    :tableActions="tableActions"
                    @cell-edited="handleCellEdit"
                    />
                <gp-section-columns
                    ref="columns"
                    :autofocus="false"
                    :persitent="true"
                    :attributes="config.attributes"
                    :metrics="config.metrics"
                    :columns="columns"
                    @submit="$refs.columns.changes = {}; columns = $event.columns;"
                    @cancel="$refs.columns.changes = {}"
                    />
            </template>
            <p v-else>
                Please select a category from the hierarchy above.
            </p>
        </div>
    </div>
</template>
<script>
let utils = require("../my-utils")

module.exports = {
    mixins: [
        utils.configHelpers,
        utils.columnsHelpers,
        utils.editingHelpers,
    ],
    props: {
        mode: { type: String },
        show: { type: Boolean },
        entry: { type: Object },
        fields: { type: Array },
        username: { type: String },
        holidays: { type: Array, default: () => [
            'January 1, 2022',
            'January 17, 2022',
            'February 14, 2022',
            'February 21, 2022',
            'April 18, 2022',
            'April 27, 2022',
            'May 8, 2022',
            'May 30, 2022',
            'June 19, 2022',
            'July 4, 2022',
            'September 5, 2022',
            'October 10, 2022',
            'October 31, 2022',
            'November 11, 2022',
            'November 24, 2022',
            'November 25, 2022',
            'December 24, 2022',
            'December 25, 2022',
            'December 26, 2022',
            'December 31, 2022'
        ] },
    },
    data() {
        let day = new Date()
        let simulationDay = utils.formatDate(day)
        day.setDate(1)
        let selectedMonth = utils.formatDate(day)
        return {
            tab: localStorage['pim-main-foot-tab'] || 'table',
            columns: JSON.parse(localStorage['pim-main-foot-columns'] || '[]'),
            tileSize: JSON.parse(localStorage['pim-main-foot-tile-size'] || '200'),
            referenceDate: "2022-01-01",
            simulationDay,
            selectedMonth,
        }
    },
    watch: {
        tab() {
            localStorage['pim-main-foot-tab'] = this.tab
        },
        columns() {
            localStorage["pim-main-foot-columns"] = JSON.stringify(this.columns)
        },
        tileSize() {
            localStorage['pim-main-foot-tile-size'] = this.tileSize
        },
        show() {
            localStorage["pim-main-foot-show"] = this.show
        },
        mode() {
            localStorage["pim-main-foot-mode"] = this.mode
        },
    },
    computed: {
        fieldGroups() {
            if (this.mode == "items")
                return ["Item Attribute"]
            if (this.mode == "variations")
                return ["Variation Detail", "Variation Flex Fields"]
            return []
        },
        config() {
            let attributes = [{
                name: "Item",
                calc: "item.item_number",
            }, {
                name: "Item Image",
                calc: "image_html",
                format: "image_html",
            }]
            let metrics = []
            let formulas = {
                image_html: "image",
            }
            let formats = {
                alphanumeric: x => x,
                boolean: x => x ? "yes" : "no",
                date: x => x ? new Date(x).toLocaleDateString() : "-",
                datetime: x => x ? new Date(x).toLocaleString() : "-",
                decimal: x => x ? Number(x).toLocaleString() : "-",
                dropdown: x => x,
                json: x => x ? JSON.stringify(x) : "-",
                multidropdown: x => x,
                numeric: x => Number(x).toLocaleString(),
                textarea: x => x,
                image_html: x => x ? `<img src="${x}">` : "",
            }
            let timeframes = {
                reference_date: {
                    calc: (now) => [now, now],
                    name: "reference_date",
                }
            }
            for (let field of this.fields) {
                if (this.fieldGroups.includes(field.group_name)) {
                    switch (field.field_type) {
                        case "alphanumeric":
                        case "textarea":
                        case "dropdown":
                        case "multidropdown":
                            let attribute = {
                                name: field.description || field.field_name,
                                type: field.field_type,
                                field: field,
                                calc: field.api_name,
                                format: field.field_type,
                            }
                            if (!field.is_derived || field.overridable) {
                                attribute.editable = true
                                attribute.stream = field.api_name
                            }
                            attributes.push(attribute)
                            break

                        default:
                            let metric = {
                                name: field.description || field.field_name,
                                type: field.field_type,
                                field: field,
                                formula: field.api_name,
                                format: field.field_type,
                            }
                            if (!field.is_derived || field.overridable) {
                                metric.editable = true
                                metric.stream = field.api_name
                            }
                            metrics.push(metric)
                    }
                }
            }
            return {
                attributes,
                metrics,
                formulas,
                formats,
                timeframes,
            }
        },
        metrics() {
            return this.config.metrics
        },
        attributes() {
            return this.config.attributes
        },
        formulas() {
            return this.config.formulas
        },
        formats() {
            return this.config.formats
        },
        timeframes() {
            return this.config.timeframes
        },
        calendar() {
            let day = utils.parseDate(this.selectedMonth)
            day.setMonth(day.getMonth() - 3)
            let months = []
            for (let i=0; i<7; i++) {
                months.push(utils.formatDate(day))
                day.setMonth(day.getMonth() + 1)
            }

            let days = []
            day = utils.parseDate(this.selectedMonth)
            let month = day.getMonth()
            while (day.getMonth() == month) {
                days.push(utils.formatDate(day))
                day.setDate(day.getDate() + 1)
                // console.log({day})
            }
            return {days, months}
        },
        formattedHolidays() {
            return this.holidays.map(day => utils.formatDate(new Date(day)))
        },
        tableActions() {
            let actions = []
            if (this.tab == "tiles") {
                actions.push({
                    icon: 'list',
                    text: 'Show as a table',
                    call: () => this.tab = 'table',
                })
                actions.push({
                    tagname: 'plain-table-tile-size',
                    config: {
                        props: { value: this.tileSize },
                        on: { change: tileSize => this.tileSize = tileSize }
                    }
                })
            }

            if (this.tab == "table") {
                actions.push({
                    icon: 'image',
                    text: 'Show as tiles',
                    call: () => this.tab = 'tiles',
                })
            }
            actions.push({
                icon: "download",
                text: "Export to Excel",
                call: () => this.$refs.table.exportToFile()
            })
            return actions
        },
        tableConfig() {
            let vals = {}
            let cols = []
            let section = {
                name: null,
                timeframe: "reference_date_plus_1",
            }
            for (let column of this.columns) {
                this.makeVals(vals, section, column)
                this.makeCols(cols, section, column)
            }
            let stream = "categories_items"
            let source = {
                dims: ["item_master_id"],
                filter1: `item_hierarchy_id == '${this.entry.id}'`,
                links: [{
                    linkName: "item",
                    sourceName: "items",
                    columnPairs: [{
                        srcColumn: "item_master_id",
                        dstColumn: "id",
                    }]
                }, {
                    linkName: "item_primary_image",
                    sourceName: "item_primary_images",
                    columnPairs: [{
                        srcColumn: "item_master_id",
                        dstColumn: "item_master_id",
                    }]
                }, {
                    linkName: "primary_image",
                    sourceName: "digital_assets",
                    columnPairs: [{
                        srcColumn: "item_primary_image.digital_asset_id",
                        dstColumn: "id"
                    }]
                }],
                funcs: [{
                    name: "image",
                    calc: "primary_image.link_url"
                }]
            }
            for (let column of this.columns) {
                console.log({column})
                let columnsMap = {
                    alphanumeric:  "alphanumeric_data",
                    boolean:       "boolean_data",
                    date:          "date_data",
                    datetime:      "datetime_data",
                    decimal:       "decimal_data",
                    dropdown:      "dropdown_data",
                    json:          "json_data",
                    multidropdown: "dropdown_data",
                    numeric:       "numeric_data",
                    textarea:      "textarea_data",
                    overridden:    "is_overridden",
                }
                let field = column.metric?.field || column.attribute?.field
                if (field) {
                    source.links.push({
                        linkName: `${field.api_name}_last`,
                        sourceName: JSON.stringify({
                            source: "item_attribute_data",
                            filter1: `sku_master_field_id == '${field.id}'`,
                            dims: "item_master_id",
                            vals: `last(${columnsMap[field.field_type]}, updated_at) as value`
                        }),
                        columnPairs: [{
                            srcColumn: "item_master_id",
                            dstColumn: "item_master_id",
                        }]
                    })
                    source.funcs.push({
                        name: field.api_name,
                        calc: `${field.api_name}_last.value`
                    })
                }
            }
            console.log({vals, cols})
            let filter1 = undefined
            let filter2 = undefined
            if (this.mode == "simulation") {
                stream = "items"
                let filters = []
                for (let rule of this.entry.rules || []) {
                    if (!rule.strategy ||
                        (!rule.strategy.dateStart ||
                        rule.strategy.dateStart <= this.simulationDay + 'T23:59:59' &&
                        !rule.strategy.dateEnd ||
                        rule.strategy.dateEnd >= this.simulationDay + 'T00:00:00'))
                    {
                        filters.push(utils.makeFilter([
                            this.makeFilter(rule.strategy?.itemFilter?.filter),
                            this.makeFilter(rule.hasItemFilter && rule.itemFilter)]))
                    }
                }
                filter1 = undefined
                filter2 = filters.join(" || ")
            }
            return {
                stream,
                source,
                filter1,
                filter2,
                dims: [{
                    calc: "item.item_number",
                    name: "Item",
                }],
                vals: _(vals)
                    .toPairs()
                    .map(([name, calc]) => ({
                        name,
                        calc: `${calc} as ${name}`,
                        show: false}))
                    .sortBy("calc")
                    .map(Object.freeze)
                    .value(),
                cols: _(cols)
                    .sortBy("id")
                    .map(Object.freeze)
                    .value(),
            }
        },
    },
    methods: {
        isHolilday(day) {
            return this.formattedHolidays.includes(day)
        },
        makeStyles(column) {
            return [null, null]
        },
        sectionName(column) {
            return null
        },
        makeFilter(filter) {
            return _(filter)
                .map((condition) =>
                    _(condition)
                        .toPairs()
                        .filter(([key, value]) => key && value)
                        .map(([key, value]) => 
                            value.length == 1
                                ? `(${key}) == ${utils.quote(value[0])}`
                                : `(${key}) in ${utils.quote(value)}`)
                        .join(" && "))
                .filter()
                .join(" || ")
        },
    }
}
</script>
<style>
.pim-main-foot {
    background: white;
    z-index: 1;
    flex-basis: 31px;
    display: flex;
    flex-direction: column;
}
.my-dark-theme .pim-main-foot {
    background: #222;
}
.pim-hierarchies-side-live {
    margin-top: 20px;
}
.pim-hierarchies-side {
    box-shadow: 0 0 10px 0px #00000020
}
.pim-hierarchies-side .form-inline .form-group {
    display: flex;
    width: 100%;
}
.pim-hierarchies-side .form-inline label {
    flex-basis: 1px;
    flex-grow: 1;
    display: block;
}
.pim-main-foot.opened {
    flex-basis: 50%;
}
.pim-main-foot-tabs {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    line-height: 30px;
    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
}
.my-dark-theme .pim-main-foot-tabs {
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
}
.pim-main-foot-tabs {
    background-image: linear-gradient(to bottom, #eee, white 5%, white 60%, #eee);
}
.pim-hierarchies-side-tabs {
    background-image: linear-gradient(to right, #eee, white 5%, white 60%, #eee);
}
.my-dark-theme .pim-main-foot-tabs {
    background: #333;
}
.my-dark-theme .pim-hierarchies-side-tabs {
    background: #333;
}
.pim-main-foot-tabs > ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}
.pim-main-foot-tabs > ul > li {
    white-space: nowrap;
    border-right: 1px solid var(--dark);
}
.pim-main-foot-tabs > ul > li.active {
    background: white;
    border-top: 1px solid var(--dark);
    margin-top: -1px;
    margin-bottom: -1px;
}
.my-dark-theme .pim-main-foot-tabs > ul > li.active {
    border-top: 1px solid var(--light);
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
    background: #222;
}
.pim-main-foot-tabs > ul > li > a {
    padding: 0 15px;
    color: var(--dark)
}
.my-dark-theme .pim-main-foot-tabs > ul > li > a {
    color: var(--light)
}
.pim-main-foot-tabs > ul > li.active > a {
    color: #222;
}
.my-dark-theme .pim-main-foot-tabs > ul > li.active > a {
    color: white;
}
.pim-main-foot-tabs > ul > li > a:hover {
    text-decoration: none;
}
.pim-main-foot-tabs svg {
    width: 20px;
    margin-top: -2px;
    margin-right: 6px;
}
.pim-main-foot {
    position: relative;
}
.pim-main-foot-body {
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
}
.pim-main-foot-calendar + .pim-main-foot-body {
    top: 70px;
}
.pim-main-foot-body > p {
    color: var(--dark);
    font-size: 0.95em;
}
.my-dark-theme .pim-main-foot-body > p {
    color: var(--light);
}
.pim-main-foot-body .plain-table {
    flex-basis: 1px;
    flex-grow: 1;
    padding: 10px 20px;
    overflow-y: auto;
}
.pim-main-foot-body .my-column-dim {
    display: none;
}
.pim-main-foot-body .gp-section-columns {
    flex-basis: 300px;
    padding: 10px;
    padding-left: 0;
}
.pim-main-foot-body .gp-section-columns {
    display: flex;
    flex-direction: column;
}
.pim-main-foot-body .gp-section-columns > * {
    margin-bottom: 0!important;
}
.pim-main-foot-body .gp-section-columns-actions {
    border-top: 1px solid var(--dark);
    margin-right: 0;
    padding-top: 10px;
}
.gp-section-columns-actions > *:last-child {
    margin-right: 0;
}
.pim-main-foot-body .gp-section-columns ul {
    list-style: none;
    border: none;
    margin: 0;
    font-size: 0.9em;
}
.pim-main-foot-body .gp-section-columns ul input {
    transform: translate(0, -1px);
}
.pim-hierarchies-head p {
    color: var(--dark);
    margin-bottom: 0;
    font-size: 0.95em;
    line-height: 1.4;
}
.my-dark-theme .pim-hierarchies-head p {
    color: var(--light);
}
.pim-hierarchies-head .gp-check {
    color: var(--dark);
    font-size: 0.95em;
}
.my-dark-theme .pim-hierarchies-head .gp-check {
    color: var(--light);;
}
.pim-hierarchies-head .gp-check input {
    transform: translate(0, -1px);
}
.pim-hierarchies-main {
    overflow-y: auto;
    padding: 15px 20px;
    margin-right: 0;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 400px);
}
.matched {
    color:var(--red);
    text-decoration: underline;
}
.pim-hierarchies input[type="search"] {
    margin-bottom: 10px;
}
.pim-hierarchies-move {
    float: right;
    border: 2px solid var(--light);
    border-radius: 50%;
    margin: 10px;
    position: sticky;
    top: 10px;
    background: #ffffffaa;
    z-index: 2;
    display: flex;
    flex-direction: column;
    line-height: 18px;
    padding: 4px;
}
.my-dark-theme .pim-hierarchies-move {
    border: 2px solid var(--dark);
    background: #00000022;
}
.pim-hierarchies-move > * {
    display: flex;
    height: 22px;
}
.pim-hierarchies-move > * > * {
    width: 22px;
}
.pim-hierarchies-move svg {
    width: 18px;
    height: 18px;
    margin: 2px;
    display: block;
}
.pim-hierarchies-entry.focused
> .pim-hierarchies-entry-head
> .pim-hierarchies-entry-name {
    background: var(--light);
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--cyan);
}
.my-dark-theme .pim-hierarchies-entry.focused
> .pim-hierarchies-entry-head
> .pim-hierarchies-entry-name {
    background: var(--dark);
}
.pim-hierarchies a.disabled {
    pointer-events: none;
    color: var(--dark);
    opacity: 0.7;
}
.my-dark-theme .pim-hierarchies a.disabled {
    color: var(--light);
}
.pim-hierarchies-entry-desc {
    color: var(--dark);
}
.my-dark-theme .pim-hierarchies-entry-desc {
    color: var(--light);
}
.pim-hierarchies-entry-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
    padding-right: 2px;
    margin: -1px;
}
.pim-hierarchies-entry-head .gp-check {
    transform: translate(0, 1px);
    margin-left: 6px;
    margin-right: -6px;
}
.pim-hierarchies-entry-name {
    padding: 0 4px;
    cursor: pointer;
    position: relative;
    margin-left: 1px;
}
.pim-hierarchies-open svg {
    width: 18px;
    height: 18px;
    display: inline-block;
}
.pim-hierarchies-entry {
    position: relative;
}
.pim-hierarchies-open {
    position: absolute;
    top: -1px;
    left: -20px;
}
.pim-hierarchies-menu {
    font-size: 1em;
    max-width: 250px;
    white-space: nowrap;
}
.pim-hierarchies-menu label {
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    text-overflow: ellipsis;
}
.pim-hierarchies-menu ul {
    list-style: none;
    margin: 0 -8px;
    padding: 0;
}
.pim-hierarchies-menu li a {
    display: block;
    padding: 2px 10px;
}
.pim-hierarchies-menu li a svg {
    width: 17px;
    height: 17px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    margin-right: 4px;
}
.pim-hierarchies-search {
    width: 300px;
}
.pim-hierarchies input[type="search"] {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--gray);
    background: transparent;
}
.pim-hierarchies input[type="search"]:focus {
    outline: none;
    box-shadow: none;
    /*color: white;*/
    background: transparent;
    border-bottom-color: var(--dark);
}
.my-dark-theme .pim-hierarchies input[type="search"]:focus {
    border-bottom-color: white;
}
.pim-hierarchies ::-webkit-search-cancel-button {
    position: relative;
    right: -10px;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-size: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(80,80,80)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
}
.pim-hierarchies-head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}
.pim-hierarchies-side {
    flex-basis: 450px;
    display: flex;
    flex-direction: row;
}
.pim-hierarchies-side-main {
    padding: 15px 20px;
}
.pim-hierarchies-side-main > p {
    color: var(--dark);
    font-size: 0.95em;
    line-height: 1.4;
}
.my-dark-theme .pim-hierarchies-side-main > p {
    color: var(--light);
}
.pim-hierarchies-side-tabs {
    width: 32px;
    border-left: 1px solid var(--dark);
    border-right: 1px solid var(--dark);
}
.my-dark-theme .pim-hierarchies-side-tabs {
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
}
.pim-hierarchies-side-tabs > ul {
    background-color: white;
    width: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
.my-dark-theme .pim-hierarchies-side-tabs > ul {
    background-color: var(--dark);
}
.pim-hierarchies-side-tabs > ul > li {
    display: inline-block;
    border-bottom: 1px solid var(--dark);
}
.my-dark-theme .pim-hierarchies-side-tabs > ul > li {
    border-bottom: 1px solid var(--light);
}
.pim-hierarchies-side-tabs > ul > li > a {
    display: block;
    padding-top: 100%;
    vertical-align: top;
}
.pim-hierarchies-side-tabs > ul > li > a > span {
    color: #444;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    transform-origin: 0 0;
    white-space: nowrap;
    transform: rotate(-90deg) translate(-14px, -1px);
}
.pim-hierarchies-side {
    position: relative;
}
.pim-hierarchies-side-main {
    position: absolute;
    top: 0;
    left: 32px;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.pim-hierarchies-side-tabs > ul {
    background: transparent;
}
.pim-hierarchies-side-tabs > ul > li > a {
    /*background: var(--light);*/
    background: transparent;
}
.pim-hierarchies-side-tabs > ul > li > a > span {
    color: var(--dark);
}
.my-dark-theme .pim-hierarchies-side-tabs > ul > li > a > span {
    color: var(--light);
}
.pim-hierarchies-side-tabs > ul > li.active {
    background: white;
}
.my-dark-theme .pim-hierarchies-side-tabs > ul > li.active {
    background: #222;
}
.pim-hierarchies-side-tabs > ul > li.active > a > span {
    color: #222
}
.my-dark-theme .pim-hierarchies-side-tabs > ul > li.active > a > span {
    color: white;
}
.pim-hierarchies-side-main .gp-stored {
    top: -16px;
    margin-top: -16px;
}
.pim-hierarchies-side-main .plain-table-body {
    overflow-x: auto;
    margin: 0 -20px;
    width: calc(100% + 40px);
}
.pim-hierarchies-side-main .plain-table-body > table {
    padding: 0 20px;
}
.pim-filter-popup {
    width: 320px;
    padding: 10px;
}
.pim-filter-popup-notes {
    max-width: initial;
}
.pim-hierarchies-side-main svg {
    width: 18px;
    height: 18px;
}
.pim-hierarchies-rules {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}
.pim-hierarchies-rule-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pim-hierarchies-rule-head:not(:last-child) {
    padding-bottom: 4px;
    border-bottom: 1.5px solid var(--cyan);
}
.pim-hierarchies-rule-body {
    margin-left: 9px;
    padding: 10px 15px;
    padding-bottom: 0;
    border-left: 1.5px solid var(--cyan);
}
.pim-hierarchies-side-main input,
.pim-hierarchies-side-main select,
.pim-hierarchies-side-main textarea {
    color: black;
}
.pim-hierarchies-side-main .form-group > label {
    margin-bottom: 5px;
    color: var(--dark);
}
.my-dark-theme .pim-hierarchies-side-main .form-group > label  {
    color: var(--light);
}
.my-dark-theme ::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
}
.my-dark-theme .pim-hierarchies-side-main input,
.my-dark-theme .pim-hierarchies-side-main select,
.my-dark-theme .pim-hierarchies-side-main textarea {
    background: transparent;
    color: white;
    border-bottom: 1px solid var(--light);
    border-radius: 0;
}
.pim-hierarchies-side-main .form-group > label:first-child:last-child {
    display: block;
    margin-bottom: -10px;
}
.pim-hierarchies-rule-head svg {
    transform: translate(0, -1px);
}
.pim-hierarchies-rule-body .form-group > .gp-check {
    font-size: 0.9em;
    margin-left: 6px;
    border-left: 1.5px solid var(--cyan);
    padding-left: 37px;
}
.pim-hierarchies-rule-body .form-group > .gp-check input {
    margin-top: 4px;
}
.pim-hierarchies-rule-body .pim-filter {
    margin-left: 6px;
    padding: 4px 0;
    padding-left: 15px;
    border-left: 1.5px solid var(--cyan);
}
.pim-hierarchies-side-main .editable:hover {
    text-decoration: underline;
}
.pim-hierarchies-rules-actions {
    margin-top: 15px;
    margin-right: -10px;
    display: flex;
}
.pim-hierarchies-rules-actions > * {
    margin-right: 10px;
}
.pim-hierarchies a > .feather-icon:not(:last-child) svg {
    margin-top: -4px;
}
.plain-table-body img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: -4px 0;
    vertical-align: top;
    object-fit: contain;
    /*outline: 1px solid red;*/
}
.plain-table-presented-row img {
    max-width: 200px;
    max-height: 200px;
}
.plain-table-presented-row th,
.plain-table-presented-row td {
    border: none!important;
}
.plain-table-presented-row th:after,
.plain-table-presented-row td:after {
    content: none!important;
}
.plain-table-presented-row td.my-column-tagged > span,
.plain-table-presented-row td.my-column-string > span {
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
}
.plain-table-tile-size {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    margin-right: -10px;
    align-items: center;
    margin-left: 1px;
}
.plain-table-tile-size > * {
    margin: 0;
    padding: 0;
    margin-right: 4px;
    line-height: 20px;
    color: var(--cyan);
}
.plain-table-tile-size svg {
    width: 18px;
    height: 18px;
    display: block;
}
.plain-table-tile-size input {
    padding: 2px 4px;
    height: auto;
    width: 60px;
    text-align: right;
}
.plain-table-manage-table ul > li > a svg {
    display: inline-block;
    transform: translate(0, 2px);
}
.plain-table-tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-right: -10px;
    margin-bottom: -10px;
    --tile-padding: 10px;
    font-size: 0.95em;
    transition: opacity 0.1s;
}
.plain-table-tile {
    flex-basis: calc(var(--tile-size) + var(--tile-padding) * 2 + 2px);
    padding: var(--tile-padding) ;
    border: 1px solid var(--gray);
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.plain-table-tile img {
    width: calc(var(--tile-size) + var(--tile-padding) * 2 - 2px);
    height: calc(var(--tile-size) + var(--tile-padding) * 2 - 2px);
    margin: 0 calc(1px - var(--tile-padding));
}
.plain-table-tile td:nth-last-child(2) img {
    margin-bottom: calc(0px - var(--tile-padding));
}
.plain-table-tile > a {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    line-height: 18px;
    z-index: 1;
}
.plain-table-tile:hover > a {
    display: block;
}
.plain-table-tile > a svg {
    display: block;
    width: 18px!important;
    height: 18px!important;
}
.plain-table-tile > a + td:not(.my-column-editable) {
    margin-top: -5px;
}
.plain-table-tile th,
.plain-table-tile td {
    padding: 2px 0;
    position: relative;
    line-height: 21px;
    display: block;
    width: var(--tile-size);
}
.plain-table-tile td {
    min-height: 25px;
}
.plain-table-tile td.my-column-editable {
    padding: 2px 5px;
}
.plain-table-tile label {
    margin: 0;
    position: absolute;
    left: 0;
    z-index: -1;
    color: #aaa;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.plain-table-tile td.my-column-editable label {
    padding-left: 5px;
    color: var(--cyan);
    opacity: 0.5;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.plain-table-tile td.my-column-editable.editable input {
    font-size: 1em;
}
.plain-table-tile td.my-column-editable.editable > span:not(:empty) + span {
    margin-top: -21px;
}
.plain-table-tile label + span {
    background: white;
}
.my-dark-theme .plain-table-tile label + span {
    background: #222;
}
.pim-hierarchies-side .form-group {
    margin-bottom: 8px;
}
.pim-hierarchies-side .form-group > label {
    font-size: 0.9em;
    margin-bottom: 3px;
}
.pim-hierarchies-side .edited {
    color: var(--red);
}
.pim-hierarchies-side .mixed svg,
.pim-hierarchies-side .edited svg {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
    margin-left: -2px;
}
.pim-hierarchies-side .edited svg {
    color: var(--red);
}
.pim-hierarchies-side .mixed {
    color: var(--teal);
}
.pim-hierarchies-attributes-actions {
    background: white;
    position: sticky;
    bottom: -15px;
    padding: 10px 0;
}
.my-dark-theme .pim-hierarchies-attributes-actions {
    background: #222;
}
.pim-hierarchies-entries table {
    max-width: 100%;
}
.pim-hierarchies-entries tbody > tr:first-child > td {
    padding-top: 10px;
}
.pim-hierarchies-entries th {
    font-weight: normal;
    color: var(--dark);
    padding: 4px 10px;
    line-height: 1.4;
    font-size: 0.9em;
    vertical-align: bottom;
    position: sticky;
    top: -15px;
    z-index: 1;
    background: white;
}
.my-dark-theme .pim-hierarchies-entries th {
    background: #222;
    color: var(--light);
}
.pim-hierarchies-entries th:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid var(--dark);
    left: 0;
    right: 0;
    bottom: 0;
}
.my-dark-theme .pim-hierarchies-entries th:after {
    border-bottom: 1px solid var(--light);
}
.pim-hierarchies-entries td:not(:first-child) {
    padding-left: 6px;
}
.pim-hierarchies-entries td:not(:first-child) svg {
    position: absolute;
    top: 4px;
    left: -5px;
    width: 16px;
    height: 16px;
}
.pim-hierarchies-entries td {
    position: relative;
    padding: 0 px;
    white-space: nowrap;
}
.pim-hierarchies-entries td:not(:first-child) {
    color: var(--gray);
    /*border-left: 1px solid var(--gray);*/
}
.pim-hierarchies-entries td > span {
    display: block;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    text-overflow: ellipsis;
    font-size: 0.95em;
}
.pim-main-foot-calendar {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
    padding: 8px 20px;
    vertical-align: top;
    user-select: none;
}
.pim-main-foot-calendar select {
    display: inline-block;
    width: 80px;
    border: none;
    border-bottom: 1px solid var(--dark);
    border-radius: 0;
    padding: 2px;
    margin: -1px;
    height: 24px;
    font-size: 14px;
    color: #222;
}
.pim-main-foot-calendar svg {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: 1px;
}
.pim-main-foot-calendar .day {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    border: 1px solid var(--gray);
    background: white;
    margin-left: 4px;
}
.my-dark-theme .pim-main-foot-calendar .day {
    background: #222;
}
.pim-main-foot-calendar .month {
    margin: 0 4px;
}
.pim-main-foot-calendar .day[data-day="0"],
.pim-main-foot-calendar .day[data-day="6"] {
    /*color: white;*/
    background: var(--light);
}
.pim-main-foot-calendar .day.holiday {
    color: var(--red);
    border-color: var(--red);
    background: #e74c3c22;
}
.pim-main-foot-calendar .day:focus {
    outline: none;
}
.pim-main-foot-calendar .day.active {
    border-color: var(--cyan);
    outline: 1px solid var(--cyan);
    transform: scale(1.2);
}
</style>