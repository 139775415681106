<template>
  <div>
    <vue-multiselect
      v-model="value"
      :options="options"
      :searchable="searchable"
      :close-on-select="true"
      :show-labels="true"
      :placeholder="placeholder10n"
      :multiple="multiple"
      @select="onSelect"
      :limit="3"
      :hide-selected="true"
      noResult="Ничего не найдено. Измените поисковый запрос"
      noOptions="Список значений пуст"
    />
  </div>
</template>

<script>
const utils = require('./my-utils');

module.exports = {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: utils.l10n('Выберите опцию'),
    },
    selectedOption: {
      type: String,
      required: false,
      default: '',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      value: '',
    };
  },

  computed: {
    placeholder10n() {
      return utils.l10n(this.placeholder);
    },
  },

  mounted() {
    if (this.selectedOption) {
      this.value = this.selectedOption;
    }
  },

  methods: {
    onSelect(value, id) {
      this.$emit('select', { value, id });
    },
  },
};
</script>

<style>
.multiselect__tags {
  width: inherit;
  min-height: 46px;
  font-weight: 400;
  border: none;
  border-radius: 8px;
  background-color: #f7f7f7;
  transition: background-color 0.15s ease-in-out;
}
.multiselect__tags:hover {
  background-color: #E9E9E9;
}
.multiselect__single {
  font-family: 'Inter';
  font-weight: 400;
  line-height: 26px;
  background-color: inherit;
}
.multiselect__placeholder {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background-color: inherit;
}
.multiselect__input {
  background: inherit;
  line-height: inherit;
}
.multiselect__tag {
  background-color: #3385FF;
}
.multiselect__tag-icon:hover {
  background-color: #5295D5;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background-color: #3385FF;
}
/* .multiselect * {
    box-sizing: border-box;
}
*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
} */
.multiselect__option {
  font-weight: 400;
}
</style>
