var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("my-multiselect", {
    staticClass: "input-search",
    attrs: {
      options: _vm.options,
      loading: _vm.isLoading,
      "internal-search": false,
      "close-on-select": true,
      "options-limit": 100,
      placeholder: "Введите название продукта",
      searchable: ""
    },
    on: { "search-change": _vm.asyncFind },
    model: {
      value: _vm.selectedValue,
      callback: function($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }