<template>
    <div class="my-filters">
        <a
            v-for="filter in visibleFilters"
            :class="filterClass(filter)"
            href="javascript:void(0)"
            @click="removeFilter(filter)"
            >
            {{formatFilter(filter)}}
        </a>
        <span v-if="showMoreLink">
            and
            <a 
                href="javascript:void(0)"
                @click="showAll=true"
            >{{value.length - visibleFilters.length}}</a>
            more
        </span>
        <a v-if="showClearLink"
            href="javascript:void(0)"
            @click="removeAllFilters"
        >clear all</a>
        <span class="text-muted badge" v-if="value.length == 0" v-show="wasFiltered">
            no filters applied
        </span>
    </div>
</template>
<script>
let utils = require("./my-utils")
module.exports = {
    props: {
        value: { type: Array, default: () => [] },
        chart: { type: Object },
    },
    data: function() {
        return {
            showAll: false,
            wasFiltered: false
        }
    },
    computed: {
        visibleFilters: function() {
            let visibleFilters = this.value
            if (visibleFilters.length > 13 && !this.showAll)
                visibleFilters = visibleFilters.slice(0, 10);
            return visibleFilters
        },
        showMoreLink: function() {
            return this.visibleFilters.length != this.value.length
        },
        showClearLink: function() {
            return this.value.length > 5
        },
    },
    watch: {
        value() {
            if (this.value.length > 0)
                this.wasFiltered = true 
        }
    },
    methods: {
        removeFilter: function(filter) {
            console.log("removeFilter", filter)
            if (filter.type === "chart") {
                this.chart.filter(filter.value)
                this.chart.redraw()
            }
            else {
                let i = this.value.indexOf(filter)
                if (i !== -1)
                    this.value.splice(i,1)
            }
        },
        removeAllFilters: function() {
            this.value.splice(0, this.value.length)
            this.chart.filterAll()
            this.chart.redraw()
            this.showAll = false
        },
        filterClass: function(filter) {
            return {
                "badge": true,
                "badge-pill": true,
                "badge-secondary": filter.type === "chart",
                "badge-primary": filter.type === "keepOnly",
                "badge-danger": filter.type === "exclude",
            }
        },
        formatFilter: function({type, value}) {
            // console.log("formatFilter", {type, value})
            let format = (x) => {
                if (x === "")
                    return "[empty]"

                if (_.isArray(x))
                    return x.map(format).join(" - ")

                if (_.isPlainObject(x))
                    return format(x.value)

                if (this.chart.xAxis != null) {
                    let tickFormat = this.chart.xAxis().tickFormat()
                    if (tickFormat != null)
                        return tickFormat(x)
                }

                if (_.isDate(x))
                    return utils.multiFormatDate(x)

                if (_.isString(x))
                    return x

                if (_.isNumber(x))
                    return new Number(x).toLocaleString()

                return JSON.stringify(x)
            }

            return format(value)
        }
    }
}
</script>

<style>
.progress.progress-thin {
    border-radius: 0;
    height: 3px;
    background: none;
}
.progress.progress-thin .progress-bar {
    transition: width 0.1s linear;
    /*height: 2px;*/
}
.my-filters {
    /*margin-bottom: 4px;*/
    /*min-height: 22px;*/
    line-height: 22px;
    clear: left;
}
.my-filters:after {
    content: "";
    display: block;
    clear: both;
}
.my-filters > * {
    margin-right: 4px;
}
.my-filters > .badge {
    font-weight: normal;
    border-radius: 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-filters > :not(.badge) {
    font-size: 13px;
    white-space: nowrap;
}
@keyframes progress {
    from {transform: rotateZ(0); }
    to {transform: rotateZ(360deg); }
}
.my-filters .badge {
    margin-top: 6px;
    line-height: 18px;
}
</style>