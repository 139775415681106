<template>
  <div
    :class="{
      'gp-check': true,
      'form-check': !inline,
      'form-check-inline': inline,
    }"
  >
    <input
      :id="id"
      ref="checkbox"
      :type="type"
      :name="name"
      class="form-check-input input"
      :checked="checked || false"
      :disabled="disabled"
      @click="$emit('click', $event)"
      @change="$emit('change', $event.target.checked)"
    />

    <label
      :for="id"
      class="form-check-label"
    >
      <slot />
    </label>
  </div>
</template>

<script>
const utils = require('../my-utils');

module.exports = {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    type: { type: String, default: 'checkbox' },
    name: String,
    inline: Boolean,
    checked: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
  },
  data() {
    return {
      id: utils.randomId(),
    };
  },
  mounted() {
    this.$refs.checkbox.indeterminate = this.indeterminate;
  },
  watch: {
    indeterminate() {
      this.$refs.checkbox.indeterminate = this.indeterminate;
    },
  },
};
</script>

<style scoped>
input[type=checkbox] {
  position: relative;
  cursor: pointer;
}
input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #CFCFCF;
  border-radius: 3px;
  background-color:#ffffff;
  transition: background-color 0.3s ease-in;
}
input[type=checkbox]:hover:before,
input[type=checkbox]:focus:before {
  border-color: #9B9B9B;
}
input[type=checkbox]:disabled {
  border-color: #E9E9E9;
}
input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: none;
  background-color:#5295D5;
}
input[type=checkbox]:checked:disabled:before {
  border: 2px solid #CFCFCF;
}
input[type=checkbox]:checked:hover:before,
input[type=checkbox]:checked:focus:before {
  background-color: #3C80C1;
  transition: background-color 0.3s ease-in;
}
input[type=checkbox]:checked:disabled {
  background-color: #D4D4D4;
}
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-check-label {
  cursor: pointer;
}
</style>
