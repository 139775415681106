var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "supply-chains-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "h2",
        [
          _c("span", { staticStyle: { display: "none" } }, [_vm._v("Heading")]),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Параметры цепочки" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          attrs: { checked: _vm.activeChain },
          on: { change: _vm.handleChange }
        },
        [_c("l10n", { attrs: { value: "Активная цепочка" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Период действия" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("inline-input", {
              attrs: { type: "date" },
              model: {
                value: _vm.period.startDate,
                callback: function($$v) {
                  _vm.$set(_vm.period, "startDate", $$v)
                },
                expression: "period.startDate"
              }
            }),
            _vm._v("\n        -  \n      "),
            _c("inline-input", {
              attrs: { type: "date" },
              model: {
                value: _vm.period.endDate,
                callback: function($$v) {
                  _vm.$set(_vm.period, "endDate", $$v)
                },
                expression: "period.endDate"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Вид цепочки" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.chainType,
                expression: "chainType"
              }
            ],
            staticClass: "form-control form-control-sm",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.chainType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { value: "in", selected: "" } },
              [_c("l10n", { attrs: { value: "Прямая поставка" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "eq" } },
              [_c("l10n", { attrs: { value: "РЦ хранение" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "PBS" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "fd" } },
              [_c("l10n", { attrs: { value: "КДК" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "gp-check",
        {
          attrs: {
            checked: _vm.promoChain,
            disabled: _vm.isPromoChainDisabled
          },
          on: { change: _vm.handleChange }
        },
        [_c("l10n", { attrs: { value: "Промо цепочка" } })],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "h2",
        [
          _c("span", { staticStyle: { display: "none" } }, [_vm._v("Heading")]),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Параметры отправителя" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type stock-select parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Склады отгрузки" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: { value: "LF001", placeholder: "Выберите склад" }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" },
            on: { click: _vm.onStockSelect }
          },
          [_vm._v("\n      Склады >>>\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type stock-select parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Ассортиментные наборы" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: {
            value: "FreshSTG-SelfSummer007-bond",
            placeholder: "Выберите ассортимент"
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" },
            on: { click: _vm.onStockSelect }
          },
          [_vm._v("\n      Ассортимент >>>\n    ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "time-processed-container" },
        [
          _c(
            "div",
            {
              staticClass: "chain-type parameter time-select-container",
              staticStyle: { "margin-right": "10px" }
            },
            [
              _c(
                "p",
                { staticClass: "title" },
                [_c("l10n", { attrs: { value: "Время приема заявок" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timeIn,
                      expression: "timeIn"
                    }
                  ],
                  staticClass: "form-control form-control-sm time-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.timeIn = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "in" } },
                    [_c("l10n", { attrs: { value: "00.00 - 04.00" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "eq" } },
                    [_c("l10n", { attrs: { value: "04.00 - 08.00" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "08.00 - 12.00" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "12.00 - 16.00" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "16.00 - 20.00" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "ne" } },
                    [_c("l10n", { attrs: { value: "20.00 - 00.00" } })],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              attrs: { checked: _vm.noLimitations },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Без ограничений" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type parameter time-select-container" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Время обработки заявок" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.timeProcessed,
                expression: "timeProcessed"
              }
            ],
            staticClass: "form-control form-control-sm time-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.timeProcessed = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { value: "in" } },
              [_c("l10n", { attrs: { value: "00.00 - 04.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "eq" } },
              [_c("l10n", { attrs: { value: "04.00 - 08.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "08.00 - 12.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "12.00 - 16.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "16.00 - 20.00" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "20.00 - 00.00" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "p",
            { staticClass: "title", staticStyle: { width: "216px" } },
            [_c("l10n", { attrs: { value: "Минимальные границы" } })],
            1
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minCurrency_1,
                  expression: "minCurrency_1"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.minCurrency_1 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "100,000" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("ИЛИ Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minCurrency_2,
                  expression: "minCurrency_2"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.minCurrency_2 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("ИЛИ Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minCurrency_1,
                  expression: "minCurrency_1"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.minCurrency_1 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "" }
          }),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              attrs: {
                checked: _vm.noLimitationsMinimalBoundaries,
                disabled: _vm.noLimitationsMinimalBoundariesDisabled
              },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Без ограничений" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "p",
            { staticClass: "title", staticStyle: { width: "216px" } },
            [_c("l10n", { attrs: { value: "Максимальные границы" } })],
            1
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.maxCurrency_1,
                  expression: "maxCurrency_1"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.maxCurrency_1 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "100,000" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("ИЛИ Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.maxCurrency_2,
                  expression: "maxCurrency_2"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.maxCurrency_2 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("ИЛИ Ед.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.maxCurrency_3,
                  expression: "maxCurrency_3"
                }
              ],
              staticClass: "form-control form-control-sm input-sm",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.maxCurrency_3 = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "in", selected: "" } },
                [_c("l10n", { attrs: { value: "Руб." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "eq" } },
                [_c("l10n", { attrs: { value: "Шт." } })],
                1
              ),
              _vm._v(" "),
              _c(
                "option",
                { attrs: { value: "ne" } },
                [_c("l10n", { attrs: { value: "Кг." } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("Сумма")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control form-control-sm input-sm",
            attrs: { value: "" }
          }),
          _vm._v(" "),
          _c(
            "gp-check",
            {
              attrs: { checked: _vm.noLimitationsMaximalBoundaries },
              on: { change: _vm.handleChange }
            },
            [_c("l10n", { attrs: { value: "Без ограничений" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "h2",
        [
          _c("span", { staticStyle: { display: "none" } }, [
            _vm._v("Параметры получателя")
          ]),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "Параметры получателя" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type stock-select parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Склады получатели" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control form-control-sm",
          attrs: { value: "LWLOO (id:17)", placeholder: "Выберите склад" }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary select-btn",
            attrs: { type: "button" },
            on: { click: _vm.onStockSelect }
          },
          [_vm._v("\n      Склады >>>\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "chain-type time-select-container parameter" }, [
        _c(
          "p",
          { staticClass: "title" },
          [_c("l10n", { attrs: { value: "Срок поставки" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.timeDelivery,
                expression: "timeDelivery"
              }
            ],
            staticClass: "form-control form-control-sm time-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.timeDelivery = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { value: "in" } },
              [_c("l10n", { attrs: { value: "1 день" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "eq" } },
              [_c("l10n", { attrs: { value: "2 дня" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ne" } },
              [_c("l10n", { attrs: { value: "3 дня" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "fd" } },
              [_c("l10n", { attrs: { value: "4 дня" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "ij" } },
              [_c("l10n", { attrs: { value: "5 дней" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "option",
              { attrs: { value: "df" } },
              [_c("l10n", { attrs: { value: "6 дней" } })],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("p", { staticClass: "metaData" }, [
          _vm._v(
            "Создано 04/02/2021   Автор: Oleg N.    Последнее изменение: 07/02/2021"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-block" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary select-btn",
              staticStyle: { "margin-right": "5px" },
              attrs: { type: "button" },
              on: { click: _vm.onStockSelect }
            },
            [_vm._v("\n        Дублировать\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary select-btn",
              attrs: { type: "button" },
              on: { click: _vm.onStockSelect }
            },
            [_vm._v("\n        Сохранить\n      ")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "metaData" }, [
      _c("span", [_vm._v("SelfStorage001")]),
      _vm._v(" SelfStorage001 (id:4) -> LWLOO (id:17)")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }