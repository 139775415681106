<template>
    <div class="gp-filter">
        <feather-icon name="filter"/>
        <span v-for="conditions, i in filter" class="gp-filter-conditions">
            <template v-for="values, calc in conditions">
                <template v-if="activeControl == i+calc">
                    <my-popup
                        :key="activeControl"
                        placement="bottom-start"
                        :portal="popupPortal"
                        @clickoutside="
                            cleanupConditions()
                            activeControl = null"
                        >
                        <template v-slot:anchor>
                            <span style="display: inline-block;" class="ui-front"/>
                        </template>
                        <div class="gp-filter-popup">
                            <gp-select
                                ref="attribute"
                                v-model="attribute"
                                :options="sortedAttributes"
                                :autofocus="!attribute"
                                :placeholder="l10n('Attribute')"
                                recentOptionsKey="recentAttributes"
                                />
                            <!--select v-model="operator" class="form-control form-control-sm">
                                <option value="in"><l10n value="contains"/></option>
                                <option value="eq"><l10n value="equal to"/></option>
                                <option value="ne"><l10n value="not equal to"/></option>
                                <option value="lt"><l10n value="less than"/></option>
                                <option value="gt"><l10n value="greater than"/></option>
                                <option value="le"><l10n value="less or equal to"/></option>
                                <option value="ge"><l10n value="greater or equal to"/></option>
                                <option value="bt"><l10n value="between"/></option>
                            </select-->
                            <div class="gp-filter-values">
                                <feather-icon v-if="loading" name="clock"/>
                                <textarea
                                    ref="values"
                                    class="form-control form-control-sm"
                                    @focus="autocomplete.autocomplete('search')"
                                    >{{values.join("\n")}}</textarea>
                            </div>
                            <div ref="autocomplete"/>
                            <div class="gp-filter-popup-notes">
                                <l10n value="Use multiple lines to filter by multiple values"/>
                            </div>
                            <div class="gp-filter-popup-actions">
                                <button
                                    class="btn btn-primary btn-xs"
                                    :disabled="attribute == null"
                                    @click="
                                        changeCondition(i, calc)
                                        cleanupConditions()
                                        activeControl = null
                                        $event.stopPropagation()
                                    ">
                                    <l10n value="OK"/>
                                </button>
                                <button
                                    class="btn btn-secondary btn-xs"
                                    @click="
                                        cleanupConditions()
                                        activeControl = null
                                        $event.stopPropagation()">
                                    <l10n value="Cancel"/>
                                </button>
                                <button
                                    class="btn btn-danger btn-xs"
                                    @click="
                                        deleteCondition(i, calc)
                                        cleanupConditions()
                                        activeControl = null">
                                    <l10n value="Delete"/>
                                </button>
                            </div>
                        </div>
                    </my-popup>
                </template><a
                    href="javascript:void(0)"
                    @click="
                        attribute = getAttributeByCalc(calc)
                        activeControl = i+calc
                        "
                    >{{getAttributeByCalc(calc).name}}: 
                    {{values.length < 10 ?
                        values.join(", ") :
                        values.slice(0,8).join(", ") + " " +
                            l10n("and {more} more...")
                                .replace("{more}",
                                    new Number(values.length-8)
                                        .toLocaleString())}}</a>
                <l10n class="operator" value="AND"/>
                <span> </span>
            </template>
            <a
                class="new"
                href="javascript:void(0)"
                @click="
                    $event.stopPropagation()
                    $set(conditions,'',[])
                    attribute = null
                    activeControl = i
                    ">
                <feather-icon name="plus"/>
            </a>
            <l10n class="operator" value="OR" v-if="filter.length > 0"/>
            <span> </span>
        </span>
        <span class="gp-filter-conditions">
            <a
                class="new"
                href="javascript:void(0)"
                @click="
                    $event.stopPropagation()
                    activeControl = filter.length
                    filter.push({'':[]})
                    ">
                <feather-icon name="plus"/>
            </a>
        </span>
    </div>
</template>
<script>
let utils = require("../my-utils")
let FuzzySearch = require("fuzzy-search").default
module.exports = {
    mixins: [
        utils.configHelpers,
        utils.referenceDateHelper,
        utils.extraFilters,
    ],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        cores:          { type: Number, default: 16 },
        value:          { type: Array },
        stream:         { type: String, default: "default" },
        groups:         { type: Array, default: () => [] },
        source:         { type: Object },
        filter0:        { type: String },
        filter1:        { type: String },
        filter2:        { type: String },
        expand:         { type: String },
        autofilter:     { type: Boolean, default: false },
        popupPortal:    { type: String, default: "popup" },
        vars:           { type: Object, default: () => ({})},
    },
    data() {
        return {
            l10n: utils.l10n,
            filter: this.value || [],
            requestId: null,
            loading: false,
            operator: "eq",
            attribute: null,
            activeControl: null, 
            possibleValues: {},
            autocomplete: null,
            threshold: 100,
        }
    },
    beforeDestroy() {
        if (this.autocomplete)
            this.autocomplete.autocomplete("destroy")
    },
    computed: {
        sortedAttributes() {
            return this.attributes
                .filter(a => !a.deleted && a.name)
                .sort((a,b) => a.name.localeCompare(b.name))
        }
    },
    methods: {
        getPossibleValues(calc) {
            console.log("getPossibleValues", {calc})
            if (!calc)
                return Promise.resolve([])

            let referenceDate = this.referenceDate ? utils.parseDate(this.referenceDate) : null
            let date = referenceDate ? utils.nextDate(referenceDate) : null

            let resolve = calc =>
                this.resolveDateConditions(
                    this.resolveSubstitutes(calc),
                    date,
                    date,
                    referenceDate)

            let resolveVar = calc =>
                this.vars[calc] || calc

            let possibleValues = this.possibleValues
            let values = possibleValues[resolveVar(calc)]
            let source = _.cloneDeep(this.source)
            let filter0 = this.filter0
            let filter1 = this.filter1
            let filter2 = this.filter2
            if (source) {
                source.filter0 = utils.makeFilter([this.extraFilter0, source.filter0])
                source.filter1 = utils.makeFilter([this.extraFilter1, source.filter1])
                source.filter2 = utils.makeFilter([this.extraFilter2, source.filter2])
            }
            else {
                filter0 = utils.makeFilter([this.extraFilter0, filter0])
                filter1 = utils.makeFilter([this.extraFilter1, filter1])
                filter2 = utils.makeFilter([this.extraFilter2, filter2])
            }
            if (!values) {
                let requestId = utils.randomId()
                this.loading = true
                this.requestId = requestId
                values = utils.query({
                    id: requestId,
                    cores: this.cores,
                    name: "gp-filter",
                    stream: this.stream,
                    source,
                    vars: this.vars,
                    filter0,
                    filter1,
                    filter2,
                    dims: [`cast(${resolve(calc)}, string)`],
                    sort: [1],
                    filter3: "dim1 != ''",
                    expand: this.expand,
                }).then((rows) => {
                    if (requestId == this.requestId) {
                        this.loading = false
                        this.requestId = null
                    }
                    let fuzzySearch = new FuzzySearch(
                        rows,
                        ["0"],
                        { caseSensitive: false, sort: true })
                    window.fuzzySearch = fuzzySearch
                    return fuzzySearch
                })
                .catch(() => {
                    if (requestId == this.requestId) {
                        this.loading = false
                        this.requestId = null
                    }
                    return new FuzzySearch([])
                })
                this.$set(possibleValues, resolveVar(calc), values)
            }
            return values
        },
        getAttributeByCalc(calc) {
            return this.attributes.find((attribute) => attribute.calc == calc) || {calc, name:calc}
        },
        getAttributeByName(name) {
            return this.attributes.find((attribute) => attribute.name == name) || {name, calc:name}
        },
        changeCondition(i, calc) {
            this.filter.splice(
                i,
                1,
                _(this.filter[i])
                    .toPairs()
                    .map((condition) => {
                        if (condition[0] == calc) {
                            let type = this.$refs.attribute[0].value.type
                            return [
                                this.$refs.attribute[0].value.calc,
                                _(this.$refs.values[0].value)
                                    .split("\n")
                                    .map(_.trim)
                                    .filter()
                                    .uniq()
                                    .sortBy()
                                    .map(x => {
                                        switch (type) {
                                            case "int8":
                                            case "int16":
                                            case "int32":
                                            case "int64":
                                                x = parseInt(x)
                                                break
                                            case "float":
                                            case "double":
                                                x = parseFloat(x)
                                                break
                                            case "date":
                                            case "datetime":
                                                x = `\`${x}\``
                                                break
                                        }
                                        return x
                                    })
                                    .value()]
                        }
                        else return condition
                    })
                    .fromPairs()
                    .value())
        },
        deleteCondition(i, calc) {
            this.$delete(this.filter[i], calc)
        },
        cleanupConditions() {
            for (let i=0; i<this.filter.length; ++i) {
                let conditions = this.filter[i]
                this.$delete(conditions, "")
                if (_.isEmpty(conditions)) {
                    this.filter.splice(i,1)
                    break
                }
            }
        },
        boradcastFilter() {
            let filter2 =
                _(this.filter)
                    .map((condition) =>
                        _(condition)
                            .toPairs()
                            .filter(([key, value]) => key && value)
                            .map(([key, value]) => 
                                value.length == 1
                                    ? `(${this.formulas[key] || key}) == ${utils.quote(value[0])}`
                                    : `(${this.formulas[key] || key}) in ${utils.quote(value)}`)
                            .join(" && "))
                    .filter()
                    .join(" || ")

            let filters = {
                stream: this.stream,
                groups: this.groups,
                filter0: "",
                filter1: "",
                filter2,
                filter3: "",
            }

            utils.bridge.trigger("filtersChanged", this._uid, filters)
        }
    },
    mounted() {
        if (this.autofilter)
            this.boradcastFilter()
    },
    watch: {
        filter0() { this.possibleValues = {} },
        filter1() { this.possibleValues = {} },
        filter2() { this.possibleValues = {} },
        value() {
            this.filter = this.value || []
        },
        attribute() {
            this.threshold = 100
            if (this.attribute) {
                $(this.$refs.values).focus()
                if (this.autocomplete) {
                    Vue.nextTick(() => {
                        console.log("trigger autocomplete")
                        this.autocomplete.autocomplete("search", "")
                    })
                }
            }
        },
        filter() {
            this.$emit("input", this.filter)
            this.$emit("change", this.filter)

            if (this.autofilter)
                this.boradcastFilter()
        },
        activeControl() {
            Vue.nextTick(() => {
                console.log("setup autocomplete")
                let self = this
                // $("*[autofocus]").focus()
                // $("*[autofocus]").select()
                if (this.autocomplete)
                    this.autocomplete.autocomplete("destroy")
                this.autocomplete = $(this.$refs.values).autocomplete({
                    minLength: 0,
                    appendTo: this.$refs.autocomplete,
                    source: function(request, response) {
                        let searchTerm = _.last(request.term.split("\n"))
                        // let attribute = self.$refs.attribute[0].value
                        let attribute = self.attribute
                        console.log("possible values for", {attribute})
                        if (attribute) {
                            self.getPossibleValues(attribute.calc)
                                .then((fuzzySearch) => {
                                    let values = fuzzySearch.search(searchTerm)
                                    if (values.length > self.threshold) {
                                        let more = utils.l10n("and {more} more...").replace("{more}", new Number(values.length-self.threshold).toLocaleString())
                                        self.autocompleteMore = more
                                        values = values.slice(0, self.threshold)
                                        values.push([more])
                                    }
                                    response(values.map((row) => row[0]))
                                })
                                .catch(() => {
                                    response([])
                                })
                        }
                        else
                            response([])
                    },
                    focus: () => false,
                    select: (event, ui) => {
                        let terms = $(this.$refs.values).val().split("\n")
                        terms.pop()
                        terms.push(ui.item.value)
                        let value = terms.join("\n")
                        if (value == this.autocompleteMore) {
                            this.threshold *= 10
                            _.defer(() => {
                                this.autocomplete.autocomplete("search", "")
                                let e = jQuery.Event("keydown");
                                e.which = 40;
                                $(this.$refs.values).trigger("focus").trigger(e)
                            })
                        }
                        else {
                            $(this.$refs.values).val(value)
                        }
                        return false;
                    },
                })
            })
        }
    }
}
</script>
<style>
.gp-filters-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.gp-filters-list > li {
    border-top: 1px solid var(--dark);
    padding: 0;
    margin: 0;
}
.my-dark-theme .gp-filters-list > li {
    border-top: 1px solid var(--light);
}
.gp-filters-list > li label {
    background-color: var(--light);
    padding-top: 0;
    padding: 1px 4px;
    display: block;
}
.my-dark-theme .gp-filters-list > li label {
    background-color: var(--dark);
}
.gp-filters-list > li p {
    line-height: 1.3;
    font-size: 0.9em;
}
.gp-filter-conditions {
    display: inline;
}
.gp-filter-popup {
    padding: 8px 8px;
    border-radius: 4px;
    display: inline-block;
    background-color: rgb(191,223,294);
}
*[data-popper-placement] {
    z-index: 100!important;
}
.gp-filter-popup .gp-select {
    margin-bottom: 6px;
}
.gp-filter-values + div {
    margin-bottom: 6px;
    position: relative;
}
.gp-filter-popup .btn.btn-xs {
    padding: 0 8px;
    font-size: 0.9em;
}
.gp-filters .new svg {
    /*padding: 1px;*/
    color: #1489FF80;
    border: none;
    /*border-radius: 4px;*/
    margin-top: -2px;
    margin-bottom: -6px;
    vertical-align: top;
}
.gp-filters .operator {
    opacity: 0.8;
    font-style: italic;
}
.gp-filter-popup-actions {
    display: flex;
    margin-right: -6px;
}
.gp-filter-popup-actions > * {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 6px;
}
.ui-autocomplete {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute!important;
    left: inherit!importanti;
    top: inherit!important;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /*margin-left: 8px;*/
    /*margin-top: -8px;*/
    /*width: 100px!important;*/
}
.ui-autocomplete .ui-menu-item-wrapper {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    padding: 0 8px;
    font-size: 1em;
}
.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    background-color: #3498db30!important;
    color: inherit!important;
}
.gp-filter-popup {
    box-shadow: 0 0 10px 10px #00000010;
    border: 1px solid var(--cyan);
}
.my-dark-theme .gp-filter-popup {
    background-color: rgb(35,53,74);
    border: 1px solid black;
}
.gp-filter .feather-icon-trash {
    visibility: hidden
}
.gp-filter .feather-icon-trash svg {
    width: 18px;
    height: 18px;
}
ul.gp-filters-list > li:hover .feather-icon-trash {
    visibility: visible;    
}
.gp-filter-popup input[type="number"] {
    max-width: 150px;
}
.gp-filters-actions {
    display: flex;
    margin-right: -10px;
}
.gp-filters-actions > * {
    margin-right: 10px;
    flex-grow: 1;
}
.gp-filter-delete {
    float: right;
    margin-right: 4px;
}
.gp-filters .feather-icon-filter svg {
    width: 16px;
    height: 16px;
    opacity: 0.8;
}
.gp-filters-list > li label {
    padding: 4px 8px;
} 
.gp-filter-delete {
    margin-top: 2px;
}
.gp-filters-list li label {
    cursor: move
}
.gp-filters-list li {
    padding-bottom: 4px;
}
.gp-filters-list li p {
    padding: 0 8px;
    padding-bottom: 10px;
    margin: 0;
}
.gp-filters ul {
    margin-left: -20px;
    margin-right: -20px;
}
.gp-filters ul li label {
    padding: 4px 20px;
}
.gp-filters ul li p {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
}
.gp-filter-values {
    position: relative;
}
.gp-filter .feather-icon svg,
.gp-filter-popup .feather-icon svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-bottom: -2px;
}
.gp-filter .feather-icon-filter svg {
    margin-right: 4px;
}
.gp-filter-popup .feather-icon-clock {
    position: absolute;
    top: 2px;
    right: 4px;
    color: var(--dark);
}
.gp-filter-popup .feather-icon-clock svg {
    width: 16px;
    height: 16px;
}
/*.gp-filter-conditions .my-popper > div {
    z-index: 5!important;
}
*/
.gp-filter-conditions .my-popup .ui-autocomplete li {
    font-size: 13px;
}
.gp-filter-values {
    margin-bottom: 0!important;
}
.gp-filter-popup .btn.btn-xs {
    padding: 2px 8px;
}
.gp-filter .operator {
    font-style: italic;
}
.gp-filter .operator {
    font-style: italic;
}
.gp-filter-values textarea.form-control {
    height: 100px;
}
.gp-filter-popup-notes {
    font-size: 0.9em;
    max-width: 200px;
    white-space: initial;
    opacity: 0.6;
    line-height: 1.3em;
    padding: 0 6px;
}
.gp-filter {
    font-size: 14px;
}
.gp-filter textarea,
.ui-autocomplete {
    font-size: 13px;
}
.ui-widget {
    font-family: inherit;
}
.my-dark-theme .gp-filter .feather-icon-plus svg path {
    fill: #ffffff;
}
</style>