module.exports = {
    props: {
        marginTop:      { type: Number, default: 10 },
        marginLeft:     { type: Number, default: 42 },
        marginRight:    { type: Number, default: 50 },
        marginBottom:   { type: Number, default: 42 },
    },
    watch: {
        marginTop:    function(x) { console.log("marginTop"); this.setChartProperty("margins", this.margins) },
        marginLeft:   function(x) { console.log("marginLeft"); this.setChartProperty("margins", this.margins) },
        marginRight:  function(x) { console.log("marginRight"); this.setChartProperty("margins", this.margins) },
        marginBottom: function(x) { console.log("marginBottom"); this.setChartProperty("margins", this.margins) },
    },
    computed: {
        margins: function() {
            return {
                top: this.marginTop,
                left: this.marginLeft,
                right: this.marginRight,
                bottom: this.marginBottom,
            }
        },
    },
}
