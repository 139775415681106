<template>
<div class="gp-users">
    <gp-data
        v-model="classifReport"
        stream="classif"
        filter2="category != ''"
        :dims="['category', 'class']"
        />
    <div class="gp-users-tools">
        <input class="form-control form-control-sm" type="search" :placeholder="l10n('Search...')" v-model="searchString"/> 
        <div>
            <button class="btn btn-sm btn-primary" @click="user = {}; showUserDialog=true">
                <l10n value="Create user"/>
            </button>
            <button class="btn btn-sm btn-secondary" @click="resetPasswordForSelectedUsers" :disabled="selectedUsersCount == 0">
                <l10n value="Reset passwords for selected users"/>
                ({{selectedUsersCount}})
            </button>
            <button class="btn btn-sm btn-danger" @click="deleteSelectedUsers" :disabled="selectedUsersCount == 0">
                <l10n value="Delete selected users"/>
                ({{selectedUsersCount}})
            </button>
        </div>
    </div>
    <plain-table
        ref="table"
        stream="users"
        :provider="provider"
        :dims="dims"
        :vals="vals"
        :pagination="true"
        :throttled="false"
        :initialSort="[2]"
        @change="handleTableChange"
        @action="handleTableAction"
        />
    <my-dialog title="User" v-if="showUserDialog" @close="showUserDialog = false" :large="true">
        <div class="form-group">
            <label><l10n value="ID"/></label>
            <input class="form-control" v-model="user.id"/>
        </div>
        <div class="form-group">
            <label><l10n value="Name"/></label>
            <input class="form-control" v-model="user.name"/>
        </div>
        <div class="form-group">
            <label><l10n value="E-Mail"/></label>
            <input class="form-control" v-model="user.email"/>
        </div>
        <div class="form-group">
            <label><l10n value="Groups"/></label>
            <gp-pills v-model="user.groups" :options="accessGroups"/>
            <!-- <input class="form-control" v-model="user.groups"/> -->
        </div>
        <div class="form-group">
            <label><l10n value="Access"/></label>
            <gp-pills v-model="user.access" :options="categories"/>
        </div>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="createUser(user); showUserDialog = false"><l10n value="Submit"/></button>
            <button class="btn btn-sm btn-secondary" @click="showUserDialog = false"><l10n value="Cancel"/></button>
        </template>
    </my-dialog>
</div>
</template>
<script>
let utils = require('../my-utils')
let FuzzySearch = require("fuzzy-search").default
module.exports = {
    props: {
        accessGroups: { type: Array, default: () => [{name: "admin"}] }
    },
    data() {
        return {
            l10n: utils.l10n,
            searchString: "",
            user: {},
            users: [],
            showUserDialog: false,
            classifReport: null,
            selectedUsers: {},
        }
    },
    mounted() {
        this.loadUsers()
    },
    computed: {
        usersWithMeta() {
            return this.users.map(user => _.assign({}, user, {
                categories: this.parseAccess(user),
                groups: user.groups.map(group =>
                    this.accessGroups.find(({name}) => name == group) || ({name:group}))
            }))
        },
        usersSearch() {
            return new FuzzySearch(this.usersWithMeta, ["id", "name", "email", "groups.name", "categories"], { caseSensitive: false, sort: true })
        },
        visibleUsers() {
            return (this.searchString ? this.usersSearch.search(this.searchString) : this.usersWithMeta)
                .filter(user => !["oleg", "maxim", "ag"].includes(user.id) || app.username == user.id)
        },
        selectedUsersCount() {
            let selectedUsersCount = 0
            for (let user of this.visibleUsers)
                if (this.selectedUsers[user.id])
                    selectedUsersCount += 1
            return selectedUsersCount
        },
        cat2cls() {
            return this.classifReport ?
                _(this.classifReport.rows)
                    .sortBy("0")
                    .groupBy("0")
                    .toPairs()
                    .map(([category, group]) => [category, _.map(group, "1")])
                    .fromPairs()
                    .value()
                : {}
        },
        cls2cat() {
            return this.classifReport ?
                _(this.classifReport.rows)
                    .sortBy("1")
                    .map(([category, class_]) => [class_, category])
                    .fromPairs()
                    .value()
                : {}
        },
        categories() {
            return _(this.classifReport?.rows)
                .map("0")
                .uniq()
                .filter()
                .sortBy()
                .map(name => ({name}))
                .value()
        },
        dims() {
            return [{
                name: " ",
                type: "check",
            }, {
                name: "ID",
            }]
        },
        vals() {
            return [
                { name: "Name" },
                { name: "E-Mail" },
                { name: "Groups" },
                { name: "Access" },
                {
                    name: " ",
                    actionable: true,
                    actionicon: "edit"
                },

            ]
        },
        columns() {
            return _.concat(this.dims, this.vals)
        },
    },
    watch: {
        visibleUsers() {
            this.$refs.table.requestData()
        },
        selectedUsers() {
            this.$refs.table.requestData()
        }
    },
    methods: {
        provider({exportToFile}, {take,skip,sort}) {
            if (exportToFile) {
                let rows = _(this.visibleUsers)
                    .map(user => [
                        user.id,
                        user.name,
                        user.email,
                        user.groups.map(({name}) => name).join("; "),
                        this.parseAccess(user).join("; "),
                        // utils.l10n("modify"),
                    ])
                    .orderBy(
                        sort.map(x => Math.abs(x)-1),
                        sort.map(x => x < 0 ? "desc" : "asc"))
                    .value()

                let header = this.columns.slice(1, this.columns.length-1).map(({name}) => utils.l10n(name))

                let text = `${
                    _([header])
                        .concat(rows)
                        .map(row => row.map(cell => `"${`${cell}`.replace('"', '""')}"`).join(","))
                        .join("\r\n")}\r\n`

                let blob = new Blob([
                    new Uint8Array([0xEF,0xBB,0xBF]),
                    new TextEncoder("utf-8").encode(text)],
                    { type: 'text/csv' })

                let url = URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = "users.csv"
                a.click()
                URL.revokeObjectURL(url)
            }

            let size = this.visibleUsers.length
            let rows = _(this.visibleUsers)
                .map(user => [
                    this.selectedUsers[user.id],
                    user.id,
                    user.name,
                    user.email,
                    user.groups.map(({name}) => name).join("; "),
                    this.parseAccess(user).join("; "),
                    // utils.l10n("modify"),
                ])
                .orderBy(
                    sort.map(x => Math.abs(x)-1),
                    sort.map(x => x < 0 ? "desc" : "asc"))
                .drop(skip)
                .take(take)
                .value()

            for (let row of rows)
                row.__cache = {}

            let data = {
                dataset: {
                    source: {
                        report: {
                            size,
                            rows,
                            stats: {},
                            totals: {},
                            columns: this.columns,
                        }
                    }
                }
            }
            let meta = {
                stream: "users",
                columns: this.columns,
                dims: this.dims,
                vals: this.vals,
                cols: [],
                args: {take,skip},
            }
            return {data, meta}
        },
        async resetPasswordForSelectedUsers() {
            let users = []
            for (let user of this.visibleUsers)
                if (this.selectedUsers[user.id])
                    users.push(user)
            if (window.confirm(
                utils.l10n("Are you sure you want to reset passwords for the following users: {users}")
                    .replace("{users}", users.map(user => user.id).join(", "))))
            {
                for (let user of users) {
                    if (user.email) {
                        let formData = new FormData()
                        formData.append("email", user.email)
                        await fetch("/auth/reset_password", {
                            method: "POST",
                            body: formData
                        })
                    }
                }
                alert(utils.l10n("The new passwords have been sent to the selected users by email"))
            }
        },
        async deleteSelectedUsers() {
            let users = []
            for (let user of this.visibleUsers)
                if (this.selectedUsers[user.id])
                    users.push(user)
            if (window.confirm(
                utils.l10n("Are you sure you want to delete the following users: {users}")
                    .replace("{users}", users.map(user => user.id).join(", "))))
            {
                for (let user of users) {
                    let query = `
                        mutation {
                            deleteUser(user:${utils.quote(user.id)})
                        }
                        `
                    await fetch("/graphql", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({query})
                    })
                }
            }
            this.loadUsers()
        },
        handleTableChange(e, {row}) {
            let id = row[1]
            if (e.target.checked)
                this.$set(this.selectedUsers, id, true)
            else
                this.$delete(this.selectedUsers, id)
        },
        handleTableAction(e, {row}) {
            let id = row[1]

            let user = this.visibleUsers.find(user => user.id == id)
            this.startModifyUser(user)
            // console.log("startModifyUser2", arguments)
        },
        startModifyUser(user) {
            user = _.clone(user)
            let categories = this.parseAccess(user)
            user.access = this.categories.filter(({name}) => categories.includes(name))
            delete user.categories
            this.user = user
            this.showUserDialog = true
        },
        async createUser(user) {
            user = _.clone(user)

            if (user.groups?.length)
                user.groups = user.groups.map(({name}) => name)
            else
                user.groups = []

            let categories = []
            if (user.access?.length) {
                let classes = []
                for (let category of user.access) {
                    categories.push(category.name)
                    for (let class_ of this.cat2cls[category.name] || [])
                        classes.push(class_)
                }
                user.access = [{
                    stream: "combined",
                    filter0: `class in ${utils.quote(classes)}`,
                    filter1: `class in ${utils.quote(classes)}`,
                }]
            }
            else
                user.access = []
            user.meta = JSON.stringify({categories})

            let knownUser = this.users.findIndex(({id}) => id == user.id)

            if (knownUser == -1)
                this.users.push(user)
            else
                this.users.splice(knownUser, 1, user)

            let query = `
                mutation {
                    createUser(user:${utils.quote(user)})
                }
                `
            await fetch("/graphql", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({query})
            })
            if (knownUser == -1) {
                if (user.email) {
                    let formData = new FormData()
                    formData.append("email", user.email)
                    await fetch("/auth/reset_password", {
                        method: "POST",
                        body: formData
                    })
                }
            }
            this.loadUsers()
        },
        async loadUsers() {
            let response = await fetch("/graphql", {
                method:"POST",
                body: JSON.stringify({
                    query: `
                    {
                        dataset {
                            users {
                                id
                                name
                                meta
                                email
                                groups
                                access {
                                    stream
                                    filter0
                                    filter1
                                    filter2
                                    canReset
                                    canWrite
                                }
                            }
                        }
                    }`
                })
            })
            this.users = (await response.json()).data.dataset.users
        },
        isCategoryManager(username) {
            return app.isCategoryManager(username)
        },
        parseAccess(user) {
            if (user.meta) {
                let meta = JSON.parse(user.meta)
                if (meta.categories)
                    return meta.categories
            }
            let access = user.access
            let categories = new Set()
            for (let entry of access) {
                if (entry.stream == "combined") {
                    let classes = this.parseFilter(entry.filter0)
                    for (let class_ of classes) {
                        categories.add(this.cls2cat[class_])
                    }
                }
            }
            return _.sortBy([...categories])
        },
        parseFilter(filter) {
            if (_.startsWith(filter, "class in ")) {
                return JSON.parse(filter.replace("class in ", ""))
            }
            else
                return []
        }
    }
}
</script>
<style>
.gp-users-tools {
    position: sticky;
    top: 36px;
    z-index: 2;
    background-color: white;
    padding: 15px 20px;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--gray);
}
.gp-users-tools .btn + .btn {
    margin-left: 10px;
}
.gp-users-tools input {
    margin-bottom: 10px;
}
.gp-users {
    font-size: 0.9em;
}
.gp-users td {
    border-top: 1px solid var(--gray)!important;
}
.gp-users td {
    white-space: nowrap;
}
.gp-users td:nth-child(6) {
    white-space: normal;
}
</style>