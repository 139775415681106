var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productcard-container" }, [
    _c("header", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "header__title-block" },
        [
          _c("button", {
            staticClass: "button-return",
            attrs: { type: "button" },
            on: { click: _vm.onBackBtnClick }
          }),
          _vm._v(" "),
          _c("p", { staticClass: "header-title" }, [
            _vm._v("Happy Horses (apt. HRSS707)")
          ]),
          _vm._v(" "),
          _c(
            "my-tooltip-simple",
            {
              scopedSlots: _vm._u([
                {
                  key: "tooltip-data",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "header-title-tooltip" }, [
                        _c("p", [_vm._v("ID карточки №001936")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Создано: 04/03/2023")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Автор: Tatyana I.")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("Последнее изменение: 06/10/2023")])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("button", {
                staticClass: "button-info",
                attrs: { type: "button" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header__search-form" },
        [_c("autoorder-search")],
        1
      )
    ]),
    _vm._v(" "),
    _c("main", { staticClass: "main" }, [
      _c("section", { staticClass: "main__section section" }, [
        _c("h3", { staticClass: "section__title" }, [
          _vm._v("Справочник товара")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            { staticClass: "inputblock__label", attrs: { for: "vendor-code" } },
            [_vm._v("\n          Артикул\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.vendorCode,
                expression: "vendorCode"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: { id: "vendor-code", placeholder: "Введите артикул товара" },
            domProps: { value: _vm.vendorCode },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.vendorCode = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "suppliers" } },
              [_vm._v("Поставщики")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                options: _vm.suppliers,
                selectedOption: _vm.selectedSuppliers,
                multiple: "",
                searchable: "",
                placeholder: "Выберите поставщиков"
              },
              on: { select: _vm.onSuppliersSelect }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              {
                staticClass: "inputblock__label",
                attrs: { for: "manufacturer" }
              },
              [_vm._v("Производитель")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                options: _vm.manufacturers,
                selectedOption: _vm.selectedManufacturer,
                searchable: ""
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "trademark" } },
              [_vm._v("Торговая марка")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                options: _vm.trademarks,
                selectedOption: _vm.selectedTrademark,
                searchable: ""
              },
              on: { select: _vm.onTrademarkSelect }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "main__section" }, [
        _c("h3", { staticClass: "section__title" }, [
          _vm._v("Атрибуты товара")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            {
              staticClass: "inputblock__label",
              attrs: { for: "expiration-date" }
            },
            [_vm._v("Срок годности, дн.")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.expirationDaysQuantity,
                expression: "expirationDaysQuantity"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: {
              id: "expiration-date",
              placeholder: "Укажите срок годности товара"
            },
            domProps: { value: _vm.expirationDaysQuantity },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.expirationDaysQuantity = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            { staticClass: "inputblock__label", attrs: { for: "volume" } },
            [_vm._v("Вес / Объём")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.volume,
                expression: "volume"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: {
              id: "volume",
              placeholder: "Укажите срок годности товара"
            },
            domProps: { value: _vm.volume },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.volume = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            {
              staticClass: "inputblock__label",
              attrs: { for: "package-capacity" }
            },
            [_vm._v("Вложение в короб")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.packageCapacity,
                expression: "packageCapacity"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: {
              id: "package-capacity",
              placeholder: "Укажите срок годности товара"
            },
            domProps: { value: _vm.packageCapacity },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.packageCapacity = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputblock" }, [
          _c(
            "label",
            { staticClass: "inputblock__label", attrs: { for: "analogs" } },
            [_vm._v("Аналоги")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.analogs,
                expression: "analogs"
              }
            ],
            staticClass: "inputblock__input input",
            attrs: { id: "analogs", placeholder: "Укажите аналоги" },
            domProps: { value: _vm.analogs },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.analogs = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputblock" },
          [
            _c(
              "label",
              { staticClass: "inputblock__label", attrs: { for: "category" } },
              [_vm._v("Категория")]
            ),
            _vm._v(" "),
            _c("my-multiselect", {
              attrs: {
                options: _vm.categories,
                selectedOption: _vm.selectedCategory,
                searchable: ""
              },
              on: { select: _vm.onCategorySelect }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "main__section" }, [
        _c(
          "div",
          { staticClass: "fileinput" },
          [
            !_vm.filePreview
              ? _c("div", { staticClass: "fileinput__stub" })
              : _vm._e(),
            _vm._v(" "),
            _vm.filePreview
              ? _c("div", { staticClass: "fileinput__image-container" }, [
                  _c("img", {
                    staticClass: "fileinput__image",
                    staticStyle: {
                      "max-height": "256px",
                      "margin-bottom": "5px",
                      "border-radius": "5px"
                    },
                    attrs: { src: _vm.filePreview, alt: "fileName" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("my-fileinput", {
              on: {
                "file-updated": function($event) {
                  return _vm.captureFile($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("footer", { staticClass: "footer" }, [
      _c(
        "div",
        { staticClass: "footer__checkbox" },
        [
          _c(
            "gp-check",
            {
              attrs: { checked: _vm.isActiveCard },
              on: { change: _vm.handleChange }
            },
            [
              _vm._v("\n          \n        "),
              _c("l10n", { attrs: { value: "Активная карточка" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__buttons-block" }, [
      _c(
        "button",
        { staticClass: "button button_primary", attrs: { type: "button" } },
        [_vm._v("\n        Дублировать\n      ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "button button_secondary", attrs: { type: "button" } },
        [_vm._v("\n        Сохранить\n      ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }