module.exports = _.merge({
    watch: {
        ordering:   function() { this.createChart() },
    },
    methods: {
        makeChart(base, instant) {
            let n = this.dims.length
            let m = this.vals.length

            let dimension = this.ndx.dimension((row) => row.slice(0, n))

            let group = dimension.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))


            let chart = dc.barChart(base)
            chart.dimension(dimension)

            for (let i = 0; i < this.vals.length; ++i) {
                let name = this.vals[i].name || this.vals[i].calc
                if (i == 0)
                    chart.group(group, name, (d) => d.value[i])
                else
                    chart.stack(group, name, (d) => d.value[i])
            }

            return chart

            /*let n = this.dims.length
            let groups = this.cols.length > 0 ? this.cols.slice(n) : this.vals
            let m = groups.length

            let dim = this.ndx.dimension((row) => row.slice(0,n))

            let grp = dim.group().reduce(
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] += v[n+i] }; return p },
                (p,v) => { for (let i = 0; i < m; ++i) { p[i] -= v[n+i] }; return p },
                () => _.fill(new Array(m), 0))

            let chart = dc.barChart(base)

            chart.dimension(dim)

            console.log("groups", groups)

            _(groups)
                .forEach((group, i) => {
                    if (_.isPlainObject(group) && group.hide)
                        return
                    let name = _.isString(group) ? group : group.name
                    if (i === 0)
                        chart.group(grp, name, (d) => d.value[i])
                    else
                        chart.stack(grp, name, (d) => d.value[i])
                })

            return chart*/
        },
    },
}, require("./props").chartProps({
    barPadding:     { type: Number, default: 0.5 },
    outerPadding:   { type: Number, default: 0.8 },
    ordering:       { type: [Function, String], default: (d) => d.key[0] },
    keyAccessor:    { type: [Function, String], default: (d) => d.key.length == 1 ? d.key[0] : d.key.join("\n") },
}))
