<template>
	<tr
        tabindex="0"
        :data-row="i"
        :class="rowClass"
        :style="rowStyle"
        >
        <template v-for="section in sections" v-if="editable">
	        <template v-for="column, j in section.visibleColumns">
	            <plain-table-cell-static
	            	v-if="editableColumn !== column.i"
	                :key="column.id || j"
	                :i="i"
	                :meta="meta"
	                :row="row"
	                :column="column"
	                :section="section"
	                />
	            <plain-table-cell-editable
	            	v-else
	                :key="column.id || j"
	                :i="i"
	                :meta="meta"
	                :row="row"
	                :rows="rows"
	                :column="column"
	                :section="section"
	                :editing="true"
                    @requestReactionToConsent="runRequestReactionToConsent"
                    @requestOutputPriority="runRequestOutputPriority"
	                />
	    	</template>
        </template>
    </tr>
</template>
<script>
let utils = require('./my-utils')

module.exports = {
    props: {
        i:          { type: Number,  default: -1 },
        row:        { type: Array },
        rows:       { type: Array },
        meta:       { type: Object },
        primary:    { type: Boolean },
        selected:   { type: Boolean },
        editable:   { type: Boolean },
        subTotal:   { type: Boolean },
        sections:   { type: Array },
        editableColumn: { type: Number },
    },
    methods: {
        runRequestReactionToConsent(data) {
            this.$emit('requestReactionToConsent', data);
        },

        runRequestOutputPriority(data) {
            this.$emit('requestOutputPriority', data);
        },
        
        makeInfo(column) {
            return {
                row: this.row,
                column: this.resolveColumn(column),
                meta: this.meta,
            }
        },
        resolveColumn(i) {
            for (let section of this.sections)
                for (let column of section.visibleColumns)
                    if (column.i === i)
                        return column
            return null
        },
    },
    computed: {
        rowClass() {
            let classes = {
                primary: this.primary,
                selected: this.selected,
                editable: this.editable,
                "sub-total": this.subTotal,
            }
            for (let section of this.sections) {
                for (let column of section.columns) {
                    if (column.rowClass) {
                        let class_ = column.rowClass(
                        	this.row[column.i],
                        	this.row,
                        	column,
                        	this.meta)
                        if (class_)
                            classes[class_] = true
                    }
                }
            }
            return classes
        },
        rowStyle() {
            let style = {}
            for (let section of this.sections)
                for (let column of section.columns)
                    if (column.rowStyle)
                        _.assign(style,
                            column.rowStyle(
                                this.row[column.i],
                                this.row,
                                column,
                                this.meta))
            if (this.row.level !== undefined)
              style["--level"] = `${this.row.level}`
            return style
        },
    },
}
</script>
<style>
</style>
