<template>
  <div class="accordions-container">
    <my-accordion
      openedByDefault
      @accordionOpened="handleAccordionOpened('accordion-1')"
      ref="accordion-1"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Информация по заказу" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Номер заказа:</b> 000646 <br />
          <b>Отправитель:</b> Little Freddie (арт LTT001)&nbsp;   <b>Получатель:</b> LWLOO(id: 17) <br />
          <b>Текущая дата:</b> 27.03.2023<br />
          <b>Дата формирования:</b> 27.03.2023<br />
          <b>Дата отправки:</b> 27.03.2023&nbsp; <b>Дата обработки:</b> 27.03.2023&nbsp; <b>Дата отгрузки:</b> 29.03.2023&nbsp; <b>Дата прихода:</b> 29.03.2023<br />
          <b>Время приема заявок:</b> 10:00<br />
          <b>Статус согласования:</b> <span style="color: orange">на проверке</span>&nbsp; <b>Статус отправки:</b> <span style="color: orange">не отправлен</span>
        </div>
      </template>
    </my-accordion>
    <my-accordion
      @accordionOpened="handleAccordionOpened('accordion-2')"
      ref="accordion-2"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Ограничения по заказам по цепочке поставщика" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Период действия:</b> <span style="color: orange">31.12.2099</span>
          <b>Вид цепочки:</b> Прямая поставка <br />
          <b>Склады отгрузки:</b> Склад № 6 <br />
          <b>Ассортиментные наборы:</b> Набор средств для волос <br />
          <b>Время обработки заявок:</b> 2 дня <br />
          <b>Минимальные границы:</b>30 000 рублей&nbsp;&nbsp;&nbsp; <b>Максимальные границы:</b> - <br />
          <b>Склады получатели:</b>  LWLOO(id: 17) <br />
          <b>Срок поставки:</b>  29.03.2023 <br />
          <b>Дата создания цепочки поставки:</b> 1.1.2022 <br />
        </div>
      </template>
    </my-accordion>
    <my-accordion
      @accordionOpened="handleAccordionOpened('accordion-3')"
      ref="accordion-3"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n value="Информация по контрагенту" />
        </span>
      </template>
      <template v-slot:content>
        <div style="font-size: 0.75rem; padding: 5px">
          <b>Наименование контрагента:</b> ООО "Контрагент" <br />
          <b>Id карточки:</b> 678910324 <br />
          <b>Артикул:</b> ABC12345 <br />
          <b>ИНН:</b> 1234567890 <br />
          <b>Телефон:</b>+7 996 636 0303&nbsp;&nbsp;&nbsp; <b>Телеграм:</b> @admin
          <b>Договоры:</b> Договор №68 от 12.04.2023 <br />
          <b>Склады отгрузки:</b> Склад № 6 <br />
          <b>Контактное лицо:</b> Иванов Иван Иванович <br />
          <b>Адрес приема заказов:</b> г. Москва, ул. Селигерская, д. 15, лит. А <br />
          <b>График приема заказов:</b> <span style="color: orange">понедельник - пятница 9:00 - 18: 00</span>&nbsp; <b>Время отправки заказов (до):</b> <span style="color: orange">до 17:00</span>
        </div>
      </template>
    </my-accordion>
  </div>
</template>

<script>
const MyAccordion = require('../my-accordion.vue').default;

module.exports = {
  components: {
    'my-accordion': MyAccordion,
  },

  methods: {
    handleAccordionOpened(accordionRef) {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('accordion') && ref !== accordionRef) {
          this.$refs[ref].closeAccordion();
        }
      });
    },
  },
};
</script>

<style scoped>
.accordions-container {
  width: 49.5%;
}
.accordion-title {
  margin-left: 0.75rem;
  font-size: 14px;
  font-weight: 500;
}
</style>
