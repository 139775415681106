<template>
  <my-multiselect
    class="input-search"
    v-model="selectedValue"
    :options="options"
    :loading="isLoading"
    :internal-search="false"
    :close-on-select="true"
    :options-limit="100"
    placeholder="Введите название продукта"
    searchable
    @search-change="asyncFind"
  />
</template>

<script>
const MyMultiSelect = require('../my-multiselect.vue').default;
const getProductsSearch = require('../api/productsSearch');

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
  },

  data() {
    return {
      options: [],
      selectedValue: '',
      isLoading: false,
    };
  },

  methods: {
    asyncFind(query) {
      if (query.length >= 3) {
        this.isLoading = true;
        getProductsSearch(query)
          .then((response) => {
            this.options = response;
            this.isLoading = false;
          })
          .catch(() => {
            console.log('error');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
  .input-search {
    display: flex;
    align-items: center;
    width: 438px;
    min-width: 350px;
    height: 46px;
    margin: 0;
    padding: 0px 8px 0px 12px;
    padding-left: 42px;
    border: none;
    border-radius: 8px;
    background: #f7f7f7 url('./search-icon.svg' ) no-repeat 12px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
  .input-search:hover {
    background-color: #E9E9E9;
  }
  .input-search:focus {
    border-color: #5295D5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
    box-shadow: 0 0 0 0.2rem #5295D5;
  }
</style>
