<template>
  <div class="supply-chains-container">
    <p class="metaData"><span>SelfStorage001</span> SelfStorage001 (id:4) -> LWLOO (id:17)</p>
    <h2>
      <span style="display: none;">Heading</span>
      <l10n value="Параметры цепочки" />
    </h2>
    <gp-check
      :checked="activeChain"
      @change="handleChange"
    >
      <l10n value="Активная цепочка" />
    </gp-check>

    <div class="chain-type parameter">
      <p class="title">
        <l10n value="Период действия" /></p>
      <span>
        <inline-input type="date" v-model="period.startDate" />
        &nbsp; - &nbsp;
        <inline-input type="date" v-model="period.endDate" />
      </span>
    </div>

    <div class="chain-type parameter">
      <p class="title">
        <l10n value="Вид цепочки" />
      </p>
      <select v-model="chainType" class="form-control form-control-sm">
        <option value="in" selected><l10n value="Прямая поставка"/></option>
        <option value="eq"><l10n value="РЦ хранение"/></option>
        <option value="ne"><l10n value="PBS"/></option>
        <option value="fd"><l10n value="КДК"/></option>
      </select>
    </div>

    <gp-check
      :checked="promoChain"
      :disabled="isPromoChainDisabled"
      @change="handleChange"
    >
      <l10n value="Промо цепочка" />
    </gp-check>
    <br />
    <h2>
      <span style="display: none;">Heading</span>
      <l10n value="Параметры отправителя" />
    </h2>
    <div class="chain-type stock-select parameter">
      <p class="title">
        <l10n value="Склады отгрузки" />
      </p>
      <input
        class="form-control form-control-sm"
        value="LF001"
        placeholder="Выберите склад"
      >
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn"
        @click="onStockSelect"
      >
        Склады >>>
      </button>
    </div>
    <div class="chain-type stock-select parameter">
      <p class="title">
        <l10n value="Ассортиментные наборы" />
      </p>
      <input
        class="form-control form-control-sm"
        value="FreshSTG-SelfSummer007-bond"
        placeholder="Выберите ассортимент"
      >
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn" 
        @click="onStockSelect"
      >
        Ассортимент >>>
      </button>
    </div>
    <div class="time-processed-container">
      <div class="chain-type parameter time-select-container" style="margin-right: 10px;">
        <p class="title">
          <l10n value="Время приема заявок" />
        </p>
        <select v-model="timeIn" class="form-control form-control-sm time-select">
          <option value="in"><l10n value="00.00 - 04.00"/></option>
          <option value="eq"><l10n value="04.00 - 08.00"/></option>
          <option value="ne"><l10n value="08.00 - 12.00"/></option>
          <option value="ne"><l10n value="12.00 - 16.00"/></option>
          <option value="ne"><l10n value="16.00 - 20.00"/></option>
          <option value="ne"><l10n value="20.00 - 00.00"/></option>
        </select>
      </div>
      <gp-check
        :checked="noLimitations"
        @change="handleChange"
      >
        <l10n value="Без ограничений" />
      </gp-check>
    </div>
    <div class="chain-type parameter time-select-container">
      <p class="title">
        <l10n value="Время обработки заявок" />
      </p>
      <select v-model="timeProcessed" class="form-control form-control-sm time-select">
        <option value="in"><l10n value="00.00 - 04.00"/></option>
        <option value="eq"><l10n value="04.00 - 08.00"/></option>
        <option value="ne"><l10n value="08.00 - 12.00"/></option>
        <option value="ne"><l10n value="12.00 - 16.00"/></option>
        <option value="ne"><l10n value="16.00 - 20.00"/></option>
        <option value="ne"><l10n value="20.00 - 00.00"/></option>
      </select>
    </div>
    <div class="" style="display: flex;">
      <p class="title" style="width: 216px">
        <l10n value="Минимальные границы" />
      </p>
      <span>Ед.</span>
      <select v-model="minCurrency_1" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value="100,000"
      >
      <span>ИЛИ Ед.</span>
      <select v-model="minCurrency_2" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value=""
      >
      <span>ИЛИ Ед.</span>
      <select v-model="minCurrency_1" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value=""
      >
      <gp-check
        :checked="noLimitationsMinimalBoundaries"
        :disabled="noLimitationsMinimalBoundariesDisabled"
        @change="handleChange"
      >
        <l10n value="Без ограничений" />
      </gp-check>
    </div>
    <div class="" style="display: flex;">
      <p class="title" style="width: 216px">
        <l10n value="Максимальные границы" />
      </p>
      <span>Ед.</span>
      <select v-model="maxCurrency_1" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value="100,000"
      >
      <span>ИЛИ Ед.</span>
      <select v-model="maxCurrency_2" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value=""
      >
      <span>ИЛИ Ед.</span>
      <select v-model="maxCurrency_3" class="form-control form-control-sm input-sm">
        <option value="in" selected><l10n value="Руб."/></option>
        <option value="eq"><l10n value="Шт."/></option>
        <option value="ne"><l10n value="Кг."/></option>
      </select>
      <span>Сумма</span>
      <input
        class="form-control form-control-sm input-sm"
        value=""
      >
      <gp-check
        :checked="noLimitationsMaximalBoundaries"
        @change="handleChange"
      >
        <l10n value="Без ограничений" />
      </gp-check>
    </div>
    <br />
    <h2>
      <span style="display: none;">Параметры получателя</span>
      <l10n value="Параметры получателя" />
    </h2>
    <div class="chain-type stock-select parameter">
      <p class="title">
        <l10n value="Склады получатели" />
      </p>
      <input
        class="form-control form-control-sm"
        value="LWLOO (id:17)"
        placeholder="Выберите склад"
      >
      <button
        type="button"
        class="btn btn-sm btn-primary select-btn"
        @click="onStockSelect"
      >
        Склады >>>
      </button>
    </div>
    <div class="chain-type time-select-container parameter">
      <p class="title">
        <l10n value="Срок поставки" />
      </p>
      <select v-model="timeDelivery" class="form-control form-control-sm time-select">
        <option value="in"><l10n value="1 день"/></option>
        <option value="eq"><l10n value="2 дня"/></option>
        <option value="ne"><l10n value="3 дня"/></option>
        <option value="fd"><l10n value="4 дня"/></option>
        <option value="ij"><l10n value="5 дней"/></option>
        <option value="df"><l10n value="6 дней"/></option>
      </select>
    </div>
    <div class="footer">
      <p class="metaData">Создано 04/02/2021 &nbsp; Автор: Oleg N. &nbsp;&nbsp; Последнее изменение: 07/02/2021</p>
      <div class="button-block">
        <button
          type="button"
          class="btn btn-sm btn-primary select-btn"
          style="margin-right: 5px;"
          @click="onStockSelect"
        >
          Дублировать
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary select-btn"
          @click="onStockSelect"
        >
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const MySelect = require('../my-select.vue').default;

module.exports = {
  components: {
    'my-select': MySelect,
  },

  props: {

  },

  data() {
    return {
      activeChain: true,
      promoChain: false,
      isPromoChainDisabled: false,
      chainType: 'in',
      chainTypeOptions: [],
      period: {
        startDate: '2023-01-01',
        endDate: '2099-12-31',
      },
      timeIn: null,
      timeProcessed: 'in',
      noLimitations: true,
      noLimitationsMinimalBoundaries: false,
      noLimitationsMinimalBoundariesDisabled: false,
      noLimitationsMaximalBoundaries: true,
      minCurrency_1: 'in',
      minCurrency_2: 'eq',
      minCurrency_3: 'ne',
      minTotal_1: null,
      minTotal_2: null,
      minTotal_3: null,
      maxCurrency_1: 'in',
      maxCurrency_2: 'eq',
      maxCurrency_3: 'ne',
      maxTotal_1: null,
      maxTotal_2: null,
      maxTotal_3: null,
      timeDelivery: null,
    };
  },

  methods: {
    handleChange() {
      console.log('changed');
    },

    onStockSelect() {
      console.log('select stock');
    }
  },
};
</script>

<style scoped>
.supply-chains-container {
  padding: 0.65rem;
  font-weight: 600;
}
.supply-chains-container h2 {
  font-weight: 600;
}
.parameter {
  display: flex;
  justify-content: space-between;
}
.chain-type {
  width: 450px;
}
.chain-type.time-select-container {
  width: 350px;
}
.chain-type.stock-select {
  width: 560px;
}
.chain-type .title {
  width: 250px;
}
.title {
  color: var(--cyan);
}
.select-btn {
  width: 200px;
  height: 29px;
  background-color: var(--cyan);
  border: none;
}
.stock-select input {
  width: 350px;
  margin-right: 10px;
}
.time-select {
  width: 150px;
}
.time-processed-container {
  display: flex;
}
.input-sm {
  width: 70px;
  margin-right: 5px;
}
.metaData {
  font-style: italic;
  color: #dedede;
}
.metaData span {
  font-style: normal;
  color: var(--cyan);
}
.footer {
  display: flex;
  justify-content: space-between;
}
.button-block .select-btn {
  width: 160px;
}
</style>
