var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-multiselect", {
        attrs: {
          options: _vm.options,
          searchable: _vm.searchable,
          "close-on-select": true,
          "show-labels": true,
          placeholder: _vm.placeholder10n,
          multiple: _vm.multiple,
          limit: 3,
          "hide-selected": true,
          noResult: "Ничего не найдено. Измените поисковый запрос",
          noOptions: "Список значений пуст"
        },
        on: { select: _vm.onSelect },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }