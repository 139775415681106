var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plain-table",
      style: {
        display: _vm.display,
        "--head-height": _vm.tableHeadHeight + "px",
        "--head-first-row-height": _vm.tableHeadFirstRowHeight + "px",
        "--head-last-row-height": _vm.tableHeadLastRowHeight + "px"
      }
    },
    [
      _c(
        "div",
        { staticClass: "plain-table-head" },
        [
          _c("my-errors", {
            attrs: { errors: _vm.errors },
            on: { retry: _vm.requestData }
          }),
          _vm._v(" "),
          _c("my-caption", { attrs: { caption: _vm.caption } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "my-filters" },
            _vm._l(_vm.filters, function(filter, i) {
              return filter.show !== false
                ? _c(
                    "a",
                    {
                      class: _vm.filterClass(filter),
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.filters.splice(i, 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(filter.name) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { ref: "style" }),
          _vm._v(" "),
          _vm.pagination
            ? _c(
                "div",
                {
                  staticClass: "plain-table-paginator",
                  attrs: { tabindex: "0" },
                  on: { keydown: _vm.handlePaginatorKeyDown }
                },
                [
                  _c(
                    "a",
                    {
                      class: { disabled: _vm.page === 0 },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.setDesiredPage(_vm.page - 10)
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "chevrons-left" } })],
                    1
                  ),
                  _c(
                    "a",
                    {
                      class: { disabled: _vm.page === 0 },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.setDesiredPage(_vm.page - 1)
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "chevron-left" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.promptPage }
                    },
                    [
                      _c("l10n", {
                        attrs: {
                          value: "page {page}",
                          page: new Number(_vm.page + 1).toLocaleString()
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { disabled: _vm.page === _vm.pageCount - 1 },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.setDesiredPage(_vm.page + 1)
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "chevron-right" } })],
                    1
                  ),
                  _c(
                    "a",
                    {
                      class: { disabled: _vm.page === _vm.pageCount - 1 },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.setDesiredPage(_vm.page + 10)
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "chevrons-right" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("l10n", {
                    staticClass: "nowrap",
                    attrs: {
                      rows: new Number(_vm.size).toLocaleString(),
                      value: "{rows} rows,"
                    }
                  }),
                  _vm._v(" "),
                  _c("l10n", {
                    staticClass: "nowrap",
                    attrs: {
                      pages: new Number(_vm.pageCount).toLocaleString(),
                      value: "{pages} pages,"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.promptPageSize }
                    },
                    [
                      _c("l10n", {
                        staticClass: "nowrap",
                        attrs: {
                          "page-size": new Number(
                            _vm.pageSize
                          ).toLocaleString(),
                          value: "{page-size} rows per page"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("inline-help", {
                    attrs: {
                      text:
                        "Click and use use <Left> and <Right> arrow keys for pagination. Hold <Shift> for fast forward.",
                      html: true
                    }
                  })
                ],
                1
              )
            : _vm.overscroll
            ? _c(
                "div",
                { staticClass: "plain-table-paginator" },
                [
                  !_vm.report
                    ? _c("l10n", { attrs: { value: "Loading..." } })
                    : _c("l10n", {
                        attrs: {
                          rows: new Number(_vm.size).toLocaleString(),
                          value: "{rows} rows"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "plain-table-table-actions" },
            [
              _vm.showTableActions
                ? _c(
                    "my-popup",
                    {
                      attrs: {
                        placement: "bottom-end",
                        anchor: _vm.$refs.showTableActions
                      },
                      on: {
                        escape: function($event) {
                          _vm.showTableActions = false
                        },
                        clickoutside: function($event) {
                          _vm.showTableActions = false
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "popover show plain-table-manage-table"
                        },
                        [
                          _c("div", { staticClass: "popover-body" }, [
                            _c(
                              "ul",
                              _vm._l(_vm.tableActionsEx(), function(action) {
                                return action.menu !== false
                                  ? _c(
                                      "li",
                                      [
                                        action.tagname
                                          ? _c("RenderProxy", {
                                              attrs: {
                                                tagname: action.tagname,
                                                config: action.config
                                              }
                                            })
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.callTableAction(
                                                      action,
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: { name: action.icon }
                                                }),
                                                _vm._v(" "),
                                                _c("l10n", {
                                                  attrs: { value: action.text }
                                                })
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }),
                              0
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addShopButton
                ? [
                    _c(
                      "my-button",
                      {
                        on: { "mybutton-click": _vm.handleAddShopButtonClick }
                      },
                      [_c("l10n", { attrs: { value: "Add shop" } })],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.evaluateButtons
                ? _vm._l(_vm.evaluateButtons, function(evaluateButton, i) {
                    return _c(
                      "my-button",
                      {
                        attrs: {
                          isLoading: _vm.isEvaluateBtnsLoading[i],
                          evaluateButton: evaluateButton
                        },
                        on: { "mybutton-click": _vm.handleEvaluateButtonClick }
                      },
                      [
                        _c("l10n", {
                          attrs: {
                            value: evaluateButton.value
                              ? evaluateButton.value
                              : "Evaluate"
                          }
                        })
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tableActionsEx(), function(action) {
                return action.menu === false
                  ? [
                      action.tagname
                        ? _c("RenderProxy", {
                            attrs: {
                              tagname: action.tagname,
                              config: action.config
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#", title: _vm.l10n(action.text) },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.callTableAction(action, $event)
                            }
                          }
                        },
                        [_c("feather-icon", { attrs: { name: action.icon } })],
                        1
                      )
                    ]
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.tableActionsEx().length == 0
                ? _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.exportToFile()
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "download" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.toogleFullscreen }
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      name: _vm.inFullscreenMode ? "minimize" : "maximize"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.tableActionsEx().length === 0 || _vm.tableActionsButtonEnabled
                ? _c(
                    "a",
                    {
                      ref: "showTableActions",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          _vm.showTableActions = true
                        }
                      }
                    },
                    [_c("feather-icon", { attrs: { name: "more-vertical" } })],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("my-progress", {
            key: 1,
            attrs: {
              working: _vm.working,
              sorting: _vm.sorting,
              progress: _vm.progress,
              downloading: _vm.downloading,
              processRows: _vm.processRows,
              processTime: _vm.processTime,
              meta: _vm.meta,
              refreshData: _vm.refreshData,
              assembly: _vm.assembly
            }
          }),
          _vm._v(" "),
          _c("my-download", { attrs: { file: _vm.file } }),
          _vm._v(" "),
          _c("my-tooltip", {
            ref: "tooltip",
            attrs: { meta: null, keepOnly: _vm.keepOnly, exclude: _vm.exclude }
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { clear: "both" } }),
          _vm._v(" "),
          _vm.pagination && _vm.size > 0
            ? _c(
                "div",
                {
                  ref: "slider",
                  staticClass: "plain-table-slider",
                  on: { click: _vm.handlePageSliderClick }
                },
                [
                  _c("div", { staticClass: "plain-table-slider-background" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "plain-table-slider-prefetch-window",
                    style: {
                      left: (_vm.skip / _vm.size) * 100 + "%",
                      right:
                        (1 - Math.min(1, (_vm.skip + _vm.take) / _vm.size)) *
                          100 +
                        "%"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "plain-table-slider-visible-window",
                    style: {
                      left: ((_vm.page * _vm.pageSize) / _vm.size) * 100 + "%",
                      right:
                        (1 -
                          Math.min(
                            1,
                            ((_vm.page + 1) * _vm.pageSize) / _vm.size
                          )) *
                          100 +
                        "%"
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedSection !== null
            ? _c(
                "my-popup",
                {
                  key: "section-popup-" + _vm.selectedSection,
                  attrs: {
                    draggable: true,
                    placement: "bottom-start",
                    anchor: _vm.resolveSelectedSection
                  },
                  on: {
                    escape: function($event) {
                      _vm.selectedSection = null
                    },
                    clickoutside: function($event) {
                      _vm.selectedSection = null
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "popover show plain-table-manage-section" },
                    [
                      _c("div", { staticClass: "popover-body" }, [
                        _c(
                          "ul",
                          [
                            _vm._l(
                              [_vm.sections[_vm.selectedSection]],
                              function(section) {
                                return _vm._l(
                                  _vm.sectionActionsEx(section),
                                  function(action) {
                                    return _c(
                                      "li",
                                      [
                                        action.tagname
                                          ? _c("RenderProxy", {
                                              attrs: {
                                                tagname: action.tagname,
                                                section: section,
                                                meta: _vm.meta,
                                                config: action.config
                                              }
                                            })
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.callSectionAction(
                                                      section,
                                                      action
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: { name: action.icon }
                                                }),
                                                _vm._v(" "),
                                                _c("l10n", {
                                                  attrs: { value: action.text }
                                                })
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  }
                                )
                              }
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedColumn !== null
            ? _c(
                "my-popup",
                {
                  key: "column-popup-" + _vm.selectedColumn,
                  attrs: { draggable: true, anchor: _vm.resolveSelectedColumn },
                  on: {
                    escape: function($event) {
                      _vm.selectedColumn = null
                    },
                    clickoutside: function($event) {
                      _vm.selectedColumn = null
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "popover show plain-table-manage-column" },
                    [
                      _c("div", { staticClass: "popover-body" }, [
                        _c(
                          "ul",
                          [
                            _vm.desiredSort.length > 1 ||
                            (_vm.desiredSort.length === 1 &&
                              _vm.sortingLevel(_vm.selectedColumn) !== 0)
                              ? _c(
                                  "li",
                                  { key: "sort-help" },
                                  [
                                    _c("l10n", {
                                      attrs: {
                                        value:
                                          "Hold shift for secondary sorting"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.sortedAscending(_vm.selectedColumn)
                              ? _c("li", { key: "sort-asc" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.sortAscending(
                                            $event,
                                            _vm.selectedColumn
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "arrow-down" }
                                      }),
                                      _vm._v(" "),
                                      _c("l10n", {
                                        attrs: { value: "Sort ascending" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.sortedDescending(_vm.selectedColumn)
                              ? _c("li", { key: "sort-desc" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.sortDescending(
                                            $event,
                                            _vm.selectedColumn
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "arrow-up" }
                                      }),
                                      _vm._v(" "),
                                      _c("l10n", {
                                        attrs: { value: "Sort descending" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sortedAscending(_vm.selectedColumn) ||
                            _vm.sortedDescending(_vm.selectedColumn)
                              ? _c("li", { key: "sort-none" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeSorting(
                                            $event,
                                            _vm.selectedColumn
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "x" }
                                      }),
                                      _vm._v(" "),
                                      _c("l10n", {
                                        attrs: { value: "Remove sorting" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              { key: "column-filter" },
                              [
                                _vm.showColumnFilters
                                  ? _c("plain-table-column-filter", {
                                      attrs: {
                                        stream: _vm.stream,
                                        groups: ["columnFilter"],
                                        source: _vm.source,
                                        vars: _vm.vars,
                                        dims: [
                                          _vm.columns[_vm.selectedColumn].calc
                                        ],
                                        sort: [1],
                                        type: [
                                          "docid",
                                          "int8",
                                          "int16",
                                          "int32",
                                          "int64",
                                          "float",
                                          "double"
                                        ].includes(
                                          _vm.columns[_vm.selectedColumn].type
                                        )
                                          ? "number"
                                          : "text",
                                        format:
                                          _vm.columns[_vm.selectedColumn].format
                                      },
                                      on: { change: _vm.columnFilterChanged },
                                      model: {
                                        value:
                                          _vm.columnFilter[_vm.selectedColumn],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.columnFilter,
                                            _vm.selectedColumn,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "columnFilter[selectedColumn]"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.sections, function(section) {
                              return [
                                _vm._l(section.columns, function(column) {
                                  return column.i == _vm.selectedColumn
                                    ? _vm._l(
                                        _vm.columnActionsEx(column),
                                        function(action, i) {
                                          return _c(
                                            "li",
                                            {
                                              key: action.key || "action-" + i
                                            },
                                            [
                                              action.tagname
                                                ? _c("RenderProxy", {
                                                    attrs: {
                                                      tagname: action.tagname,
                                                      column: column,
                                                      meta: _vm.meta,
                                                      config: action.config
                                                    }
                                                  })
                                                : _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.callColumnAction(
                                                            column,
                                                            action
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("feather-icon", {
                                                        attrs: {
                                                          name: action.icon
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("l10n", {
                                                        attrs: {
                                                          value: action.text
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    : _vm._e()
                                })
                              ]
                            }),
                            _vm._v(" "),
                            _vm.hasFilters(_vm.selectedColumn)
                              ? _c("li", { key: "clear-filters" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFilter(
                                            $event,
                                            _vm.selectedColumn
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "filter" }
                                      }),
                                      _vm._v(" "),
                                      _c("l10n", {
                                        attrs: { value: "Clear column filters" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.mode == "tiles"
        ? _c(
            "div",
            {
              ref: "tiles",
              staticClass: "plain-table-tiles",
              style: { opacity: _vm.pending ? 0.5 : 1 },
              on: {
                click: _vm.handleTableClick,
                change: _vm.handleTableChange,
                keydown: _vm.handleTableKeyDown,
                mousedown: _vm.handleTableMouseDown
              }
            },
            _vm._l(_vm.rows, function(row, i) {
              return _c("plain-table-tile", {
                key: row.key || i,
                attrs: {
                  i: i,
                  row: _vm.rowOverrides[row.key] || row,
                  rows: _vm.editableRow === i ? _vm.report.rows : null,
                  meta: _vm.meta,
                  primary: _vm.selectedRow === i,
                  selected: _vm.selectedRows[i],
                  editable: _vm.editableRow === i,
                  sections: _vm.sections,
                  editableColumn:
                    _vm.presentedRow == null ? _vm.editableColumn : null
                },
                on: {
                  maximize: function($event) {
                    _vm.presentedRow = i
                  }
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mode == "table" || _vm.mode == "tree"
        ? _c(
            "div",
            {
              ref: "tableBody",
              staticClass: "plain-table-body",
              on: {
                "&scroll": function($event) {
                  return _vm.deferUpdateOverscrollAnchor.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data !== null,
                      expression: "data !== null"
                    }
                  ],
                  ref: "table",
                  class: {
                    table: true,
                    "table-sm": true,
                    "table-striped": _vm.striped,
                    "table-bordered": _vm.bordered,
                    "table-hover": _vm.hover,
                    "table-responsive": _vm.responsive,
                    "table-frozen-dims": _vm.freezeDims,
                    "table-expandable": _vm.expandable,
                    "table-column-resizing": _vm.resizingColumn
                  },
                  style: { opacity: _vm.pending ? 0.5 : 1 },
                  attrs: {
                    id: _vm.tableId,
                    cellspacing: "0",
                    cellpadding: "0"
                  },
                  on: {
                    click: _vm.handleTableClick,
                    change: _vm.handleTableChange,
                    keydown: _vm.handleTableKeyDown,
                    mousedown: _vm.handleTableMouseDown,
                    mouseleave: _vm.handleTableMouseLeave,
                    mousemove: _vm.handleTableMouseMove
                  }
                },
                [
                  _c("thead", [
                    _vm.sections.length > 1
                      ? _c(
                          "tr",
                          _vm._l(_vm.sections, function(section, i) {
                            return section.visibleColumns.length > 0
                              ? _c(
                                  "th",
                                  {
                                    class: {
                                      "my-column-dim": _vm.isDimsSection(
                                        section
                                      ),
                                      "section-start": true,
                                      "section-end": true
                                    },
                                    attrs: {
                                      "data-section": i,
                                      "data-column": section.columns[0].i,
                                      colspan: section.visibleColumns.length
                                    }
                                  },
                                  [
                                    _vm.sectionActionsEx(section).length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "float-left",
                                            staticStyle: {
                                              "margin-right": "8px"
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.selectedSection = i
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    name: "table-head-filter"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("l10n", {
                                      attrs: { value: section.name }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      { ref: "sortable" },
                      [
                        _vm._l(_vm.sections, function(section) {
                          return _vm._l(section.visibleColumns, function(
                            column,
                            i
                          ) {
                            return _c(
                              "th",
                              {
                                class: {
                                  "my-column-dim":
                                    column.i < _vm.meta.dims.length,
                                  "section-start": i === 0,
                                  "section-end":
                                    i === section.visibleColumns.length - 1
                                },
                                attrs: {
                                  id: column.id,
                                  "data-column": column.i,
                                  "data-type": column.type,
                                  "data-name": column.name,
                                  rowspan: _vm.sections !== null ? 2 : undefined
                                }
                              },
                              [
                                _c("div", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedColumn = column.i
                                        }
                                      }
                                    },
                                    [
                                      _c("l10n", {
                                        attrs: { value: column.name }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "plain-table-sort-icons"
                                        },
                                        [
                                          _vm.sortedAscending(column.i)
                                            ? _c("feather-icon", {
                                                attrs: { name: "arrow-down" }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.sortedDescending(column.i)
                                            ? _c("feather-icon", {
                                                attrs: { name: "arrow-up" }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.desiredSort.length > 1 &&
                                          _vm.sortingLevel(column.i) !== -1
                                            ? _c("sup", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.sortingLevel(
                                                        column.i
                                                      ) + 1
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.hasFilters(column.i)
                                        ? _c("feather-icon", {
                                            attrs: { name: "filter" }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.columnIconsEx(column),
                                        function(name) {
                                          return _c("feather-icon", {
                                            key: name,
                                            attrs: { name: name }
                                          })
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "column-move-target"
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "column-resize-left",
                                  on: {
                                    mousedown: function($event) {
                                      _vm.startColumnResize(
                                        $event,
                                        _vm.previousColumn(column)
                                      )
                                    },
                                    dblclick: function($event) {
                                      _vm.resetColumnWidth(
                                        $event,
                                        _vm.previousColumn(column)
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "column-resize-right",
                                  on: {
                                    mousedown: function($event) {
                                      return _vm.startColumnResize(
                                        $event,
                                        column
                                      )
                                    },
                                    dblclick: function($event) {
                                      return _vm.resetColumnWidth(
                                        $event,
                                        column
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          })
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.report && _vm.report.totals
                        ? [
                            _vm._l(_vm.report.totals.rows, function(row) {
                              return _c(
                                "tr",
                                {
                                  class: {
                                    "plain-table-totals": true,
                                    primary: _vm.selectedRow === -1,
                                    selected: _vm.selectedRows[-1],
                                    editable: _vm.editableRow === -1
                                  },
                                  attrs: { "data-row": -1 }
                                },
                                [
                                  _vm._l(_vm.sections, function(section) {
                                    return _vm._l(
                                      section.visibleColumns,
                                      function(column, j) {
                                        return _c("plain-table-cell-static", {
                                          key: column.id,
                                          attrs: {
                                            totals: true,
                                            meta: _vm.meta,
                                            row: row,
                                            column: column,
                                            headerWithoutSum:
                                              _vm.headerWithoutSum,
                                            section: section,
                                            "no-link": true,
                                            "no-style": true,
                                            "no-action": true
                                          }
                                        })
                                      }
                                    )
                                  })
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.report.totals.rows, function(row) {
                              return _vm.expandable && _vm.selectedRow === -1
                                ? _c(
                                    "tr",
                                    {
                                      key: "expanded",
                                      staticClass: "expanded",
                                      attrs: { "data-row": _vm.selectedRow }
                                    },
                                    _vm._l(_vm.sections, function(section) {
                                      return _c(
                                        "td",
                                        {
                                          staticClass:
                                            "section-start section-end",
                                          attrs: {
                                            colspan:
                                              section.visibleColumns.length
                                          }
                                        },
                                        [
                                          _c("RenderProxy", {
                                            attrs: {
                                              section: section,
                                              row: row,
                                              meta: _vm.meta,
                                              tagname: _vm.expandTagName,
                                              totals: true,
                                              extraProps: _vm.expandProps
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            })
                          ]
                        : _vm.overscroll
                        ? [
                            _c(
                              "tr",
                              {
                                class: {
                                  "plain-table-totals": true,
                                  primary: _vm.selectedRow === -1,
                                  selected: _vm.selectedRows[-1],
                                  editable: _vm.editableRow === -1
                                },
                                attrs: { "data-row": -1 }
                              },
                              [
                                _vm._l(_vm.sections, function(section) {
                                  return _vm._l(
                                    section.visibleColumns,
                                    function(column, j) {
                                      return _c("plain-table-cell-static", {
                                        key: column.id,
                                        attrs: {
                                          meta: _vm.meta,
                                          row: _vm.overallSummary,
                                          column: column,
                                          headerWithoutSum:
                                            _vm.headerWithoutSum,
                                          section: section,
                                          "no-link": true,
                                          "no-style": true,
                                          "no-action": true
                                        }
                                      })
                                    }
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.overscroll
                        ? [
                            _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: false,
                                    expression: "false"
                                  }
                                ],
                                ref: "overscrollRef"
                              },
                              [_c("td", [_vm._v(" ")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                ref: "overscrollTop",
                                staticClass: "overscroll",
                                attrs: {
                                  set: (_vm.height = _vm.overscrollTopHeight)
                                }
                              },
                              [
                                _vm._l(_vm.sections, function(section, i) {
                                  return _vm._l(
                                    section.visibleColumns,
                                    function(column, j) {
                                      return _c("td", {
                                        class: {
                                          "my-column-dim": i == 0,
                                          "section-start": j == 0,
                                          "section-end":
                                            j ==
                                            section.visibleColumns.length - 1
                                        },
                                        style: { height: _vm.height + "px" },
                                        attrs: { "data-column": column.i }
                                      })
                                    }
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subTotals
                        ? [
                            _vm._l(_vm.rowGroups, function(rowGroup) {
                              return [
                                _c(
                                  "tr",
                                  { staticClass: "sub-total" },
                                  [
                                    _vm._l(_vm.sections, function(section) {
                                      return _vm._l(
                                        section.visibleColumns,
                                        function(column, j) {
                                          return _c("plain-table-cell-static", {
                                            key: column.id,
                                            attrs: {
                                              meta: _vm.meta,
                                              row: rowGroup.summary,
                                              column: column,
                                              section: section,
                                              "no-link": true,
                                              "no-style": true,
                                              "no-action": true
                                            }
                                          })
                                        }
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm._l(rowGroup.rows, function(row, i) {
                                  return [
                                    _c("plain-table-row-static", {
                                      key: rowGroup.key + ";" + (row.key || i),
                                      attrs: {
                                        i: _vm.rows.indexOf(row),
                                        row: _vm.rowOverrides[row.key] || row,
                                        meta: _vm.meta,
                                        primary:
                                          _vm.selectedRow ==
                                          _vm.rows.indexOf(row),
                                        selected:
                                          _vm.selectedRows[
                                            _vm.rows.indexOf(row)
                                          ],
                                        sections: _vm.sections
                                      }
                                    })
                                  ]
                                })
                              ]
                            })
                          ]
                        : _vm._l(_vm.rows, function(row, i) {
                            return [
                              _vm.editableRow === i
                                ? _c("plain-table-row-editable", {
                                    key: row.key || i,
                                    attrs: {
                                      i: i,
                                      row: _vm.rowOverrides[row.key] || row,
                                      rows:
                                        _vm.editableRow === i
                                          ? _vm.report.rows
                                          : null,
                                      meta: _vm.meta,
                                      primary: _vm.selectedRow === i,
                                      selected: _vm.selectedRows[i],
                                      editable: true,
                                      sections: _vm.sections,
                                      editableColumn:
                                        _vm.presentedRow == null
                                          ? _vm.editableColumn
                                          : null
                                    },
                                    on: {
                                      requestReactionToConsent:
                                        _vm.runRequestReactionToConsent,
                                      requestOutputPriority:
                                        _vm.runRequestOutputPriority
                                    }
                                  })
                                : _c("plain-table-row-static", {
                                    key: row.key || i,
                                    class:
                                      _vm.groupSize !== 0
                                        ? _vm.getRowClassByIndex(
                                            i + 1,
                                            _vm.groupSize
                                          )
                                        : null,
                                    attrs: {
                                      i: i,
                                      row: _vm.rowOverrides[row.key] || row,
                                      meta: _vm.meta,
                                      primary: _vm.selectedRow === i,
                                      selected: _vm.selectedRows[i],
                                      sections: _vm.sections
                                    }
                                  }),
                              _vm._v(" "),
                              _vm.expandable && i === _vm.selectedRow
                                ? _c(
                                    "tr",
                                    {
                                      key: "expanded",
                                      staticClass: "expanded",
                                      attrs: { "data-row": _vm.selectedRow }
                                    },
                                    _vm._l(_vm.sections, function(section) {
                                      return _c(
                                        "td",
                                        {
                                          class: {
                                            "section-start": true,
                                            "section-end": true,
                                            selected:
                                              _vm.selectedColumn !== null &&
                                              _vm.selectedColumn >=
                                                section.columns[0].i &&
                                              _vm.selectedColumn <=
                                                section.columns[
                                                  section.columns.length - 1
                                                ].i
                                          },
                                          attrs: {
                                            colspan:
                                              section.visibleColumns.length
                                          }
                                        },
                                        [
                                          _c("RenderProxy", {
                                            attrs: {
                                              section: section,
                                              row: row,
                                              meta: _vm.meta,
                                              tagname: _vm.expandTagName,
                                              totals: false,
                                              extraProps: _vm.expandProps
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          }),
                      _vm._v(" "),
                      _vm.overscroll
                        ? [
                            _c(
                              "tr",
                              {
                                ref: "overscrollBottom",
                                staticClass: "overscroll",
                                attrs: {
                                  set: (_vm.height = _vm.overscrollBottomHeight)
                                }
                              },
                              [
                                _vm._l(_vm.sections, function(section, i) {
                                  return _vm._l(
                                    section.visibleColumns,
                                    function(column, j) {
                                      return _c("td", {
                                        class: {
                                          "my-column-dim": i == 0,
                                          "section-start": j == 0,
                                          "section-end":
                                            j ==
                                            section.visibleColumns.length - 1
                                        },
                                        style: { height: _vm.height + "px" },
                                        attrs: { "data-column": column.i }
                                      })
                                    }
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("tfoot", [
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.numSelectedRows > 1,
                            expression: "numSelectedRows > 1"
                          }
                        ]
                      },
                      [
                        _vm._l(_vm.sections, function(section) {
                          return _vm._l(section.visibleColumns, function(
                            column,
                            j
                          ) {
                            return _c("plain-table-cell-static", {
                              key: column.id,
                              attrs: {
                                meta: _vm.meta,
                                row: _vm.selectedSummary,
                                column: column,
                                section: section,
                                "no-link": true,
                                "no-style": true,
                                "no-action": true
                              }
                            })
                          })
                        })
                      ],
                      2
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.presentedRow != null && _vm.rows[_vm.presentedRow]
        ? _c(
            "my-dialog",
            {
              attrs: {
                large: true,
                title: _vm.rows[_vm.presentedRow]
                  .slice(
                    _vm.dims.length > 0 && _vm.dims[0].type == "check" ? 1 : 0,
                    _vm.dims.length
                  )
                  .join(", ")
              },
              on: {
                close: function($event) {
                  _vm.presentedRow = null
                }
              }
            },
            [
              _c("div", { staticClass: "plain-table-presented-row" }, [
                _c(
                  "table",
                  {
                    ref: "presentedRow",
                    staticClass: "table table-sm table-hover",
                    on: {
                      click: _vm.handleTableClick,
                      change: _vm.handleTableChange,
                      keydown: _vm.handleTableKeyDown,
                      mousedown: _vm.handleTableMouseDown
                    }
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.sections, function(section) {
                          return [
                            _vm._l([_vm.rows[_vm.presentedRow]], function(row) {
                              return [
                                section.name
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "plain-table-presented-row__section-name",
                                          attrs: { colspan: "2" }
                                        },
                                        [
                                          _c("l10n", {
                                            attrs: { value: section.name }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(section.visibleColumns, function(
                                  column
                                ) {
                                  return _c(
                                    "tr",
                                    { attrs: { "data-row": _vm.presentedRow } },
                                    [
                                      _c(
                                        "td",
                                        { attrs: { "data-type": column.type } },
                                        [
                                          _c("l10n", {
                                            attrs: { value: column.name }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      column.i == _vm.editableColumn &&
                                      _vm.presentedRow == _vm.editableRow
                                        ? _c("plain-table-cell-editable", {
                                            attrs: {
                                              editing: true,
                                              meta: _vm.meta,
                                              row:
                                                _vm.rowOverrides[row.key] ||
                                                row,
                                              column: column,
                                              section: { visibleColumns: [] },
                                              "no-class": false,
                                              "no-style": false,
                                              "no-action": false
                                            }
                                          })
                                        : _c("plain-table-cell-static", {
                                            attrs: {
                                              meta: _vm.meta,
                                              row:
                                                _vm.rowOverrides[row.key] ||
                                                row,
                                              column: column,
                                              section: { visibleColumns: [] },
                                              "no-class": false,
                                              "no-style": false,
                                              "no-action": false
                                            }
                                          })
                                    ],
                                    1
                                  )
                                })
                              ]
                            })
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary plain-table-presented-row__btn",
                    attrs: { disabled: _vm.page == 0 },
                    on: {
                      click: function($event) {
                        _vm.desiredPage = _vm.page - 1
                        _vm.presentedRow = _vm.pageSize - 1
                      }
                    }
                  },
                  [_c("feather-icon", { attrs: { name: "chevrons-left-sm" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary plain-table-presented-row__btn",
                    attrs: { disabled: _vm.presentedRow <= 0 && _vm.page == 0 },
                    on: { click: _vm.prevPresentedRow }
                  },
                  [_c("feather-icon", { attrs: { name: "chevron-left-sm" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "plain-table-presented-row__page-count",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.promptPresentedRow.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("l10n", {
                          attrs: {
                            value: "row {row} of {rows}",
                            row: new Number(
                              _vm.presentedRow + 1
                            ).toLocaleString(),
                            rows: new Number(_vm.rows.length).toLocaleString()
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.pageCount > 1
                      ? [
                          _vm._v(
                            "\n                    ,\n                    "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.promptPage.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("l10n", {
                                attrs: {
                                  value: "page {page} of {pages}",
                                  page: new Number(
                                    _vm.page + 1
                                  ).toLocaleString(),
                                  pages: new Number(
                                    _vm.pageCount
                                  ).toLocaleString()
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("inline-help", {
                      attrs: {
                        text:
                          "You can use <Left> and <Right> arrow keys to switch between rows."
                      }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary plain-table-presented-row__btn",
                    attrs: {
                      disabled:
                        _vm.presentedRow >= _vm.rows.length - 1 &&
                        _vm.page == _vm.pageCount - 1
                    },
                    on: { click: _vm.nextPresentedRow }
                  },
                  [_c("feather-icon", { attrs: { name: "chevron-right-sm" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary plain-table-presented-row__btn",
                    attrs: { disabled: _vm.page >= _vm.pageCount - 1 },
                    on: {
                      click: function($event) {
                        _vm.desiredPage = _vm.page + 1
                        _vm.presentedRow = 0
                      }
                    }
                  },
                  [
                    _c("feather-icon", { attrs: { name: "chevrons-right-sm" } })
                  ],
                  1
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }