let {escape, renderClasses, renderStyle} = require("./plain-table-static-utils.js")

let nextCheckId = 1

export class PlainTableCellStatic {
    constructor(props) {
        _.assign(this, props)
    }
    render() {
        return `
        <td
            class="${escape(renderClasses(this.classes))}"
            style="${escape(renderStyle(this.style))}"
            title="${escape(this.title)}"
            data-column="${escape(this.column.i)}"
            data-type="${escape(this.column.type)}"
            >
            ${this.column.type == "check" ? `
            <div class="form-check">
            <input id="check-${this.checkId}" type="checkbox" class="form-check-input" ${this.value ? "checked" : ""}/>
            <label for="check-${this.checkId}" class="form-check-label">
            </label>
            </div>
            ` : `
            ${this.column.actionable && this.actionicon && this.actionicon !== 'noicon' && !this.noAction ? `
            <a
                class="my-colum-action"
                href="javascript:void(0)">
                ${this.actionicon === 'minus' 
                ? `<svg class="minus-icon" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="green">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4C0.5 3.72386 0.723858 3.5 1 3.5H7C7.27614 3.5 7.5 3.72386 7.5 4C7.5 4.27614 7.27614 4.5 7 4.5H1C0.723858 4.5 0.5 4.27614 0.5 4Z" fill="black"/>
                    </svg>`
                : this.actionicon === 'plus' 
                ? `<svg class="plus-icon" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <g clip-path="url(#clip0_612_3118)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1V3.5H1C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5H3.5V7C3.5 7.27614 3.72386 7.5 4 7.5C4.27614 7.5 4.5 7.27614 4.5 7V4.5H7C7.27614 4.5 7.5 4.27614 7.5 4C7.5 3.72386 7.27614 3.5 7 3.5H4.5V1Z" fill="black"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_612_3118">
                            <rect width="8" height="8" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>` : ''}
            </a>` : ""}
            ${this.column.actionable && !this.actionicon && !this.noAction ? `
            <span>
                <a href="javascript:void(0)">${escape(this.formattedValue)}</a>
            </span>` : this.actionlink ? `
            <span>
                <a
                    href="${escape(this.actionlink)}"
                    target="${this.actionlink.startsWith("http") ? "_blank" : ""}">
                    ${escape(this.formattedValue)}
                </a>
            </span>` : this.column.type == "html" ? `
            <span>${this.formattedValue}</span>` : this.isLink && !this.noLink ? `
            <span>
                <a href="${escape(this.value)}" target="_blank">
                    ${escape(this.formattedValue)}
                </a>
            </span>` : `<span>${escape(this.formattedValue)}</span>`}
            `}
        </td>`
    }
    get checkId() {
        if (!this._checkId) {
            this._checkId = nextCheckId
            nextCheckId += 1
        }
        return this._checkId
    }
    get info() {
        let {row, column, meta} = this
        return 
    }
    get value() {
        let value = undefined
        if (this.totals)
            value = this.row[this.column.i - this.meta.dims.length]
        else
            value = this.row[this.column.i]
        if (this.column.override)
            value = this.column.override({
                row: this.row,
                column: this.column,
                totals: this.totals,
                value
            })
        return value
    }
    get formattedValue() {
        return this.column.format(
            this.value,
            this.row,
            this.column,
            this.meta)
    }
    get actionlink() {
        return this.column.actionlink ?
            this.column.actionlink(this.row, this.column, this.meta) : null
    }
    get actionicon() {
        return this.column.actionicon ?
            this.column.actionicon(this.row, this.column, this.meta) : null
    }
    get style() {
        if (this.noStyle)
            return null
        let style = this.column.style(
            this.value, this.row, this.column, this.meta)
        if (this.height !== undefined) {
            if (!style)
                style = {}
            style.height = `${this.height}px`
        }
        return style
    }
    get title() {
        return this.column.title(this.value, this.row, this.column, this.meta)
    }
    get classes() {
        if (this.noClass)
            return null
        let {column, meta, row} = this
        let {visibleColumns} = this.section
        let classes = {
            "section-start": visibleColumns[0] === column,
            "section-end": visibleColumns[visibleColumns.length-1] === column,
            "my-column-editable": !this.totals && column.editable && column.editable(row, column, meta),
            "my-column-actionable": !this.totals && column.actionable,
        }

        if (column.className)
            classes[column.className] = true

        if (column.columnClass) {
            let class_ = column.columnClass(this.value, row, column, meta)
            if (class_)
                classes[class_] = true
        }

        return classes
    }
    get isLink() {
        return _.startsWith(this.value, "http")
    }
}