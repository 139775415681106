var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      class: _vm.rowClass,
      style: _vm.rowStyle,
      attrs: { tabindex: "0", "data-row": _vm.i }
    },
    [
      _vm._l(_vm.sections, function(section) {
        return _vm.editable
          ? [
              _vm._l(section.visibleColumns, function(column, j) {
                return [
                  _vm.editableColumn !== column.i
                    ? _c("plain-table-cell-static", {
                        key: column.id || j,
                        attrs: {
                          i: _vm.i,
                          meta: _vm.meta,
                          row: _vm.row,
                          column: column,
                          section: section
                        }
                      })
                    : _c("plain-table-cell-editable", {
                        key: column.id || j,
                        attrs: {
                          i: _vm.i,
                          meta: _vm.meta,
                          row: _vm.row,
                          rows: _vm.rows,
                          column: column,
                          section: section,
                          editing: true
                        },
                        on: {
                          requestReactionToConsent:
                            _vm.runRequestReactionToConsent,
                          requestOutputPriority: _vm.runRequestOutputPriority
                        }
                      })
                ]
              })
            ]
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }